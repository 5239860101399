/* eslint-disable no-console */
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, DatePicker, Row, Select, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import DoctorManager from '../../api/doctors/request';
import PatientManager from '../../api/patients/request';
import { DATE_FORMAT } from '../../configs/constants';
import { AppointmentStatus } from '../../enums/status';
import { getEnumValues } from '../../services/utility';
import DropdownCard from '../dropdownCard/DropdownCard';
import styles from './AppointmentFilter.module.scss';

const INITIAL_FILTER_INPUT = {
  patient_id: '',
  doctor_id: '',
  start_date: '',
  end_date: '',
  status: '',
};

const AppointmentFilter = (props: any) => {
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterInput, setFilterInput] = useState<any>(INITIAL_FILTER_INPUT);
  let timeout: any = null;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const paramsObj = Object.fromEntries(new URLSearchParams(location.search));
    setFilterInput(paramsObj);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  const onPatientNameChange = async (value: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(async () => {
      const response = await PatientManager.all({
        q: value,
      });
      if (response.data && response.data.length > 0) {
        setPatients(response.data);
      }
    }, 500);
  };

  const onSearchDoctor = async (value: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(async () => {
      const response = await DoctorManager.all({
        q: value,
      });
      if (response.data && response.data.length > 0) {
        setDoctors(response.data);
      }
    }, 500);
  };

  const onFilterHandler = (payload: any) => {
    const { type, value } = payload;
    const filterObj: any = { ...filterInput };
    filterObj[type] = value;
    navigate({
      pathname: location.pathname,
      search: createSearchParams(filterObj).toString(),
    });
    setFilterInput(filterObj);
  };
  return loading ? (
    <Spin />
  ) : (
    <div className={styles.Wrapper}>
      <Row gutter={20}>
        <Col lg={7} md={7} sm={24} xs={24}>
          <Select
            style={{ width: '100%' }}
            allowClear
            showSearch
            onSearch={onPatientNameChange}
            onClear={() => onFilterHandler({ type: 'patient_id', value: '' })}
            onSelect={(patientId: number) =>
              onFilterHandler({ type: 'patient_id', value: patientId })
            }
            placeholder="Search patient name"
            showArrow={false}
            filterOption={false}
            notFoundContent={''}
          >
            {patients.map((itr: any, index: number) => (
              <Select.Option key={index} value={itr.id}>
                <DropdownCard
                  item={{
                    photo: (
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 67,
                          xl: 67,
                          xxl: 67,
                        }}
                        shape="square"
                        src={itr.photo}
                        icon={<UserOutlined />}
                      />
                    ),
                    title: itr.name,
                    description: `${itr.contactNo} / ${itr.age || '-'}`,
                  }}
                />
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={7} md={7} sm={24} xs={24}>
          <Select
            style={{ width: '100%' }}
            allowClear
            showSearch
            onSearch={onSearchDoctor}
            onClear={() => onFilterHandler({ type: 'doctor_id', value: '' })}
            onSelect={(doctorId: number) =>
              onFilterHandler({ type: 'doctor_id', value: doctorId })
            }
            placeholder="Search doctor name"
            showArrow={false}
            filterOption={false}
            notFoundContent={''}
          >
            {doctors.map((itr: any, index: number) => (
              <Select.Option key={index} value={itr.id}>
                <DropdownCard
                  item={{
                    photo: (
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 67,
                          xl: 67,
                          xxl: 67,
                        }}
                        shape="square"
                        src={itr.photo}
                        icon={<UserOutlined />}
                      />
                    ),
                    title: itr.name,
                    description: `${itr.specialties}`,
                  }}
                />
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={4} md={4} sm={24} xs={24}>
          <Select
            allowClear
            onChange={(status: any) =>
              onFilterHandler({ type: 'status', value: status })
            }
            placeholder="All statuses"
            style={{ width: '100%' }}
          >
            {(
              Object.keys(
                AppointmentStatus
              ) as (keyof typeof AppointmentStatus)[]
            ).map((status, index: number) => (
              <Select.Option key={index} value={AppointmentStatus[status]}>
                {status}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <Row gutter={20}>
            <Col span={12}>
              {filterInput.start_date ? (
                <DatePicker
                  defaultValue={
                    filterInput.start_date
                      ? moment(filterInput.start_date, 'YYYY-MM-DD')
                      : moment()
                  }
                  id="start_date_filter"
                  onChange={(date: any) =>
                    onFilterHandler({
                      type: 'start_date',
                      value: date ? date.format(DATE_FORMAT) : '',
                    })
                  }
                  style={{ width: '100%' }}
                  placeholder="Start date"
                />
              ) : (
                <DatePicker
                  id="start_date_filter"
                  onChange={(date: any) =>
                    onFilterHandler({
                      type: 'start_date',
                      value: date ? date.format(DATE_FORMAT) : '',
                    })
                  }
                  style={{ width: '100%' }}
                  placeholder="Start date"
                />
              )}
            </Col>
            <Col span={12}>
              {filterInput.end_date ? (
                <DatePicker
                  allowClear
                  defaultValue={
                    filterInput.end_date
                      ? moment(filterInput.end_date, 'YYYY-MM-DD')
                      : moment()
                  }
                  onChange={(date: any) =>
                    onFilterHandler({
                      type: 'end_date',
                      value: date ? date.format(DATE_FORMAT) : '',
                    })
                  }
                  style={{ width: '100%' }}
                  placeholder="End date"
                />
              ) : (
                <DatePicker
                  allowClear
                  onChange={(date: any) =>
                    onFilterHandler({
                      type: 'end_date',
                      value: date ? date.format(DATE_FORMAT) : '',
                    })
                  }
                  style={{ width: '100%' }}
                  placeholder="End date"
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentFilter;
