/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageContainer, ProLayout } from '@ant-design/pro-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import endpoints from '../../configs/endpoints';
import Header from '../../containers/header/Header';
import sidebarMenu from './sidebarMenu';
import './Sidebar.scss';
interface Props {
  children: React.ReactNode;
  headerContent?: React.ReactNode;
}

const Layout = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: '100vh',
      }}
    >
      <ProLayout
        navTheme="light"
        headerTheme="dark"
        headerRender={() => <Header />}
        headerHeight={50}
        title=""
        logo="https://www.heal.xyz/_next/image?url=%2Flogo-1.webp&w=256&q=75"
        siderWidth={270}
        collapsed={false}
        breadcrumbRender={(routers: any) => [
          {
            path: '/',
            breadcrumbName: 'Home',
          },
          ...routers,
        ]}
        defaultCollapsed={false}
        menuItemRender={(item: any, dom: any) => (
          <a onClick={() => navigate(item.path)}>{dom}</a>
        )}
        route={{
          routes: sidebarMenu,
        }}
      >
        <PageContainer>{props.children}</PageContainer>
      </ProLayout>
    </div>
  );
};

export default Layout;
