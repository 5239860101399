import {
  Button,
  Col,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChamberManager from '../../api/chambers/request';
import DoctorManager from '../../api/doctors/request';
import { ChamberInterface } from '../../interfaces/chamber';
import { DoctorInterface } from '../../interfaces/doctor';
import { getProviderId } from '../../services/utility';
import styles from './ChamberFormContent.module.scss';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface ChamberFormContentProps {
  appointment?: any;
  chamber?: any;
  label?: string;
}

const ChamberFormContent: React.FC<ChamberFormContentProps> = (
  props: ChamberFormContentProps
) => {
  const [form] = Form.useForm();
  const [doctors, setDoctors] = useState<Array<DoctorInterface>>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<DoctorInterface | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [formInitialValues, setFormInitialValues] = useState({});
  const appointment = props.appointment;

  useEffect(() => {
    fetchDoctors();

    setFormInitialValues(props.chamber ? { ...props.chamber } : {});

    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);

  const navigate = useNavigate();
  const fetchDoctors = async () => {
    try {
      const response = await DoctorManager.all();
      setDoctors(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeDoctor = (value: any) => {
    const doctor: any = doctors.find(
      (doctor: DoctorInterface) => doctor.id === value
    );
    if (doctor) {
      setSelectedDoctor(doctor);
    }
  };

  const onFinish = (values: any) =>
    props.chamber ? updateChamber(values) : createNewChamber(values);
  const mapRequest = (request: any) => ({
    doctor_id: request.doctorId,
    room_no: request.roomNo,
    contact_no: request.contactNo,
    email: request.email,
    address: request.address,
    new_patient_fee: request.newPatientFee,
    old_patient_fee: request.oldPatientFee,
    report_fee: request.reportFee,
    advance_fee: request.advanceFee,
    max_patient: request.maxPatient,
    days_to_be_old: request.daysToBeOld,
    appointment_duration: request.appointmentDuration,
    appointment_cancelation_hour: request.appointmentCancelationHour,
    reserved_appointment: request.reservedAppointment,
    online_appointment: request?.onlineAppointment,
  });
  const createNewChamber = async (request: any) => {
    try {
      await ChamberManager.create(mapRequest(request));
      message.success('Chamber created successfully');
      navigate('/chambers');
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data);
    }
  };

  const updateChamber = async (request: any) => {
    try {
      const response: any = await ChamberManager.update({
        id: props.chamber.id,
        ...mapRequest(request),
      });
      if (response.data?.success === 'chamber_updated') {
        message.success('Chamber updated successfully');
      }
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data);
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };
  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <div className={styles.FormWrapper + ' bg-white form__content'}>
          <h2>{props.label ? props.label : 'Create Chamber'}</h2>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={formInitialValues || {}}
          >
            <Row gutter={20}>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="organizationId"
                  label="Organization Id"
                  rules={[{ required: true }]}
                  initialValue={getProviderId()}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="doctorId"
                  label={
                    props.chamber?.doctorName
                      ? `Doctor Name (Selected: ${props.chamber.doctorName})`
                      : 'Doctor Name'
                  }
                  rules={[{ required: true }]}
                >
                  <Select
                    onChange={(value) => onChangeDoctor(value)}
                    placeholder="Select a option and change input text above"
                  >
                    {doctors.map((doctor: DoctorInterface, index: number) => (
                      <Option key={index} value={doctor.id}>
                        {doctor.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="roomNo"
                  label="Room no"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter room number" />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="contactNo"
                  label="Contact no"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter contact number" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, type: 'email' }]}
                >
                  <Input placeholder="Enter email" />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="newPatientFee"
                  label="New Patient Fee"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    placeholder="Enter new patient fee"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="oldPatientFee"
                  label="Old Patient Fee"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter old patient fee"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="reportFee"
                  label="Report Fee"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter report fee"
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="advanceFee"
                  label="Advance Fee"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter advance fee"
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="maxPatient"
                  label="Max Patient"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter max patient"
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="daysToBeOld"
                  label="Days to be old"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter days to be old"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="appointmentDuration"
                  label="Appointment Duration"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter appointment duration"
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="appointmentCancelationHour"
                  label="Appointment Cancelation Hour"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter cancellation hour"
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item
                  name="reservedAppointment"
                  label="Reserved Appointment"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="Enter reserved appointment"
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <Form.Item name="onlineAppointment" label="Online Appointment">
                  <Switch
                    defaultChecked={
                      props.chamber?.onlineAppointment ? true : false
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                style={{ marginRight: '10px' }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

export default ChamberFormContent;
