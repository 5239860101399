import endpoints from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import {
  CreatePatientRequest,
  CreatePatientResponse,
  PatientInterface,
} from './../../interfaces/patient.d';
import Mapper from './mapper';

/**
 * Get all patients
 * @param page the number of page
 * @returns {Promise<CreatePatientResponse[]>} a promise of array of patients with pagination
 */
export const all = async (request: any): Promise<any> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_PATIENTS_ENDPOINT.replace(':providerId', getProviderId()),
    request,
    true
  );
  return {
    data: Mapper.dataFromSTC(response.data.data),
    meta: Mapper.meta(response.data.meta),
  };
};

/**
 * create a new patient
 * @param request the request body
 * @returns {Promise<CreatePatientResponse>} a promise of patient
 */
export const create = async (
  request: CreatePatientRequest
): Promise<CreatePatientResponse> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_PATIENTS_STORE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return Mapper.dataDetailsItemFromSTC(response.data);
};

/**
 * Get a patient by id
 * @param id the id of patient
 * @returns {Promise<PatientInterface>} a promise of array of patients with pagination
 */
export const get = async (request: any): Promise<PatientInterface> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_PATIENT_SHOW_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':patientId', request.id),
    {},
    true
  );
  return Mapper.dataDetailsItemFromSTC(response.data.data);
};

/**
 * Get a patient by id
 * @param id the id of patient
 * @returns {Promise<PatientInterface>} a promise of array of patients with pagination
 */
export const update = async (request: any): Promise<any> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_PATIENT_UPDATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':patientId', request.id),
    request,
    true
  );
  return {
    data: response.data.data,
  };
};

export const remove = async (
  request: any
): Promise<{
  data: any;
}> => {
  request['_method'] = 'DELETE';
  const response = await axioService(
    POST,
    endpoints.SERVER_PATIENT_DELETE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':patientId', request.id),
    request,
    true
  );
  return {
    data: response.data,
  };
};

const PatientManager = {
  all,
  get,
  update,
  create,
  remove,
};

export default PatientManager;
