import React from 'react';
import Layout from '../components/layout/Layout';
import PatientForm from '../components/patientForm/PatientForm';

const PatientCreate = () => {
  return (
    <Layout>
      <PatientForm />
    </Layout>
  );
};

export default PatientCreate;
