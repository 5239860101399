/* eslint-disable no-console */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import {
  Avatar,
  Button,
  Col,
  Divider,
  message,
  Modal,
  Pagination,
  Row,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ScheduleManager from '../../api/schedules/request';
import { Meta } from '../../interfaces/api';
import { ScheduleInterface } from '../../interfaces/schedule';

const ScheduleList = () => {
  const actionRef = useRef<ActionType>();
  const [schedules, setSchedules] = useState<Array<ScheduleInterface>>([]);
  const [schedule, setSchedule] = useState<any>(null);
  const [meta, setMeta] = useState<Meta | null>(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSchedules(meta?.currentPage ? meta.currentPage + 1 : 1);
  }, []);
  const onDeleteSchedule = async (id: number, index: number) => {
    try {
      setLoading(true);
      const response = await ScheduleManager.remove({
        id,
      });
      if (response.data.success === 'schedule_delete') {
        const prevSchedules = [...schedules];
        delete prevSchedules[index];
        setSchedules(prevSchedules);
        message.success('Schedule deleted successfully!');
        setLoading(false);
      } else {
        message.error('Schedule not deleted!');
        setLoading(false);
      }
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };
  const columns: ProColumns[] = [
    {
      title: 'Doctor',
      render: (text, record) => (
        <div>
          <Row gutter={10} wrap={false}>
            <Col>
              <Avatar
                className="icon-svg-sm"
                size={40}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M352 128c0 70.7-57.3 128-128.9 128-69.8 0-128-57.3-128-128 0-70.69 58.2-128 128-128C294.7 0 352 57.31 352 128zm-64.9 234c-26.7 7.1-48 32.2-48 62v24c0 4.2 2.6 8.3 5.6 11.3l16 16c6.2 6.3 16.4 6.3 22.6 0 6.3-6.2 6.3-16.4 0-22.6l-12.2-11.3V424c0-17.7 15.2-32 32-32 18.6 0 32.9 14.3 32.9 32v17.4l-11.3 11.3c-6.3 6.2-6.3 16.4 0 22.6 6.2 6.3 16.4 6.3 22.6 0l16-16c3-3 4.7-7.1 4.7-11.3v-24c0-29.8-20.4-54.9-48-62v-53.2c73.5 17.9 128 83.3 128 163.2v8c0 17.7-14.3 32-32 32H32c-17.67 0-32-14.3-32-32v-8c0-79 54.53-145.3 128-163.2v61.5c-23.1 6.9-40 28.3-40 53.7 0 30.9 25.1 56 56 56s56-25.1 56-56c0-25.4-16.9-46.8-40-53.7v-66.1c2.7-.1 5.3-.2 8-.2h112c2.7 0 5.3.1 8 .2l-.9 57.8zm-120 62c0 13.3-9.8 24-24 24-12.4 0-24-10.7-24-24s11.6-24 24-24c14.2 0 24 10.7 24 24z" />
                  </svg>
                }
              />
            </Col>
            <Col>
              <h3 className="m-0">{record.doctorName}</h3>
              <span className="clr-gray fw-400 fs-13">
                {record.doctorSpecialties}
              </span>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      title: 'Chamber',
      render: (text, record) => (
        <div>
          <Row gutter={10} align="top" wrap={false}>
            <Col>
              <Avatar
                className="icon-svg-sm"
                size={40}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M192 48c0-26.51 21.5-48 48-48h160c26.5 0 48 21.49 48 48v464h-80v-80c0-26.5-21.5-48-48-48s-48 21.5-48 48v80h-80V48zm120 16c-8.8 0-16 7.16-16 16v24h-24c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h24v24c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-24h24c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16h-24V80c0-8.84-7.2-16-16-16h-16zM160 96v416H48c-26.51 0-48-21.5-48-48V320h80c8.84 0 16-7.2 16-16s-7.16-16-16-16H0v-64h80c8.84 0 16-7.2 16-16s-7.16-16-16-16H0v-48c0-26.5 21.49-48 48-48h112zm432 0c26.5 0 48 21.5 48 48v48h-80c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v64h-80c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v144c0 26.5-21.5 48-48 48H480V96h112z" />
                  </svg>
                }
              />
            </Col>
            <Col>
              <h4>{record.chamberName}</h4>
              <span className="fs-14 clr-gray">{record.chamberAddress}</span>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      title: 'Schedule time',
      render: (text, record, index) => (
        <Row gutter={10} wrap={false}>
          <Col>
            <Avatar
              className="icon-svg-sm icon-svg-color-1"
              size={40}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M256 512C114.6 512 0 397.4 0 256S114.6 0 256 0s256 114.6 256 256-114.6 256-256 256zm-24-256c0 8 4 15.5 10.7 19.1l96 64c11 8.2 25.9 5.2 32.4-5.8 8.2-11 5.2-25.9-5.8-33.3L280 243.2V120c0-13.3-10.7-24-24.9-24-12.4 0-24 10.7-24 24l.9 136z" />
                </svg>
              }
            />
          </Col>
          <Col>
            <Row gutter={10}>
              <Col span={24}>
                <span>Start: </span>
                <span className="clr-gray"> {record.startTime}</span>
              </Col>
              <Col>
                <span>End: </span>
                <span className="clr-gray"> {record.endTime}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
    // {
    //   title: 'Days',
    //   render: (text, record) => (
    //     <div>
    //       <Row gutter={20} align="middle">
    //         <Col>
    //           {record.workingDays.map((day: string, index: number) => (
    //             <Tag color="green" key={index}>
    //               {day}
    //             </Tag>
    //           ))}
    //         </Col>
    //       </Row>
    //     </div>
    //   ),
    // },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (text, record, index, action) => {
        return (
          <Space>
            <>
              <Tooltip title="Delete">
                <Button
                  style={{
                    background: '#fff',
                    border: 'none',
                    boxShadow: 'none',
                  }}
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    Modal.confirm({
                      icon: <ExclamationCircleOutlined />,
                      content: <span>Are you sure to delete?</span>,
                      onOk() {
                        onDeleteSchedule(record.id, index);
                      },
                    });
                  }}
                  icon={
                    <span className="anticon anticon-dollar-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M0 256C0 114.6 114.6 0 256 0s256 114.6 256 256-114.6 256-256 256S0 397.4 0 256zm175-47.9 47.1 47L175 303c-9.3 9.4-9.3 24.6 0 33.1 9.4 10.2 24.6 10.2 33.1 0l47-46.2 47.9 46.2c9.4 10.2 24.6 10.2 33.1 0 10.2-8.5 10.2-23.7 0-33.1l-46.2-47.9 46.2-47c10.2-8.5 10.2-23.7 0-33.1-8.5-9.3-23.7-9.3-33.1 0l-47.9 47.1-47-47.1c-8.5-9.3-23.7-9.3-33.1 0-9.3 9.4-9.3 24.6 0 33.1z" />
                      </svg>
                    </span>
                  }
                />
              </Tooltip>
              <Tooltip title="Edit">
                <Button
                  onClick={() => navigate(record.editLink)}
                  style={{
                    background: '#fff',
                    border: 'none',
                    boxShadow: 'none',
                  }}
                  type="primary"
                  shape="circle"
                  icon={
                    <span className="anticon anticon-dollar-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M421.7 220.3 188.5 453.4l-33.9-33.9 3.5-3.5H112c-8.8 0-16-7.2-16-16v-46.1l-3.49 3.5c-4.73 4.8-8.2 10.6-10.09 17l-22.98 78.2 78.16-23c5.5-1.9 12.2-5.4 17-10.1l33.9 33.9c-10.4 10.4-23.3 18.1-37.4 22.2L30.77 511c-8.42 2.5-17.53.2-23.74-6.9-6.21-5.3-8.532-14.4-6.054-22.9L36.37 360.9c4.16-14.1 11.79-27 22.2-37.4L291.7 90.34l130 129.96zm71-161.55c25 24.99 25 65.55 0 90.55l-48.4 48.4-130-129.98 48.4-48.4c25-24.998 65.6-24.998 90.6 0l39.4 39.43z" />
                      </svg>
                    </span>
                  }
                />
              </Tooltip>
            </>
            <Tooltip title="View appointment">
              <Button
                onClick={() => {
                  setSchedule({ ...record, scheduleIndex: index });
                  setDetailsModal(true);
                }}
                style={{
                  background: '#fff',
                  border: 'none',
                  boxShadow: 'none',
                }}
                type="primary"
                shape="circle"
                icon={
                  <span className="anticon anticon-dollar-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path d="M279.6 160.4c2.8-.3 5.6-.4 8.4-.4 53 0 96 42.1 96 96 0 53-43 96-96 96-53.9 0-96-43-96-96 0-2.8.1-5.6.4-8.4 9.3 4.5 20.1 8.4 31.6 8.4 35.3 0 64-28.7 64-64 0-11.5-3.9-22.3-8.4-31.6zm201-47.8c46.8 43.4 78.1 94.5 92.9 131.1 3.3 7.9 3.3 16.7 0 24.6-14.8 35.7-46.1 86.8-92.9 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.58-80.6C48.62 355.1 17.34 304 2.461 268.3a31.967 31.967 0 0 1 0-24.6C17.34 207.1 48.62 156 95.42 112.6 142.5 68.84 207.2 32 288 32c80.8 0 145.5 36.84 192.6 80.6zM288 112c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144-64.5-144-144-144z" />
                    </svg>
                  </span>
                }
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const fetchSchedules = async ({ page }: any) => {
    try {
      setLoading(true);
      const response = await ScheduleManager.all({ page });
      setSchedules(response.data);
      setMeta(response.meta || null);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const onPaginateChange = (value: any) => {
    setPage(value);
    fetchSchedules({ page: value });
  };

  return (
    <>
      <div className="page__contents">
        <div></div>
        <Button type="primary" className="btn-create">
          <Link to="/schedules/create">Create Schedules</Link>
        </Button>
      </div>
      <ProTable
        dataSource={schedules}
        columns={columns}
        actionRef={actionRef}
        loading={loading}
        search={false}
        rowKey="id"
        cardBordered
        pagination={false}
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.perPage || 15}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title={`Schedule: ${schedule?.id}`}
        visible={detailsModal}
        onCancel={() => setDetailsModal(false)}
        footer={false}
      >
        {schedule ? (
          <div className="bg-white" style={{ padding: '20px' }}>
            <Row gutter={20} align="middle">
              <Col>
                <Avatar
                  className="icon-svg-sm"
                  size={40}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M352 128c0 70.7-57.3 128-128.9 128-69.8 0-128-57.3-128-128 0-70.69 58.2-128 128-128C294.7 0 352 57.31 352 128zm-64.9 234c-26.7 7.1-48 32.2-48 62v24c0 4.2 2.6 8.3 5.6 11.3l16 16c6.2 6.3 16.4 6.3 22.6 0 6.3-6.2 6.3-16.4 0-22.6l-12.2-11.3V424c0-17.7 15.2-32 32-32 18.6 0 32.9 14.3 32.9 32v17.4l-11.3 11.3c-6.3 6.2-6.3 16.4 0 22.6 6.2 6.3 16.4 6.3 22.6 0l16-16c3-3 4.7-7.1 4.7-11.3v-24c0-29.8-20.4-54.9-48-62v-53.2c73.5 17.9 128 83.3 128 163.2v8c0 17.7-14.3 32-32 32H32c-17.67 0-32-14.3-32-32v-8c0-79 54.53-145.3 128-163.2v61.5c-23.1 6.9-40 28.3-40 53.7 0 30.9 25.1 56 56 56s56-25.1 56-56c0-25.4-16.9-46.8-40-53.7v-66.1c2.7-.1 5.3-.2 8-.2h112c2.7 0 5.3.1 8 .2l-.9 57.8zm-120 62c0 13.3-9.8 24-24 24-12.4 0-24-10.7-24-24s11.6-24 24-24c14.2 0 24 10.7 24 24z" />
                    </svg>
                  }
                />
              </Col>
              <Col>
                <h3 className="m-0">{schedule.doctorName}</h3>
                <span className="clr-gray fw-400 fs-13">
                  {schedule.doctorSpecialties}
                </span>
              </Col>
            </Row>
            <Divider />
            <Row gutter={20}>
              <Col>
                <Avatar
                  className="icon-svg-sm"
                  size={40}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path d="M192 48c0-26.51 21.5-48 48-48h160c26.5 0 48 21.49 48 48v464h-80v-80c0-26.5-21.5-48-48-48s-48 21.5-48 48v80h-80V48zm120 16c-8.8 0-16 7.16-16 16v24h-24c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h24v24c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-24h24c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16h-24V80c0-8.84-7.2-16-16-16h-16zM160 96v416H48c-26.51 0-48-21.5-48-48V320h80c8.84 0 16-7.2 16-16s-7.16-16-16-16H0v-64h80c8.84 0 16-7.2 16-16s-7.16-16-16-16H0v-48c0-26.5 21.49-48 48-48h112zm432 0c26.5 0 48 21.5 48 48v48h-80c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v64h-80c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v144c0 26.5-21.5 48-48 48H480V96h112z" />
                    </svg>
                  }
                />
              </Col>
              <Col>
                <h4>{schedule.chamberName}</h4>
                <span className="fs-14 clr-gray">
                  {schedule.chamberAddress}
                </span>
              </Col>
            </Row>
            <Divider />
            <Row gutter={10}>
              <Col>
                <Avatar
                  className="icon-svg-sm icon-svg-color-1"
                  size={40}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 512C114.6 512 0 397.4 0 256S114.6 0 256 0s256 114.6 256 256-114.6 256-256 256zm-24-256c0 8 4 15.5 10.7 19.1l96 64c11 8.2 25.9 5.2 32.4-5.8 8.2-11 5.2-25.9-5.8-33.3L280 243.2V120c0-13.3-10.7-24-24.9-24-12.4 0-24 10.7-24 24l.9 136z" />
                    </svg>
                  }
                />
              </Col>
              <Col>
                <Row gutter={10}>
                  <Col span={24}>
                    <span>Start: </span>
                    <span className="clr-gray"> {schedule.startTime}</span>
                  </Col>
                  <Col>
                    <span>End: </span>
                    <span className="clr-gray"> {schedule.endTime}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row className="mt-2">
              <Col>
                <span>Consultation: </span>
                <span className="clr-gray"> {schedule.consultation}</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <span>Max patient: </span>
                <span className="clr-gray"> {schedule.maxPatient}</span>
              </Col>
            </Row>
            <Divider />
            <div>
              <h3>Fee:</h3>
              <Row className="mt-2" gutter={10}>
                <Col>
                  <span>Advance: </span>
                  <span className="clr-gray"> {schedule.advanceFee}</span>
                </Col>
                <Col>
                  <span>New: </span>
                  <span className="clr-gray"> {schedule.newPatientFee}</span>
                </Col>
              </Row>
              <Row className="mt-2" gutter={10}>
                <Col>
                  <span>Old: </span>
                  <span className="clr-gray"> {schedule.oldPatientFee}</span>
                </Col>
                <Col>
                  <span>Report: </span>
                  <span className="clr-gray"> {schedule.reportFee}</span>
                </Col>
              </Row>
            </div>
            <Divider />
            <div>
              {schedule.workingDays.map((day: string, index: number) => (
                <Tag key={index} color="green">
                  {day}
                </Tag>
              ))}
            </div>
            <Divider />
            <h3>Sessions</h3>
            {schedule.sessions.map((session: any, index: number) => (
              <div key={index}>
                <Row gutter={10}>
                  <Col>Quota: {session.quota}</Col>
                  <Col>Duration: {session.duration}</Col>
                  <Col>Fee: {session.fee}</Col>
                </Row>
              </div>
            ))}
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default ScheduleList;
