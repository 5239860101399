/* eslint-disable no-console */
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { Button, Col, Modal, Pagination, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import TemplateManager from '../../api/templates/request';
import { DATE_FORMAT } from '../../configs/constants';
import { Meta } from '../../interfaces/api';
import Delete from '../delete/Delete';
import TemplateTest from '../templateTest/TemplateTest';
import View from '../view/View';

const TemplateList = () => {
  const actionRef = useRef<ActionType>();
  const [templates, setTemplates] = useState<Array<any>>([]);
  const [meta, setMeta] = useState<Meta | null>(null);
  const [loading, setLoading] = useState(true);
  const [testModal, setTestModal] = useState(false);
  const [tested, setTested] = useState<any>(null);
  const location = useLocation();
  const params = useParams();
  const [testableTemplate, setTestableTemplate] = useState<number | null>(null);

  const [filterParams, setFilterParams] = useState({
    start_date: moment().format(DATE_FORMAT),
    page: 1,
    per_page: 30,
  });

  useEffect(() => {
    fetchTemplates({ ...filterParams, ...params });
  }, []);

  useEffect(() => {
    const paramsObj = Object.fromEntries(new URLSearchParams(location.search));
    fetchTemplates(paramsObj);
  }, [location]);

  const fetchTemplates = async (requestParam: any) => {
    setLoading(true);
    try {
      const response = await TemplateManager.all(requestParam);
      setTemplates(response.data);
      setMeta(response.meta);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const openTestModal = async (id: number) => {
    setTestableTemplate(id);
    setTestModal(true);
  };

  const columns: ProColumns<any>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
    },
    {
      title: 'Event',
      dataIndex: 'event',
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (text, record, index, action) => {
        return (
          <div>
            <Row gutter={10} align="middle">
              <Col>
                <Delete
                  method={TemplateManager.remove}
                  id={record.id}
                  onDelete={(payload: any) => {
                    if (payload.success === 'template_deleted') {
                      setTemplates((prevState) =>
                        prevState.filter((itr) => itr.id !== record.id)
                      );
                    }
                  }}
                />
              </Col>
              <Col>
                <View
                  title="Template view"
                  content={
                    <div>
                      <Row>
                        <Col span={24}>
                          <strong style={{ marginRight: '10px' }}>
                            title:
                          </strong>
                          {record.title}
                        </Col>
                        <Col span={24}>
                          <strong style={{ marginRight: '10px' }}>
                            subject:
                          </strong>
                          {record.subject}
                        </Col>
                        <Col span={24}>
                          <strong style={{ marginRight: '10px' }}>
                            channel:
                          </strong>
                          {record.channel}
                        </Col>
                        <Col span={24}>
                          <strong style={{ marginRight: '10px' }}>
                            message:
                          </strong>
                          {record.message}
                        </Col>
                      </Row>
                    </div>
                  }
                />
              </Col>
              <Col>
                <Button onClick={() => openTestModal(record.id)}>Test</Button>
              </Col>
              <Col>
                <Button type="primary">
                  <Link to={`/templates/${record.id}/update`}>Edit</Link>
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];

  const onPaginateChange = (value: number) => {
    const params = { ...filterParams, page: value };
    setFilterParams(params);
    fetchTemplates(params);
  };
  const onTestHandler = (test: any) => {
    setTested(test.data);
  };
  const onCancelHandler = () => {
    setTestModal(false);
    setTested(null);
    setTestableTemplate(null);
  };
  return (
    <>
      <div className="page__contents">
        <div></div>
        <Button type="primary" className="btn-create">
          <Link to="/templates/create">Create Template</Link>
        </Button>
      </div>
      <ProTable<any>
        dataSource={templates}
        columns={columns}
        actionRef={actionRef}
        search={false}
        pagination={false}
        rowKey="id"
        cardBordered
        loading={loading}
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.perPage || 15}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title="Test template"
        footer={null}
        onCancel={onCancelHandler}
        visible={testModal}
      >
        {testableTemplate ? (
          tested ? (
            <div>{tested.message}</div>
          ) : (
            <TemplateTest
              onTested={onTestHandler}
              templateId={testableTemplate}
            />
          )
        ) : null}
      </Modal>
    </>
  );
};

export default TemplateList;
