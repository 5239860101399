import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable, TableDropdown } from '@ant-design/pro-components';
import {
  Avatar,
  Button,
  Col,
  message,
  Modal,
  Pagination,
  Radio,
  Row,
  Space,
  Tooltip,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ChamberManager from '../../api/chambers/request';
import { deactivatingStatus } from '../../enums/status';
import { Meta } from '../../interfaces/api';
import { ChamberInterface } from '../../interfaces/chamber';
import Toggle from '../toggle/Toggle';
import View from '../view/View';

const ChamberList = () => {
  const actionRef = useRef<ActionType>();
  const [chambers, setChambers] = useState<Array<ChamberInterface>>([]);
  const [meta, setMeta] = useState<Meta | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [filterInput, setFilterInput] = useState({
    status: 'active',
  });

  useEffect(() => {
    const paramsObj: any = Object.fromEntries(
      new URLSearchParams(location.search)
    );
    // eslint-disable-next-line no-console
    console.log(meta);
    fetchChambers({
      ...paramsObj,
      page: meta?.currentPage ? meta.currentPage : 1,
    });
    // eslint-disable-next-line no-console
    console.log('changed');
  }, [location]);

  const navigate = useNavigate();

  const columns: ProColumns[] = [
    {
      title: 'Chamber',
      dataIndex: 'name',
      render: (text, record, index) => {
        return (
          <div>
            <Row gutter={20} wrap={false}>
              <Col>
                <Avatar
                  className="icon-svg-sm"
                  size={40}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path d="M192 48c0-26.51 21.5-48 48-48h160c26.5 0 48 21.49 48 48v464h-80v-80c0-26.5-21.5-48-48-48s-48 21.5-48 48v80h-80V48zm120 16c-8.8 0-16 7.16-16 16v24h-24c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h24v24c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-24h24c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16h-24V80c0-8.84-7.2-16-16-16h-16zM160 96v416H48c-26.51 0-48-21.5-48-48V320h80c8.84 0 16-7.2 16-16s-7.16-16-16-16H0v-64h80c8.84 0 16-7.2 16-16s-7.16-16-16-16H0v-48c0-26.5 21.49-48 48-48h112zm432 0c26.5 0 48 21.5 48 48v48h-80c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v64h-80c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v144c0 26.5-21.5 48-48 48H480V96h112z" />
                    </svg>
                  }
                />
              </Col>
              <Col>
                <h4>{record.name}</h4>
                <span className="fs-14 clr-gray">{record.address}</span>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: 'Doctor',
      render: (text, record) => {
        return (
          <Row gutter={20} wrap={false}>
            <Col>
              <Avatar
                className="icon-svg-sm"
                size={40}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M352 128c0 70.7-57.3 128-128.9 128-69.8 0-128-57.3-128-128 0-70.69 58.2-128 128-128C294.7 0 352 57.31 352 128zm-64.9 234c-26.7 7.1-48 32.2-48 62v24c0 4.2 2.6 8.3 5.6 11.3l16 16c6.2 6.3 16.4 6.3 22.6 0 6.3-6.2 6.3-16.4 0-22.6l-12.2-11.3V424c0-17.7 15.2-32 32-32 18.6 0 32.9 14.3 32.9 32v17.4l-11.3 11.3c-6.3 6.2-6.3 16.4 0 22.6 6.2 6.3 16.4 6.3 22.6 0l16-16c3-3 4.7-7.1 4.7-11.3v-24c0-29.8-20.4-54.9-48-62v-53.2c73.5 17.9 128 83.3 128 163.2v8c0 17.7-14.3 32-32 32H32c-17.67 0-32-14.3-32-32v-8c0-79 54.53-145.3 128-163.2v61.5c-23.1 6.9-40 28.3-40 53.7 0 30.9 25.1 56 56 56s56-25.1 56-56c0-25.4-16.9-46.8-40-53.7v-66.1c2.7-.1 5.3-.2 8-.2h112c2.7 0 5.3.1 8 .2l-.9 57.8zm-120 62c0 13.3-9.8 24-24 24-12.4 0-24-10.7-24-24s11.6-24 24-24c14.2 0 24 10.7 24 24z" />
                  </svg>
                }
              />
            </Col>
            <Col>
              <h3 className="m-0">{record?.doctorName}</h3>
              <span className="clr-gray fw-400 fs-13">
                {record.doctorSpecialties}
              </span>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Fee',
      render: (text, record, index) => {
        return (
          <div>
            <Row className="mt-2" gutter={10}>
              <Col>
                <span>Advance: </span>
                <span className="clr-gray"> {record.advanceFee} BDT</span>
              </Col>
              <Col>
                <span>New: </span>
                <span className="clr-gray"> {record.newPatientFee} BDT</span>
              </Col>
            </Row>
            <Row className="mt-2" gutter={10}>
              <Col>
                <span>Old: </span>
                <span className="clr-gray"> {record.oldPatientFee} BDT</span>
              </Col>
              <Col>
                <span>Report: </span>
                <span className="clr-gray"> {record.reportFee} BDT</span>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (text, record, index) => {
        return (
          <div>
            <Space>
              <>
                <Toggle
                  method={
                    record.status.value === 0
                      ? ChamberManager.activate
                      : ChamberManager.deactivate
                  }
                  id={record.id}
                  icon={
                    record.status.value === 0 ? (
                      <PlusCircleOutlined
                        style={{ color: '#000', fontSize: '24px' }}
                      />
                    ) : (
                      <MinusCircleOutlined
                        style={{ color: '#000', fontSize: '24px' }}
                      />
                    )
                  }
                  confirmLabel={
                    record.status.value === 0
                      ? 'Are you sure to activate?'
                      : 'Are you sure to deactivate?'
                  }
                  onDelete={(payload: any) => {
                    if (payload.success) {
                      const prevChambers: any = [...chambers];
                      prevChambers[index].status = {
                        value: 0,
                        label:
                          payload.success === 'chamber_deactivated'
                            ? 'Inactive'
                            : 'Active',
                        color:
                          payload.success === 'chamber_deactivated'
                            ? 'red'
                            : 'green',
                      };
                      setChambers(prevChambers);
                      message.success(
                        `Chamber ${
                          payload.success === 'chamber_deactivated'
                            ? 'deactivated'
                            : 'activated'
                        } successfully!`
                      );
                    }
                  }}
                />
                <Tooltip title="Edit">
                  <Button
                    onClick={() => navigate(record.editLink)}
                    style={{
                      background: '#fff',
                      border: 'none',
                      boxShadow: 'none',
                    }}
                    type="primary"
                    shape="circle"
                    icon={
                      <span className="anticon anticon-dollar-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M421.7 220.3 188.5 453.4l-33.9-33.9 3.5-3.5H112c-8.8 0-16-7.2-16-16v-46.1l-3.49 3.5c-4.73 4.8-8.2 10.6-10.09 17l-22.98 78.2 78.16-23c5.5-1.9 12.2-5.4 17-10.1l33.9 33.9c-10.4 10.4-23.3 18.1-37.4 22.2L30.77 511c-8.42 2.5-17.53.2-23.74-6.9-6.21-5.3-8.532-14.4-6.054-22.9L36.37 360.9c4.16-14.1 11.79-27 22.2-37.4L291.7 90.34l130 129.96zm71-161.55c25 24.99 25 65.55 0 90.55l-48.4 48.4-130-129.98 48.4-48.4c25-24.998 65.6-24.998 90.6 0l39.4 39.43z" />
                        </svg>
                      </span>
                    }
                  />
                </Tooltip>
              </>
              <View
                title="Chamber details"
                content={
                  <div className="details__content bg-white">
                    <div className="details__content__item">
                      <h4>{record?.name}</h4>
                    </div>
                    <div className="details__content__item">
                      <p>{record?.contactNo}</p>
                      <p>{record?.email}</p>
                    </div>
                  </div>
                }
              />
            </Space>
          </div>
        );
      },
    },
  ];

  const fetchChambers = async (requestParams: any) => {
    try {
      setLoading(true);
      const response = await ChamberManager.all(requestParams);
      setChambers(response.data);
      setMeta(response.meta || null);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const onPaginateChange = (page: number) => {
    console.error(page);
  };

  const onChangeStatusFilter = (ev: any) => {
    const filterObj: any = { ...filterInput };
    filterObj['status'] = ev.target.value;
    navigate({
      pathname: location.pathname,
      search: createSearchParams(filterObj).toString(),
    });
    setFilterInput({
      status: ev.target.value,
    });
  };
  return (
    <>
      <div className="page__contents">
        <div>
          <Radio.Group
            options={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inactive',
              },
            ]}
            onChange={onChangeStatusFilter}
            value={filterInput?.status}
            optionType="button"
          />
        </div>
        <Button type="primary" className="btn-create">
          <Link to="/chambers/create">Create Chambers</Link>
        </Button>
      </div>
      <ProTable
        dataSource={chambers}
        columns={columns}
        actionRef={actionRef}
        search={false}
        loading={loading}
        rowKey="id"
        cardBordered
        pagination={false}
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
          onChange(value) {
            console.error('value: ', value);
          },
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
        />
      </div>
    </>
  );
};

export default ChamberList;
