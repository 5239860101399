import React from 'react';
import './AccountLogoutDropdown.scss';
import { Avatar, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { LOCAL_LOGOUT_ENDPOINT } from '../../configs/endpoints';
import { FlexObj, getSessionUserInfo } from '../../store/ducks/session';
import { Store } from 'redux';
import { connect } from 'react-redux';

interface AccountLogoutDropdownProps {
  userInfo: FlexObj;
}

const AccountLogoutDropdown: React.FC<AccountLogoutDropdownProps> = (
  props: AccountLogoutDropdownProps
) => {
  const { userInfo } = props;

  return (
    <div className="AccountLogoutDropdown-container">
      <div className="AccountLogoutDropdown-avatar">
        <Avatar src={userInfo.avatar} size={50}>
          <div>{userInfo?.name?.slice(0, 2)}</div>
        </Avatar>
      </div>
      <div className="AccountLogoutDropdown-title"> {userInfo.name || ''} </div>
      <div className="AccountLogoutDropdown-email">{userInfo.email || ''}</div>
      <Link to="/account">Account</Link>
      <Divider />
      <div className="AccountLogoutDropdown-logout">
        <Link to={LOCAL_LOGOUT_ENDPOINT}>
          <i className="fas fa-sign-out-alt"></i> Sign Out{' '}
        </Link>
      </div>
      <Divider />
      <div className="AccountLogoutDropdown-policy">
        <div> Terms of Service </div>
        <div className="AccountLogoutDropdown-dot" />
        <div> Privacy Policy </div>
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  userInfo: FlexObj;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect AccountLogoutDropdown to the redux store */
const ConnectedAccountLogoutDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountLogoutDropdown);

export default ConnectedAccountLogoutDropdown;
