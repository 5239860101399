import React from 'react';
import Layout from '../components/layout/Layout';
import PatientList from '../components/patientList/PatientList';

const Patients = () => (
  <Layout>
    <PatientList />
  </Layout>
);
export default Patients;
