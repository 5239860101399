import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Store } from 'redux';
import { LOCAL_PERSONAL_INFO_ENDPOINT } from '../../configs/endpoints';
import { isAuthenticated } from '../../store/ducks/session';

const GuestRoute: React.FC = ({ children, isAuthenticated, ...rest }: any) => {
  if (isAuthenticated) {
    return <Navigate to={'/dashboard'} replace />;
  }

  return children;
};

/** connect the component to the store */

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>, parentProps: any): any => {
  const result = {
    ...parentProps,
    isAuthenticated: isAuthenticated(state),
  };
  return result;
};

/** connect SignIn to the redux store */
const ConnectedGuestRoute = connect(mapStateToProps, {})(GuestRoute);

export default ConnectedGuestRoute;
