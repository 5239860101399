import React from 'react';
import Layout from '../components/layout/Layout';
import UserList from '../components/userList/UserList';

const Users = () => (
  <Layout>
    <UserList />
  </Layout>
);
export default Users;
