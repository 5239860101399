import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import lodash from 'lodash';
import { getMaxHeight } from '../../services/utility';

/** interface for ProjectObj object */
export interface ProjectObj {
  id: string;
  title: string;
  color: string;
  order: number;
  isFavourite: boolean;
  isHidden: boolean;
  parent: string;
  expanded: boolean;
  synced: boolean;
}

/** The reducer name */
export const reducerName = 'projects';

// actions
/** action types */
export const SET_PROJECTS = 'virtunus/reducer/projects/SET_PROJECTS';
export const UPDATE_PROJECTS = 'virtunus/reducer/projects/UPDATE_PROJECTS';
export const SET_PROJECT = 'virtunus/reducer/projects/SET_PROJECT';
export const FIX_PROJECT_ORDERS =
  'virtunus/reducer/projects/FIX_PROJECT_ORDERS';
export const SET_PROJECTS_SYNCED_STATUS =
  'virtunus/reducer/projects/SET_PROJECTS_SYNCED_STATUS';

/** interface for SET_PROJECTS action */
export interface SetProjectsAction extends AnyAction {
  projects: ProjectObj[];
  type: typeof SET_PROJECTS;
}

/** interface for UPDATE_PROJECTS action */
export interface UpdateProjectsAction extends AnyAction {
  projects: ProjectObj[];
  type: typeof UPDATE_PROJECTS;
  ids: string[];
}

/** interface for SET_PROJECT action */
export interface SetProjectAction extends AnyAction {
  project: ProjectObj;
  type: typeof SET_PROJECT;
}

/** interface for FIX_PROJECT_ORDERS action */
export interface FixProjectOrdersAction extends AnyAction {
  parentId: string;
  type: typeof FIX_PROJECT_ORDERS;
}

/** interface for SET_PROJECTS_SYNCED_STATUS action */
export interface SetProjectsSyncedStatusAction extends AnyAction {
  status: boolean;
  type: typeof SET_PROJECTS_SYNCED_STATUS;
  ids: string[];
}

/** Create type for project reducer actions */
export type ProjectsActionTypes =
  | SetProjectsAction
  | UpdateProjectsAction
  | SetProjectAction
  | FixProjectOrdersAction
  | SetProjectsSyncedStatusAction
  | AnyAction;

// action creators

/** set projects action creator
 * @param {ProjectObj} projects - an array of project items
 * @returns {SetProjectsAction} - an action to set projects in store
 */
export const setProjects = (projects: ProjectObj[]): SetProjectsAction => ({
  projects,
  type: SET_PROJECTS,
});

/** update projects action creator
 * @param {ProjectObj} projects - an array of project items
 * @returns {UpdateProjectsAction} - an action to update projects in store
 */
export const updateProjects = (
  projects: ProjectObj[]
): UpdateProjectsAction => {
  const ids = projects.map((project: ProjectObj) => project.id);
  return {
    projects,
    type: UPDATE_PROJECTS,
    ids,
  };
};

/** set projects action creator
 * @param {ProjectObj} project - a project object
 * @returns {SetProjectAction} - an action to set project in store
 */
export const setProject = (project: ProjectObj): SetProjectAction => ({
  project,
  type: SET_PROJECT,
});

/**
 * set the project orders correctly
 * @param {string} parentId - the parent project id
 * @returns {FixProjectOrdersAction} - an action to fix the projects order given the parent project id
 */
export const fixProjectOrders = (parentId: string): FixProjectOrdersAction => ({
  parentId,
  type: FIX_PROJECT_ORDERS,
});

/** set projects synced status action creator
 * @param {string[]} ids - an array of project ids
 * @param {boolean} status - the synced status to set
 * @returns {SetProjectsSyncedStatusAction} - an action to set projects synced status in store
 */
export const setProjectsSyncedStatus = (
  ids: string[],
  status: boolean
): SetProjectsSyncedStatusAction => ({
  status,
  type: SET_PROJECTS_SYNCED_STATUS,
  ids,
});

// the reducer

/** interface for projects state in redux store */
type ProjectsState = ProjectObj[];

/** Create an immutable projects state */
export type ImmutableProjectsState =
  SeamlessImmutable.ImmutableArray<ProjectsState>;

/** initial projects state */
const initialState: ImmutableProjectsState = SeamlessImmutable([]);

/** the projects reducer function */
export default function reducer(
  state: ImmutableProjectsState = initialState,
  action: ProjectsActionTypes
): ImmutableProjectsState {
  switch (action.type) {
    case SET_PROJECTS:
      return SeamlessImmutable(action.projects);
    case UPDATE_PROJECTS:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateProj: ProjectObj) => !action.ids.includes(iterateProj.id)
        ),
        ...action.projects,
      ]);
    case SET_PROJECT:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateProject: ProjectObj) =>
            iterateProject.id !== action.project.id
        ),
        action.project,
      ]);
    case FIX_PROJECT_ORDERS:
      return SeamlessImmutable([
        ...lodash.filter(
          state as any,
          (iterateProject: ProjectObj) =>
            iterateProject.parent !== action.parentId
        ),
        ...lodash.map(
          lodash.filter(
            state,
            (iterateProject: ProjectObj) =>
              iterateProject.parent === action.parentId
          ),
          (iterateProject: ProjectObj, index: number) => ({
            ...iterateProject,
            order: index,
          })
        ),
      ]);
    case SET_PROJECTS_SYNCED_STATUS:
      return SeamlessImmutable(
        lodash.map(state, (iterateProject: ProjectObj) => {
          return action.ids.includes(iterateProject.id)
            ? { ...iterateProject, synced: action.status }
            : iterateProject;
        }) as any
      );
    default:
      return state;
  }
}

// selectors

/** returns the projects list
 * @param {Partial<Store>} state - the redux store
 * @return { ProjectObj[] } - the existing projects
 */
export function getProjects(state: Partial<Store>): ProjectObj[] {
  return (state as any)[reducerName];
}

/** returns the projects list length
 * @param {Partial<Store>} state - the redux store
 * @return { number } - the existing projects length
 */
export function getProjectsLength(state: Partial<Store>): number {
  return (state as any)[reducerName].length;
}

/** returns the project obj given project id if exists; otherwise, null
 * @param {Partial<Store>} state - the redux store
 * @param {string} projectId - the project id
 * @return { ProjectObj | null } - the existing project or null
 */
export function getProjectById(
  state: Partial<Store>,
  projectId: string
): ProjectObj {
  return lodash.find((state as any)[reducerName], { id: projectId }) || null;
}

/** returns the new available order in list given a project id as parent
 * @param {Partial<Store>} state - the redux store
 * @param {string} project - the project id
 * @return { number } - the new available order
 */
export function getNewProjectOrderbyProjectId(
  state: Partial<Store>,
  projectId: string
): number {
  return lodash.filter((state as any)[reducerName], { parent: projectId })
    .length;
}

/** returns the maximum height or depth given a project id
 * @param {Partial<Store>} state - the redux store
 * @param {string} project - the project id
 * @return { number } - the maximum height
 */
export function getMaxHeightOfProject(
  state: Partial<Store>,
  projectId: string
): number {
  return getMaxHeight((state as any)[reducerName], projectId);
}

/**
 * returns the list of unsynced projects
 * @param {Partial<Store>} state - the redux store
 * @returns {ProjectObj[]} - the list of unsynced projects
 */
export function getUnsyncedProjects(state: Partial<Store>): ProjectObj[] {
  return lodash.filter((state as any)[reducerName], { synced: false });
}
