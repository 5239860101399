import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ScheduleManager from '../api/schedules/request';
import Layout from '../components/layout/Layout';
import ScheduleFormContent from '../components/scheduleFormContent/ScheduleFormContent';

const UpdateSchedule = () => {
  const [schedule, setSchedule] = useState<any>(null);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getScheduleById(id);
    }
  }, [id]);
  const getScheduleById = async (id: any) => {
    try {
      const response = await ScheduleManager.get({ id });
      setSchedule(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      {schedule ? (
        <ScheduleFormContent pageLabel="Update Schedule" schedule={schedule} />
      ) : null}
    </Layout>
  );
};

export default UpdateSchedule;
