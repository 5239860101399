/* eslint-disable no-console */
import { ProDescriptions } from '@ant-design/pro-components';
import { Avatar, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ChamberManager from '../api/chambers/request';
import Layout from '../components/layout/Layout';

const ChamberDetails = () => {
  const { id }: any = useParams();
  const [chamber, setChamber] = useState<any>(null);

  useEffect(() => {
    fetchChamber();
  }, []);

  const fetchChamber = async () => {
    try {
      if (id) {
        const response = await ChamberManager.get({ id });
        setChamber(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <ProDescriptions column={2}>
        <ProDescriptions.Item valueType="option">
          <Button key="primary" type="primary">
            <Link to="/chambers">Back</Link>
          </Button>
        </ProDescriptions.Item>
        <ProDescriptions.Item
          span={2}
          valueType="text"
          contentStyle={{
            maxWidth: '80%',
          }}
          ellipsis
        >
          <div className="details__content bg-white">
            <div className="details__content__item">
              <h4>{chamber?.name}</h4>
            </div>
            <div className="details__content__item">
              <p>{chamber?.contactNo}</p>
              <p>{chamber?.email}</p>
            </div>
          </div>
        </ProDescriptions.Item>
      </ProDescriptions>
    </Layout>
  );
};

export default ChamberDetails;
