/* eslint-disable no-console */
import endpoints from '../../configs/endpoints';
import { Meta } from '../../interfaces/api';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import { mapMeta } from '../util';
import Mapper from './mapper';

interface ResponseInterface {
  data: Array<any> | any | null | any;
  meta?: Meta | null;
}

/**
 * Fetch all chambers for the current provider with pagination
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const all = async (request: any): Promise<ResponseInterface> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_SCHEDULES_ENDPOINT.replace(':providerId', getProviderId()),
    request,
    true
  );
  console.log(response);
  return {
    meta: Mapper.meta(response.data.meta),
    data: Mapper.dataFromSTC(response.data.data),
  };
};

/**
 * Fetch a chamber by id
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const get = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_SCHEDULE_SHOW_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':scheduleId', request.id),
    request,
    true
  );
  return {
    data: Mapper.dataDetailsItemFromSTC(response.data.data),
  };
};

/**
 * create a new chamber
 * @param request the request body
 * @returns {Promise<ScheduleDetailsInterface>}
 */
export const create = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_SCHEDULES_STORE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return {
    data: response.data.data,
  };
};

/**
 * create a new doctor
 * @param request the request body
 * @returns {Promise<ScheduleDetailsInterface>} the updated doctor
 */
export const update = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_SCHEDULE_UPDATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':scheduleId', request.id),
    request,
    true
  );
  return {
    data: response.data.data,
  };
};

/**
 * create a new doctor
 * @param request the request body
 * @returns {Promise<ScheduleDetailsInterface>} the updated doctor
 */
export const getConsultantSchedule = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_CONSULTANT_SCHEDULE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':doctorId', request.doctor_id),
    request,
    true
  );
  const data = response.data.data
    .reduce((a: any, b: any) => [...a, ...b.time_slots], [])
    .filter((itr: any) => itr.available);

  return {
    data,
  };
};

export const remove = async (
  request: any
): Promise<{
  data: any;
}> => {
  request['_method'] = 'DELETE';
  const response = await axioService(
    POST,
    endpoints.SERVER_SCHEDULE_SHOW_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':scheduleId', request.id),
    request,
    true
  );
  console.log(response.data);
  return {
    data: response.data,
  };
};

const ScheduleManager = {
  all,
  get,
  update,
  create,
  remove,
  getConsultantSchedule,
};

export default ScheduleManager;
