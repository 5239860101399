import React from 'react';
import endpoints from '../../configs/endpoints';

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M64 400c0 8.8 7.16 16 16 16h400c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.18 0-80-35.8-80-80V64c0-17.67 14.33-32 32-32s32 14.33 32 32v336zm278.6-121.4c-12.5 12.5-32.7 12.5-45.2 0L240 221.3l-89.4 89.3c-12.5 12.5-32.7 12.5-45.2 0-12.52-12.5-12.52-32.7 0-45.2l112-112c12.5-12.5 32.7-12.5 45.2 0l57.4 57.3 105.4-105.3c12.5-12.52 32.7-12.52 45.2 0 12.5 12.5 12.5 32.7 0 45.2l-128 128z" />
      </svg>
    ),
  },
  {
    path: endpoints.LOCAL_APPOINTMENTS_ENDPOINT,
    name: 'Appointments',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path d="M160 32v32h128V32c0-17.67 14.3-32 32-32s32 14.33 32 32v32h48c26.5 0 48 21.49 48 48v48H0v-48c0-26.51 21.49-48 48-48h48V32c0-17.67 14.3-32 32-32s32 14.33 32 32zM0 192h448v272c0 26.5-21.5 48-48 48H48c-26.51 0-48-21.5-48-48V192zm328.1 112.1c10.2-8.5 10.2-23.7 0-33.1-8.5-9.3-23.7-9.3-33.1 0l-95 95.1-47.9-47.1c-8.5-9.3-23.7-9.3-33.1 0-9.3 9.4-9.3 24.6 0 33.1l64 64c9.4 10.2 24.6 10.2 33.1 0l112-112z" />
      </svg>
    ),
    exact: true,
  },
  {
    path: endpoints.LOCAL_PATIENTS_ENDPOINT,
    name: 'Patients',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path d="M176 288c44.1 0 80-35.9 80-80s-35.9-80-80-80-80 35.9-80 80 35.9 80 80 80zm368-160H304c-8.8 0-16 7.2-16 16v176H64V48c0-8.84-7.16-16-16-16H16C7.163 32 0 39.16 0 48v416c0 8.8 7.163 16 16 16h32c8.84 0 16-7.2 16-16v-48h512v48c0 8.837 7.163 16 16 16h32c8.837 0 16-7.163 16-16V224c0-53.9-43-96-96-96z" />
      </svg>
    ),
  },
  {
    path: endpoints.LOCAL_DOCTORS_ENDPOINT,
    name: 'Doctors',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path d="M352 128c0 70.7-57.3 128-128.9 128-69.8 0-128-57.3-128-128 0-70.69 58.2-128 128-128C294.7 0 352 57.31 352 128zm-64.9 234c-26.7 7.1-48 32.2-48 62v24c0 4.2 2.6 8.3 5.6 11.3l16 16c6.2 6.3 16.4 6.3 22.6 0 6.3-6.2 6.3-16.4 0-22.6l-12.2-11.3V424c0-17.7 15.2-32 32-32 18.6 0 32.9 14.3 32.9 32v17.4l-11.3 11.3c-6.3 6.2-6.3 16.4 0 22.6 6.2 6.3 16.4 6.3 22.6 0l16-16c3-3 4.7-7.1 4.7-11.3v-24c0-29.8-20.4-54.9-48-62v-53.2c73.5 17.9 128 83.3 128 163.2v8c0 17.7-14.3 32-32 32H32c-17.67 0-32-14.3-32-32v-8c0-79 54.53-145.3 128-163.2v61.5c-23.1 6.9-40 28.3-40 53.7 0 30.9 25.1 56 56 56s56-25.1 56-56c0-25.4-16.9-46.8-40-53.7v-66.1c2.7-.1 5.3-.2 8-.2h112c2.7 0 5.3.1 8 .2l-.9 57.8zm-120 62c0 13.3-9.8 24-24 24-12.4 0-24-10.7-24-24s11.6-24 24-24c14.2 0 24 10.7 24 24z" />
      </svg>
    ),
    routes: [
      {
        path: endpoints.LOCAL_DOCTORS_ENDPOINT,
        name: 'Doctors List',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M352 128c0 70.7-57.3 128-128.9 128-69.8 0-128-57.3-128-128 0-70.69 58.2-128 128-128C294.7 0 352 57.31 352 128zm-64.9 234c-26.7 7.1-48 32.2-48 62v24c0 4.2 2.6 8.3 5.6 11.3l16 16c6.2 6.3 16.4 6.3 22.6 0 6.3-6.2 6.3-16.4 0-22.6l-12.2-11.3V424c0-17.7 15.2-32 32-32 18.6 0 32.9 14.3 32.9 32v17.4l-11.3 11.3c-6.3 6.2-6.3 16.4 0 22.6 6.2 6.3 16.4 6.3 22.6 0l16-16c3-3 4.7-7.1 4.7-11.3v-24c0-29.8-20.4-54.9-48-62v-53.2c73.5 17.9 128 83.3 128 163.2v8c0 17.7-14.3 32-32 32H32c-17.67 0-32-14.3-32-32v-8c0-79 54.53-145.3 128-163.2v61.5c-23.1 6.9-40 28.3-40 53.7 0 30.9 25.1 56 56 56s56-25.1 56-56c0-25.4-16.9-46.8-40-53.7v-66.1c2.7-.1 5.3-.2 8-.2h112c2.7 0 5.3.1 8 .2l-.9 57.8zm-120 62c0 13.3-9.8 24-24 24-12.4 0-24-10.7-24-24s11.6-24 24-24c14.2 0 24 10.7 24 24z" />
          </svg>
        ),
      },
      {
        path: endpoints.LOCAL_CHAMBERS_ENDPOINT,
        name: 'Chambers',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M336 0c26.5 0 48 21.49 48 48v416c0 26.5-21.5 48-48 48h-96v-80c0-26.5-21.5-48-48-48s-48 21.5-48 48v80H48c-26.51 0-48-21.5-48-48V48C0 21.49 21.49 0 48 0h288zM64 272c0 8.8 7.16 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16H80c-8.84 0-16 7.2-16 16v32zm112-48c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32zm80 48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v32zM80 96c-8.84 0-16 7.2-16 16v32c0 8.8 7.16 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16H80zm80 48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v32zm112-48c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32z" />
          </svg>
        ),
      },
    ],
  },
  {
    path: endpoints.LOCAL_REPORTS_ENDPOINT,
    name: 'Reports',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.5 21.49 48 48 48h245.5c16.97 0 33.25-6.744 45.26-18.75l90.51-90.51A63.855 63.855 0 0 0 448 325.5V80c0-26.51-21.5-48-48-48zM64 96h320l-.001 224H320c-17.67 0-32 14.33-32 32v64H64V96z" />
      </svg>
    ),
    routes: [
      {
        path: '/transactions',
        name: 'Transactions',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M160 0c17.7 0 32 14.33 32 32v35.68c1.6.21 3.1.44 4.7.67 10.6 1.58 42.2 6.67 55.2 9.96 16.2 4.34 27.5 21.79 23.1 38.89-4.3 17.1-21.7 27.5-38.9 23.2-9.3-3.3-37.6-7.1-48.8-8.7-32.1-4.8-59.6-2.4-78.5 4.8-18.28 7-25.87 16.9-27.88 28-1.94 10.7-.47 16.8 1.29 20.6 1.89 4 5.58 8.5 12.93 13.4 16.26 10.7 41.06 17.9 73.26 26.6l2.8.8c28.4 7.7 63.2 17.2 89 34.3 14.1 9.4 27.4 22.1 35.6 39.7 8.3 17.8 10.1 37.8 6.3 58.2-7 38.9-34 64.3-65.7 77.5-13.6 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32v-34.9c-.4 0-.9-1-1.3-.2h-.2c-24.3-3.8-64.43-14.3-91.5-26.3-16.15-7.2-23.42-26.1-16.24-42.3 7.18-16.1 26.09-23.4 41.34-16.2 21.8 9.3 56.2 18.4 76.1 21.5 32 4.8 58.3 2 76.1-5.2 16.9-6.9 24.6-16.9 26.8-28.9 1.9-10.7.5-16.8-1.3-20.6-1.9-4-5.6-8.5-12.9-13.4-16.3-10.7-41.1-17.9-73.3-26.6l-2.8-.8c-28.4-7.7-63.22-17.2-89.04-34.3-14.11-9.4-27.33-22.1-35.54-39.7-8.33-17.8-10.14-37.8-6.27-59.1 7.08-38.9 35.1-63.71 68.01-76.27 13.02-4.97 27.14-8.24 42.04-10V32c0-17.67 14.3-32 32-32z" />
          </svg>
        ),
      },
    ],
  },
  {
    path: endpoints.LOCAL_SCHEDULES_ENDPOINT,
    name: 'Schedules',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M256 512C114.6 512 0 397.4 0 256S114.6 0 256 0s256 114.6 256 256-114.6 256-256 256zm-24-256c0 8 4 15.5 10.7 19.1l96 64c11 8.2 25.9 5.2 32.4-5.8 8.2-11 5.2-25.9-5.8-33.3L280 243.2V120c0-13.3-10.7-24-24.9-24-12.4 0-24 10.7-24 24l.9 136z" />
      </svg>
    ),
  },
  {
    path: endpoints.LOCAL_USERS_ENDPOINT,
    name: 'Users',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0 96 57.31 96 128s57.3 128 128 128zm50.7 48H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3c0-95.7-77.6-173.3-173.3-173.3z" />
      </svg>
    ),
  },
  {
    path: endpoints.LOCAL_TEMPLATES_ENDPOINT,
    name: 'Templates',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.5 21.49 48 48 48h245.5c16.97 0 33.25-6.744 45.26-18.75l90.51-90.51A63.855 63.855 0 0 0 448 325.5V80c0-26.51-21.5-48-48-48zM64 96h320l-.001 224H320c-17.67 0-32 14.33-32 32v64H64V96z" />
      </svg>
    ),
  },
];
