import React from 'react';
import ChamberFormContent from '../components/chamberFormContent/ChamberFormContent';
import Layout from '../components/layout/Layout';

const ChamberCreate = () => (
  <Layout>
    <ChamberFormContent />
  </Layout>
);

export default ChamberCreate;
