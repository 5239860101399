import moment from 'moment';
import { DATE_TIME_FORMAT } from './../../configs/constants';
export const mapDataItemFromSTC = (dataItem: any): any => {
  return {
    reference: dataItem.reference,
    amount: dataItem.amount,
    appointmentId: dataItem.appointment_id,
    doctorName: dataItem.doctor.full_name,
    appointmentAt: moment(dataItem.appointment.appointment_at).format(
      DATE_TIME_FORMAT
    ),
    appointmentEndAt: moment(dataItem.appointment.appointment_end_at).format(
      DATE_TIME_FORMAT
    ),
    particulars: dataItem.particulars,
    transactionType: dataItem.transaction_type,
  };
};

export const mapDataFromSTC = (data: any[]): any[] => {
  return data.map(mapDataItemFromSTC);
};
