export enum AppointmentStatus {
  Pending = '0',
  Confirmed = '1',
  Canceled = '2',
  Present = '5',
  Visiting = '6',
  Visited = '3',
  Missed = '4',
}

export enum AppointmentStatusStr {
  Pending = 'Pending',
  Confirmed = 'Confirmed',
  Canceled = 'Canceled',
  Present = 'Present',
  Visiting = 'Visiting',
  Visited = 'Visited',
  Missed = 'Missed',
}

export enum deactivatingStatus {
  Chamber = 'chamber_deactivated',
  Doctor = 'doctor_deactivated',
}

export const deactivateStatusObj: any = {
  0: {
    value: 0,
    label: 'Inactive',
    color: 'red',
  },
  1: {
    value: 1,
    label: 'Active',
    color: 'green',
  },
};

export enum PaymentStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
}
