import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <h1 style={{ fontSize: '80px', fontWeight: '700', lineHeight: '1' }}>
        404
      </h1>
      <Button>
        <Link to="/">Back to home</Link>
      </Button>
    </div>
  );
}

export default NotFound;
