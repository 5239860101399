import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Store } from 'redux';
import { LOCAL_LOGIN_ENDPOINT } from '../../configs/endpoints';
import { getSessionToken, setSessionToken } from '../../store/ducks/session';
import './SessionAuthorize.scss';

/** interface to describe SessionAuthorize props*/
interface SessionAuthorizeProps {
  token: string;
  setSessionTokenActionCreator: typeof setSessionToken;
}

const SessionAuthorize = (props: SessionAuthorizeProps) => {
  const { token, setSessionTokenActionCreator } = props;
  const location = useLocation();
  const navigate = useNavigate();

  /** destroys the session token */
  React.useEffect(() => {
    setSessionTokenActionCreator(
      (queryString.parse(location.search)['token'] as string) || ''
    );

    /** redirect based on app */
    const app = window.sessionStorage.getItem('app') || '';
    const redirectEndpoint = window.sessionStorage.getItem('redirect') || '/';

    let extendedRedirectPart = '';
    if (app !== '') {
      /** redirect to todo app */
      extendedRedirectPart = `?app=${app}&redirect=${redirectEndpoint}`;
    }

    navigate(LOCAL_LOGIN_ENDPOINT + extendedRedirectPart);
  }, []);

  return (
    <div>{token !== '' && <Navigate to={LOCAL_LOGIN_ENDPOINT} replace />}</div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setSessionTokenActionCreator: setSessionToken,
};

/** connect SessionAuthorize to the redux store */
const ConnectedSessionAuthorize = connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionAuthorize);

export default ConnectedSessionAuthorize;
