/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-console */
import { SmileOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import TemplateManager from '../../api/templates/request';
import { templateTypes } from '../../configs/options';
import styles from './TemplateForm.module.scss';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface TemplateFormProps {
  data?: any;
}

const TemplateForm: React.FC<TemplateFormProps> = (
  props: TemplateFormProps
) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);
  const [events, setEvents] = useState<any>([]);
  useEffect(() => {
    fetchEvents();
    if (props.data) {
      setInitialValues({ ...props.data });
    }
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);
  const fetchEvents = async () => {
    try {
      const response = await TemplateManager.fetchEvents();
      setEvents(response);
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = async (values: any) => {
    props.data ? updateTemplate(values) : createNewTemplate(values);
  };

  const updateTemplate = async (request: any) => {
    try {
      setLoading(true);
      await TemplateManager.update({
        ...request,
        id: props.data.id,
      });
      message.success('Template updated successfully');
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  /**
   * Create new appointment
   */

  const createNewTemplate = async (request: any) => {
    try {
      setLoading(true);
      await TemplateManager.create(request);
      message.success('Template created successfully');
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };
  /**
   * Reset Form
   */

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <div className={styles.FormWrapper + ' bg-white form__content'}>
          <h2>{props.data ? 'Update' : 'Create'} Template</h2>
          <Row gutter={60}>
            <Col xxl={16} lg={24} md={24} sm={24} xs={24}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={initialValues || {}}
              >
                <Row gutter={20}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Form.Item
                      name="subject"
                      label="Subject"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Form.Item
                      name="channel"
                      label="Channel"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Select channel">
                        {templateTypes.map((itr: any, index: number) => (
                          <Select.Option key={index} value={itr.id}>
                            {itr.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Form.Item
                      name="event"
                      label="Event"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Select event">
                        {events.map((itr: any, index: number) => (
                          <Select.Option key={index} value={itr}>
                            {itr}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="message"
                      label="Message"
                      rules={[{ required: true }]}
                    >
                      <div>
                        <Space style={{ marginBottom: '10px' }}>
                          <Typography.Text
                            className="ant-form-text"
                            type="secondary"
                          >
                            <strong>Basic format: </strong>
                            Dear ::patient_name::, your appointment with
                            ::doctor_name:: at ::appointment_at::{' '}
                            {`{{message}}`}
                          </Typography.Text>
                        </Space>
                        <Input.TextArea />
                        <Typography.Text
                          className="ant-form-text"
                          type="secondary"
                          style={{ marginTop: '10px' }}
                        >
                          Your message must have keywords: ::patient_name::
                          ::doctor_name:: ::appointment_at::
                        </Typography.Text>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button
                    loading={loading}
                    style={{ marginRight: '10px' }}
                    type="primary"
                    htmlType="submit"
                  >
                    {props.data ? 'Update' : 'Create'}
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default TemplateForm;
