import { getChamberBaseMapper, getDoctorBaseMapper } from '../mapper';

export const dataItemFromSTC = (schedule: any): any => {
  return {
    id: schedule.id,
    doctorId: schedule.doctor.id,
    chamberId: schedule.chamber.id,
    ...getDoctorBaseMapper(schedule.doctor),
    ...getChamberBaseMapper(schedule.chamber),
    workingDays: schedule.working_days,
    workingDate: schedule.working_date,
    startTime: schedule.start_time,
    endTime: schedule.end_time,
    physicalConsultation: schedule.physical_consultation,
    videoConsultation: schedule.video_consultation,
    maxPatient: schedule.max_patient,
    consultation: schedule.physical_consultation ? 'Phyisical' : 'Online',
    sessions: schedule.sessions,
    editLink: `/schedules/${schedule.id}/edit`,
  };
};
export const dataDetailsItemFromSTC = (schedule: any): any => ({
  id: schedule.id,
  doctorId: schedule.doctor.id,
  chamberId: schedule.chamber.id,
  ...getDoctorBaseMapper(schedule.doctor),
  ...getChamberBaseMapper(schedule.chamber),
  advanceFee: schedule.chamber.advance_fee,
  newPatientFee: schedule.chamber.new_patient_fee,
  oldPatientFee: schedule.chamber.old_patient_fee,
  reportFee: schedule.report_fee,
  workingDays: schedule.working_days,
  startTime: schedule.start_time,
  endTime: schedule.end_time,
  physicalConsultation: schedule.physical_consultation,
  videoConsultation: schedule.video_consultation,
  maxPatient: schedule.max_patient,
  sessions: schedule.sessions || [],
  workingDate: schedule.working_date,
  consultation: schedule.physical_consultation ? 'Phyisical' : 'Online',
  editLink: `/schedules/${schedule.id}/edit`,
});

export const dataFromSTC = (data: any[]): any[] => {
  return data.map(dataItemFromSTC);
};

export const dataDetailsFromSTC = (data: any[]): any[] =>
  data.map(dataDetailsItemFromSTC);

const meta = (meta: any) => ({
  currentPage: meta.current_page,
  page: meta.page,
  perPage: meta.per_page,
  total: meta.total,
});

const Mapper = {
  dataFromSTC,
  dataItemFromSTC,
  dataDetailsFromSTC,
  dataDetailsItemFromSTC,
  meta,
};

export default Mapper;
