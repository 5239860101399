export const dataItemFromSTC = (user: any): any => {
  return {
    ...user,
    id: user.id,
    email: user.email,
    mobile: user.mobile,
    name: user.name,
    status: {
      value: user.status,
      label: user.status === 1 ? 'Active' : 'Inactive',
      color: user.status === 1 ? 'green' : 'red',
    },
    username: user.username,
    role: user.roles[0].name,
    role_id: user.roles[0].id,
    photo: user.photo,
    detailsLink: `/users/${user.id}`,
    editLink: `/users/${user.id}/edit`,
  };
};
export const dataDetailsItemFromSTC = (user: any): any => ({
  id: user.id,
  email: user.email,
  mobile: user.mobile,
  name: user.name,
  status: {
    value: user.status,
    label: user.status === 1 ? 'Active' : 'Inactive',
  },
  username: user.username,
  role: user.roles[0].name,
  role_id: user.roles[0].id,
  photo: user.photo,
  detailsLink: `/users/${user.id}`,
  editLink: `/users/${user.id}/edit`,
});

export const dataFromSTC = (data: any[]): any[] => {
  return data.map(dataItemFromSTC);
};

export const dataDetailsFromSTC = (data: any[]): any[] =>
  data.map(dataDetailsItemFromSTC);

const meta = (meta: any) => ({
  currentPage: meta.current_page,
  page: meta.page,
  perPage: meta.per_page,
  total: meta.total,
});

const Mapper = {
  dataFromSTC,
  dataItemFromSTC,
  dataDetailsFromSTC,
  dataDetailsItemFromSTC,
  meta,
};

export default Mapper;
