import { Meta } from '../interfaces/api';

export const mapMeta = (meta: any): Meta => {
  return {
    currentPage: meta.current_page,
    page: meta.page,
    perPage: meta.per_page,
    total: meta.total,
  };
};
