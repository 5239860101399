import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Modal,
  Pagination,
  Row,
  Space,
  Tooltip,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PatientManager from '../../api/patients/request';
import ShortPatientDetails from '../../components/shortPatientDetails/ShortPatientDetails';
import Delete from '../delete/Delete';
import View from '../view/View';

const PatientList = () => {
  const actionRef = useRef<ActionType>();
  const [patients, setPatients] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<any>(null);
  const [detailsModal, setDetailsModal] = useState(false);
  const [patient, setPatient] = useState<any>(null);

  useEffect(() => {
    fetchPatients({ page });
  }, []);

  const columns: ProColumns<any>[] = [
    {
      title: 'Patient',
      dataIndex: 'name',
      sorter: (a, b) => `${a.patient_name}`.localeCompare(b.patient_name),
      render: (text, record, index) => (
        <ShortPatientDetails
          person={{
            name: record.name,
            photo: record.photo,
            age: record.age,
            contactNo: record.contactNo,
          }}
        />
      ),
    },

    {
      title: 'Blood group',
      dataIndex: 'bloodGroup',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
    },
    {
      title: 'Profession',
      dataIndex: 'profession',
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (text, record, index, action) => {
        return (
          <Space>
            <div>
              <Delete
                method={PatientManager.remove}
                id={record.id}
                onDelete={(payload: any) => {
                  if (payload.success === 'patient_deactivated') {
                    setPatients((prevState) =>
                      prevState.filter((itr) => itr.id !== record.id)
                    );
                  }
                }}
              />
            </div>
            <Tooltip title="Edit patient">
              <Link to={record.editLink}>
                <Button
                  style={{
                    background: '#fff',
                    border: 'none',
                    boxShadow: 'none',
                  }}
                  type="primary"
                  shape="circle"
                  icon={
                    <span className="anticon anticon-dollar-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M421.7 220.3 188.5 453.4l-33.9-33.9 3.5-3.5H112c-8.8 0-16-7.2-16-16v-46.1l-3.49 3.5c-4.73 4.8-8.2 10.6-10.09 17l-22.98 78.2 78.16-23c5.5-1.9 12.2-5.4 17-10.1l33.9 33.9c-10.4 10.4-23.3 18.1-37.4 22.2L30.77 511c-8.42 2.5-17.53.2-23.74-6.9-6.21-5.3-8.532-14.4-6.054-22.9L36.37 360.9c4.16-14.1 11.79-27 22.2-37.4L291.7 90.34l130 129.96zm71-161.55c25 24.99 25 65.55 0 90.55l-48.4 48.4-130-129.98 48.4-48.4c25-24.998 65.6-24.998 90.6 0l39.4 39.43z" />
                      </svg>
                    </span>
                  }
                />
              </Link>
            </Tooltip>
            <View
              title="Template view"
              content={
                <div>
                  <Row gutter={10} align="middle">
                    <Col>
                      <Avatar size={50} src={record.photo} />
                    </Col>
                    <Col>
                      <h4 className="fs-16 m-0">{record.name}</h4>
                      <span className="fs-13">{record.contactNo}</span>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={10} align="middle">
                    <Col>
                      <strong>Age:</strong>
                    </Col>
                    <Col>{record.age}</Col>
                  </Row>
                  <Row gutter={10} align="middle">
                    <Col>
                      <strong>Gender:</strong>
                    </Col>
                    <Col>{record.gender}</Col>
                  </Row>
                  <Row gutter={10} align="middle">
                    <Col>
                      <strong>Blood Group:</strong>
                    </Col>
                    <Col>{record.bloodGroup}</Col>
                  </Row>
                  <Row gutter={10} align="middle">
                    <Col>
                      <strong>Profession:</strong>
                    </Col>
                    <Col>{record.profession}</Col>
                  </Row>
                </div>
              }
            />
          </Space>
        );
      },
    },
  ];
  const fetchPatients = async (request: any) => {
    try {
      setLoading(true);
      const response = await PatientManager.all(request);
      setPatients(response.data);
      setMeta(response.meta);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const onPaginateChange = (value: number) => {
    setPage(value);
    fetchPatients({ page: value });
  };

  return (
    <>
      <div className="page__contents">
        <div></div>
        <Button className="btn-create" type="primary">
          <Link to="/patients/create">Create Patient</Link>
        </Button>
      </div>

      <ProTable<any>
        dataSource={patients}
        columns={columns}
        actionRef={actionRef}
        search={false}
        rowKey="id"
        cardBordered
        loading={loading}
        pagination={false}
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
          onChange(value) {
            console.error('value: ', value);
          },
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.perPage || 15}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title={`Patient: ${patient?.id}`}
        visible={detailsModal}
        onCancel={() => setDetailsModal(false)}
        footer={false}
      >
        {patient ? (
          <div>
            <Row gutter={10} align="middle">
              <Col>
                <Avatar size={50} src={patient.photo} />
              </Col>
              <Col>
                <h4 className="fs-16 m-0">{patient.name}</h4>
                <span className="fs-13">{patient.contactNo}</span>
              </Col>
            </Row>
            <Divider />
            <Row gutter={10} align="middle">
              <Col>
                <strong>Age:</strong>
              </Col>
              <Col>{patient.age}</Col>
            </Row>
            <Row gutter={10} align="middle">
              <Col>
                <strong>Gender:</strong>
              </Col>
              <Col>{patient.gender}</Col>
            </Row>
            <Row gutter={10} align="middle">
              <Col>
                <strong>Blood Group:</strong>
              </Col>
              <Col>{patient.bloodGroup}</Col>
            </Row>
            <Row gutter={10} align="middle">
              <Col>
                <strong>Profession:</strong>
              </Col>
              <Col>{patient.profession}</Col>
            </Row>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default PatientList;
