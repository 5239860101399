export enum DoctorType {
  Doctor = '1',
  Phsychologist = '2',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Others = 'others',
}
