import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import React from 'react';

interface Props {
  method: (id: any) => void;
  id: any;
  onDelete?: (payload: any) => void;
  confirmLabel?: string;
  tooltip?: string;
  icon?: any;
}
export default function Toggle(props: Props) {
  return (
    <div>
      <Tooltip title={props.tooltip || 'Toggle'}>
        <Button
          style={{
            background: '#fff',
            border: 'none',
            boxShadow: 'none',
          }}
          type="primary"
          shape="circle"
          onClick={() => {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              content: (
                <span>{props.confirmLabel || 'Are you sure to toggle?'}</span>
              ),
              async onOk() {
                const response: any = await props.method({ id: props.id });
                props.onDelete ? props.onDelete(response.data) : null;
              },
            });
          }}
          icon={
            props.icon ? (
              props.icon
            ) : (
              <CheckCircleOutlined
                style={{ color: '#000', fontSize: '24px' }}
              />
            )
          }
        />
      </Tooltip>
    </div>
  );
}
