import {
  ChamberDetailsInterface,
  ChamberInterface,
} from '../../interfaces/chamber';
import { getDoctorBaseMapper } from '../mapper';

export const dataDetailsItemFromSTC = (chamber: any): any => {
  return {
    id: chamber.id,
    name: chamber.name,
    contactNo: chamber.contact_no,
    email: chamber.email,
    newPatientFee: chamber.new_patient_fee,
    oldPatientFee: chamber.old_patient_fee,
    reportFee: chamber.report_fee,
    advanceFee: chamber.advance_fee,
    maxPatient: chamber.max_patient,
    consultationTypes: chamber.consultation_types,
    onlineAppointment: chamber.online_appointment,
    daysToBeOld: chamber.days_to_be_old,
    address: chamber.address,
    physicalConsultationSchedules: chamber.physical_consultation_schedules,
    videoConsultationSchedules: chamber.video_consultation_schedules,
    status: chamber.status === 0 ? 'Iactive' : 'Active',
    roomNo: chamber.room_no,
    appointmentDuration: chamber.appointment_duration,
    appointmentCancelationHour: chamber.appointment_cancelation_hour,
    reservedAppointment: chamber.reserved_appointment,
    schedules: chamber.schedules,
    doctorName: chamber.doctor.full_name,
    doctorContactNo: chamber.doctor.contact_no,
    doctorPhoto: chamber.doctor.photo,
    doctorSpecialties: chamber.doctor.specialties,
    doctorId: chamber.doctor.id,
    photo: ``,
    allFees: `New: ${chamber.new_patient_fee} / Old: ${chamber.new_patient_fee} / Report: ${chamber.report_fee}`,
  };
};
export const dataItemFromSTC = (chamber: any): any => ({
  id: chamber.id,
  name: chamber.name,
  email: chamber.email,
  contactNo: chamber.contact_no,
  status: chamber.status === 0 ? 'Inactive' : 'Active',
  address: chamber.address,
  schedules: chamber.schedules,
  ...getDoctorBaseMapper(chamber.doctor),
  allFees: `New: ${chamber.new_patient_fee} / Old: ${chamber.new_patient_fee} / Report: ${chamber.report_fee}`,
  newPatientFee: chamber.new_patient_fee,
  oldPatientFee: chamber.old_patient_fee,
  reportFee: chamber.report_fee,
  advanceFee: chamber.advance_fee,
  maxPatient: chamber.max_patient,

  detailsLink: `/chambers/${chamber.id}`,
  editLink: `/chambers/${chamber.id}/edit`,
});

export const dataFromSTC = (chambers: any[]): any[] =>
  chambers.map(dataItemFromSTC);

export const dataDetailsFromSTC = (data: any[]): ChamberInterface[] => {
  // eslint-disable-next-line no-console
  console.log('data-->', data);
  return dataDetailsItemFromSTC(data);
};

// export const dataDetailsItemFromSTC = (data: any): any => ({
//   ...dataItemFromSTC(data),
// });

const meta = (meta: any) => ({
  currentPage: meta.current_page,
  page: meta.page,
  perPage: meta.per_page,
  total: meta.total,
});

const Mapper = {
  dataFromSTC,
  dataItemFromSTC,
  dataDetailsFromSTC,
  dataDetailsItemFromSTC,
  meta,
};

export default Mapper;
