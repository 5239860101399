/* eslint-disable no-console */
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { Pagination, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTransactions } from '../../api/transactions/request';
import { Meta } from '../../interfaces/api';
import { getCurreny } from '../../services/utility';
import TransactionFilter from '../transactionFilter/TransactionFilter';

const TransactionList = () => {
  const actionRef = useRef<ActionType>();
  const [transactions, setTransactions] = useState<Array<any>>([]);
  const [meta, setMeta] = useState<Meta | null>(null);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const location = useLocation();
  const [filterParams, setFilterParams] = useState({
    page: 1,
    per_page: 30,
  });

  useEffect(() => {
    fetchTransactions(meta?.currentPage || 1);
  }, []);

  useEffect(() => {
    const paramsObj = Object.fromEntries(new URLSearchParams(location.search));
    fetchTransactions(paramsObj);
  }, [location]);

  const columns: ProColumns<any>[] = [
    {
      title: 'reference',
      dataIndex: 'reference',
    },
    {
      title: 'Particulars',
      dataIndex: 'particulars',
    },
    {
      title: 'Doctor',
      dataIndex: 'doctorName',
    },
    {
      title: 'Appointment at',
      dataIndex: 'appointmentAt',
    },
    {
      title: 'Appointment end at',
      dataIndex: 'appointmentEndAt',
    },
    {
      title: 'Transaction type',
      dataIndex: 'transactionType',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Appointment Id',
      dataIndex: 'appointmentId',
    },
  ];

  const fetchTransactions = async (requestParam: any) => {
    try {
      setLoading(true);
      const response = await getTransactions(requestParam);
      setTransactions(response.data);
      const total = response.data.reduce(
        (a: any, b: any) => (a += b.amount),
        0
      );
      setTotalAmount(total);
      setMeta(response.meta || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const onPaginateChange = (value: number) => {
    const params = { ...filterParams, page: value };
    setFilterParams(params);
    fetchTransactions(params);
  };

  return (
    <>
      <div className="page__contents">
        <div></div>
      </div>
      <TransactionFilter />
      <div id="table-transaction">
        <ProTable<any>
          dataSource={transactions}
          columns={columns}
          loading={loading}
          actionRef={actionRef}
          search={false}
          rowKey="id"
          cardBordered
          pagination={false}
          options={{
            density: false,
            fullScreen: false,
            reload: false,
            setting: false,
          }}
          columnsState={{
            persistenceKey: 'pro-table-singe-demos',
            persistenceType: 'localStorage',
            onChange(value) {
              console.error('value: ', value);
            },
          }}
        />
        <div style={{ padding: '20px 0' }}>
          <h4>
            Total Amount: {totalAmount} {getCurreny()}
          </h4>
        </div>
      </div>
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.perPage || 15}
          showSizeChanger={false}
        />
      </div>
    </>
  );
};

export default TransactionList;
