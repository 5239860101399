/* eslint-disable no-console */
import {
  ExclamationCircleOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import {
  Button,
  Col,
  message,
  Modal,
  Pagination,
  Row,
  Space,
  Tooltip,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserManager from '../../api/users/request';
import Layout from '../../components/layout/Layout';
import { Meta } from '../../interfaces/api';
import Toggle from '../toggle/Toggle';
import View from '../view/View';

const UserList = () => {
  const actionRef = useRef<ActionType>();
  const [users, setUsers] = useState<Array<any>>([]);
  const [meta, setMeta] = useState<Meta | null>(null);
  const [user, setUser] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [detailsModal, setDetailsModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSchedules(meta?.currentPage ? meta.currentPage + 1 : 1);
  }, []);
  const fetchSchedules = async ({ page }: any) => {
    try {
      setLoading(true);
      const response = await UserManager.all({ page });
      setUsers(response.data);
      setMeta(response.meta || null);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const navigate = useNavigate();

  const onDeactivateUser = async (id: number, index: number) => {
    try {
      setLoading(true);
      const response = await UserManager.deactivate({
        id,
      });
      if (response.data.success === 'user_deactivate') {
        const prevUsers: any = [...users];
        prevUsers[index].status = 'Inactive';
        message.success('User deactived successfully!');
        setUsers(prevUsers);
        setLoading(false);
      } else {
        message.error('User not deactivated!');
        setLoading(false);
      }
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  const columns: ProColumns[] = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render(text, record) {
        return (
          <>
            <span style={{ color: record.status.color }}>
              {record.status.label}
            </span>
          </>
        );
      },
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (text, record, index, action) => {
        return (
          <Space>
            <>
              <Toggle
                method={
                  record.status.value === 0
                    ? UserManager.activate
                    : UserManager.deactivate
                }
                id={record.id}
                icon={
                  record.status.value === 0 ? (
                    <UserAddOutlined
                      style={{ color: '#000', fontSize: '24px' }}
                    />
                  ) : (
                    <UserDeleteOutlined
                      style={{ color: '#000', fontSize: '24px' }}
                    />
                  )
                }
                confirmLabel={
                  record.status.value === 0
                    ? 'Are you sure to activate?'
                    : 'Are you sure to deactivate?'
                }
                onDelete={(payload: any) => {
                  if (payload.success) {
                    const prevUsers: any = [...users];
                    prevUsers[index].status = {
                      value: 0,
                      label:
                        payload.success === 'user_deactivated'
                          ? 'Inactive'
                          : 'Active',
                      color:
                        payload.success === 'user_deactivated'
                          ? 'red'
                          : 'green',
                    };
                    setUsers(prevUsers);
                    message.success('User deactivated successfully!');
                  }
                }}
              />
              <Tooltip title="Edit">
                <Button
                  onClick={() => navigate(record.editLink)}
                  style={{
                    background: '#fff',
                    border: 'none',
                    boxShadow: 'none',
                  }}
                  type="primary"
                  shape="circle"
                  icon={
                    <span className="anticon anticon-dollar-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M421.7 220.3 188.5 453.4l-33.9-33.9 3.5-3.5H112c-8.8 0-16-7.2-16-16v-46.1l-3.49 3.5c-4.73 4.8-8.2 10.6-10.09 17l-22.98 78.2 78.16-23c5.5-1.9 12.2-5.4 17-10.1l33.9 33.9c-10.4 10.4-23.3 18.1-37.4 22.2L30.77 511c-8.42 2.5-17.53.2-23.74-6.9-6.21-5.3-8.532-14.4-6.054-22.9L36.37 360.9c4.16-14.1 11.79-27 22.2-37.4L291.7 90.34l130 129.96zm71-161.55c25 24.99 25 65.55 0 90.55l-48.4 48.4-130-129.98 48.4-48.4c25-24.998 65.6-24.998 90.6 0l39.4 39.43z" />
                      </svg>
                    </span>
                  }
                />
              </Tooltip>
            </>
            <View
              title="User view"
              content={
                <div>
                  <Row>
                    <Col>
                      <strong>Name: </strong> {record.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Mobile: </strong> {record.mobile}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Status: </strong> {record.status.label}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Role: </strong> {record.role}
                    </Col>
                  </Row>
                </div>
              }
            />
          </Space>
        );
      },
    },
  ];

  const onPaginateChange = (value: any) => {
    setPage(value);
    fetchSchedules({ page: value });
  };

  return (
    <>
      <div className="page__contents">
        <div></div>
        <Button type="primary" className="btn-create">
          <Link to="/users/create">Create User</Link>
        </Button>
      </div>
      <ProTable
        dataSource={users}
        columns={columns}
        actionRef={actionRef}
        search={false}
        rowKey="id"
        loading={loading}
        cardBordered
        pagination={false}
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
          onChange(value) {
            console.error('value: ', value);
          },
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.perPage || 15}
          showSizeChanger={false}
        />
      </div>
    </>
  );
};

export default UserList;
