/* eslint-disable no-console */
import { Col, Row, Space } from 'antd';
import React from 'react';
import styles from './ShortCard.module.scss';

export default function ShortCard(props: any) {
  return (
    <div>
      <Row gutter={15} wrap={false}>
        <Col>{props.photo}</Col>
        <Col>
          <h4 style={{ margin: 0 }}>{props.title}</h4>
          <h5>{props.description}</h5>
        </Col>
      </Row>
    </div>
  );
}
