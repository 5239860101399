/* eslint-disable no-console */
import { deactivateStatusObj } from '../../enums/status';

export const dataItemFromSTC = (doctor: any): any => {
  return {
    ...doctor,
    id: doctor.id,
    name: doctor.full_name,
    email: doctor.email,
    bmdcRegNo: doctor.bmdc_reg_no,
    username: doctor.username,
    gender: doctor.gender,
    photo: doctor.photo || doctor.avatar,
    type: doctor.bmdc_reg_no ? '1' : '2',
    area: doctor.area,
    status: deactivateStatusObj[doctor.status || 0],
    specialties: doctor.specialties,
    specialtiesArr: doctor.specialties ? doctor.specialties.split(',') : [],
    detailsLink: `/doctors/${doctor.id}`,
    editLink: `/doctors/${doctor.id}/edit`,
  };
};
export const dataDetailsItemFromSTC = (doctor: any): any => {
  const status =
    doctor.status === null
      ? deactivateStatusObj[0]
      : deactivateStatusObj[doctor.status];
  return {
    ...doctor,
    id: doctor.id,
    name: doctor.full_name,
    email: doctor.email,
    bmdcRegNo: doctor.bmdc_reg_no,
    username: doctor.username,
    gender: doctor.gender,
    photo: doctor.photo || doctor.avatar,
    area: doctor.area,
    type: doctor.bmdc_reg_no ? '1' : '2',
    status: deactivateStatusObj[doctor.status || 0],
    specialties: doctor.specialties,
    specialtiesArr: doctor.specialties ? doctor.specialties.split(',') : [],
    detailsLink: `/doctors/${doctor.id}`,
    editLink: `/doctors/${doctor.id}/edit`,
  };
};

export const dataFromSTC = (doctors: any[]): any[] => {
  return doctors.map(dataItemFromSTC);
};

export const dataDetailsFromSTC = (doctors: any[]): any[] => {
  return doctors.map(dataDetailsItemFromSTC);
};

const meta = (meta: any) => ({
  currentPage: meta.current_page,
  page: meta.page,
  perPage: meta.per_page,
  total: meta.total,
});

const Mapper = {
  dataFromSTC,
  dataItemFromSTC,
  dataDetailsFromSTC,
  dataDetailsItemFromSTC,
  meta,
};

export default Mapper;
