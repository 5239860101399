import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserManager from '../api/users/request';
import Layout from '../components/layout/Layout';
import UserForm from '../components/userForm/UserForm';

const UpdateUserForm = () => {
  const [user, setUser] = useState<any>(null);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getUserById(id);
    }
  }, [id]);
  const getUserById = async (id: any) => {
    try {
      const response = await UserManager.get({ id });
      setUser(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  return <Layout>{user && <UserForm user={user} />}</Layout>;
};

export default UpdateUserForm;
