import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router-dom';
import { Store } from 'redux';
import { LOCAL_LOGIN_ENDPOINT } from '../../configs/endpoints';
import { isAuthenticated } from '../../store/ducks/session';

const PrivateRoute: React.FC = ({
  children,
  isAuthenticated,
  ...rest
}: any) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

/** connect the component to the store */

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>, parentProps: any): any => {
  const result = {
    ...parentProps,
    isAuthenticated: isAuthenticated(state),
  };
  return result;
};

/** connect SignIn to the redux store */
const ConnectedPrivateRoute = connect(mapStateToProps, {})(PrivateRoute);

export default ConnectedPrivateRoute;
