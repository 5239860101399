import endpoints from '../../configs/endpoints';
import { Meta } from '../../interfaces/api';
import { axioService, GET } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import { mapMeta } from '../util';
import { mapDataFromSTC } from './mapper';

interface ResponseInterface {
  data: Array<any> | any | null | any;
  meta?: Meta | null;
}

/**
 * Fetch all transactions
 * @param request - the request object
 * @returns {Promise<ResponseInterface>}
 */

export const getTransactions = async (
  request?: any
): Promise<ResponseInterface> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_TRANSATIONS_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return {
    data: mapDataFromSTC(response.data.data),
    meta: mapMeta(response.data.meta),
  };
};
