//@ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App/App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { ConfigProvider } from 'antd';
import en_US from 'antd/lib/locale/en_US';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={en_US}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
