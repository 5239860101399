import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DoctorManager from '../api/doctors/request';
import DoctorFormContent from '../components/doctorFormContent/DoctorFormContent';
import Layout from '../components/layout/Layout';

const UpdateDoctorForm = () => {
  const [doctor, setDoctor] = useState<any>(null);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getDoctorById(id);
    }
  }, [id]);
  const getDoctorById = async (id: any) => {
    try {
      const response = await DoctorManager.get({ id });
      setDoctor(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    doctor && (
      <Layout>
        <DoctorFormContent doctor={doctor} />
      </Layout>
    )
  );
};

export default UpdateDoctorForm;
