import React from 'react';
import Layout from '../components/layout/Layout';
import TransactionList from '../components/transactionList/TransactionList';

const Transactions = () => (
  <Layout>
    <TransactionList />
  </Layout>
);
export default Transactions;
