/* eslint-disable no-console */
import { Alert, Button, Card, Checkbox, Form, Input, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import constants from '../../configs/constants';
import endpoints from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import { getSessionToken, setSessionToken } from '../../store/ducks/session';
import './Login.scss';

interface LoginProps {
  token: string;
  setSessionTokenActionCreator: typeof setSessionToken;
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const { setSessionTokenActionCreator, token } = props;
  const location = useLocation();
  const passwordRef = React.useRef<any>(null);
  const emailRef = React.useRef<any>(null);
  const navigate = useNavigate();

  // React states
  /** manages loading while processing register request */
  const [loading, setLoading] = React.useState<boolean>(false);
  /** error message to show if occurs */
  const [errorMessage, setErrorMessage] = React.useState<any>('');
  /** status to manage password visibility */
  const [isPasswordVisible, setIsPasswordVisible] =
    React.useState<boolean>(false);

  /** cycles */

  React.useEffect(() => {
    emailRef.current?.focus();
  }, []);

  /** handlers */

  /** toggles the password input visibility */
  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    passwordRef.current.blur();
  };

  /**
   * onFinish is called when form validation passes
   * @param {any} values - containing the key-value user response
   */
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const response = await axioService(
        POST,
        endpoints.SERVER_LOGIN_ENDPOINT,
        {
          grant_type: constants.SERVER_LOGIN_GRANT_TYPE,
          client_id: constants.SERVER_LOGIN_CLIENT_ID,
          client_secret: constants.SERVER_LOGIN_CLIENT_SECRET,
          username: values.username,
          password: values.password,
          scope: constants.SERVER_LOGIN_CLIENT_SCOPE,
          app_name: constants.SERVER_LOGIN_APP_NAME,
        },
        false
      );
      setSessionTokenActionCreator(response.data.access_token || '');
      setLoading(false);
    } catch (exception) {
      /** console error the exception */
      console.error(exception);
      setLoading(false);
      if (
        (exception as any).response &&
        (exception as any).response.data &&
        (exception as any).response.data.message
      ) {
        /** create the error msg from server response */
        const errorMsg = (
          <div>
            {(exception as any).response.data.message} <br />
            <ul>
              {(exception as any).response.data.errors &&
                Object.keys((exception as any).response.data.errors).map(
                  (iterErrorField) => (
                    <li key="iterErrorField">
                      <span className="Login-alert-field">
                        {iterErrorField}
                      </span>{' '}
                      :{' '}
                      {(exception as any).response.data.errors[iterErrorField]}
                    </li>
                  )
                )}
            </ul>
          </div>
        );
        setErrorMessage(errorMsg);
      } else {
        setErrorMessage('There is a network error !!!');
      }
    }
  };

  /**
   * onFinishFailed is called when form validation fails
   * @param {any} errorInfo - containing the respective error information
   */
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  /** onAlertClose is used to close alert message */
  const onAlertClose = () => setErrorMessage('');

  /** google login handler */
  const googleLoginHandler = () => {
    window.location.href = endpoints.SERVER_GOOGLE_AUTH_LINK;
  };

  /** facebook login handler */
  const facebookLoginHandler = () => {
    window.location.href = endpoints.SERVER_FACEBOOK_AUTH_LINK;
  };

  /** apple login handler */
  const appleLoginHandler = () => {
    window.location.href = endpoints.SERVER_APPLE_AUTH_LINK;
  };

  /** any redirection to app is required */
  window.sessionStorage.setItem(
    'app',
    (queryString.parse(location.search)['app'] as string) || ''
  );

  window.sessionStorage.setItem(
    'redirect',
    (queryString.parse(location.search)['redirect'] as string) || '/'
  );

  /** redirection on token */
  if (token !== '') {
    const app = window.sessionStorage.getItem('app');
    const redirectEndpoint = window.sessionStorage.getItem('redirect') || '/';

    if (app !== '') {
      /** redirect to app based on app name */
      window.location.href =
        endpoints.APP_AUTHORIZE_ENDPOINT !== ''
          ? `${endpoints.APP_AUTHORIZE_ENDPOINT}?token=${token}&redirect=${redirectEndpoint}`
          : `https://${app}.virtunus.com/authorize?token=${token}&redirect=${redirectEndpoint}`;
    } else {
      navigate(endpoints.LOCAL_PERSONAL_INFO_ENDPOINT);
    }
  }

  return (
    <Spin spinning={loading}>
      {errorMessage !== '' && (
        <Alert
          closable
          onClose={onAlertClose}
          className="Login-error"
          message="Login Failed"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
      <div className="Login-body">
        <div className="Login-content">
          <div className="Login-logo-container">
            <a href={endpoints.VIRTUNUS_HOME_ENDPOINT}>
              <img src="/logo.webp" width="138" alt="" />
            </a>
          </div>
          <Form
            name="login"
            initialValues={{ remember: true }}
            validateTrigger="onSubmit"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Card className="Login-card">
              <h4 className="Login-card-title">SIGN IN</h4>
              <Form.Item
                className="Login-form-item"
                name="username"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  {
                    type: 'email',
                    message: 'Please input valid email!',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Email"
                  ref={emailRef}
                  prefix={<i className="fas fa-envelope" />}
                />
              </Form.Item>
              <Form.Item
                className="Login-form-item"
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                  {
                    min: 8,
                    message: 'Password needs to be minimum 8 characters!',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  prefix={<i className="fas fa-lock" />}
                  ref={passwordRef}
                  suffix={
                    isPasswordVisible ? (
                      <i
                        onClick={handlePasswordVisibility}
                        className="fas fa-eye-slash"
                      />
                    ) : (
                      <i
                        className="fas fa-eye"
                        onClick={handlePasswordVisibility}
                      />
                    )
                  }
                />
              </Form.Item>
              <Button
                className="Login-sign-in-btn"
                type="primary"
                htmlType="submit"
                size="large"
              >
                SIGN IN
              </Button>
              <div className="Login-password-options-container">
                <Form.Item name="Login-remember" valuePropName="">
                  <Checkbox className="Login-checkbox">Keep me login</Checkbox>
                </Form.Item>
                <Link to={endpoints.LOCAL_FORGOT_PASSWORD_ENDPOINT}>
                  <h4>Forgot Password?</h4>
                </Link>
              </div>
              <div className="Login-divider-container">
                <div className="Login-divider" /> <h4> Or Sign In With </h4>
                <div className="Login-divider" />
              </div>
              <Button
                onClick={googleLoginHandler}
                className="Login-google-btn"
                shape="round"
                size="large"
              >
                Google
              </Button>
              <Button
                onClick={facebookLoginHandler}
                className="Login-facebook-btn"
                shape="round"
                size="large"
              >
                Facebook
              </Button>
              <Button
                onClick={appleLoginHandler}
                className="Login-apple-btn"
                shape="round"
                size="large"
              >
                Apple
              </Button>
            </Card>
          </Form>
          <div className="Login-register-options-container">
            <h4>
              <span className="Login-register-title">
                Don&apos;t have an account?{' '}
              </span>
              <Link to={endpoints.LOCAL_REGISTER_ENDPOINT + location.search}>
                Register Now
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </Spin>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setSessionTokenActionCreator: setSessionToken,
};

/** connect Login to the redux store */
const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(Login);

export default ConnectedLogin;
