import React from 'react';
import DoctorFormContent from '../components/doctorFormContent/DoctorFormContent';
import Layout from '../components/layout/Layout';

const DoctorCreate = () => (
  <Layout>
    <DoctorFormContent />
  </Layout>
);

export default DoctorCreate;
