/* eslint-disable no-console */
import { Button, Form, Input, message, Select } from 'antd';
import React, { useState } from 'react';
import DoctorManager from '../../api/doctors/request';
import { specialties } from '../../configs/options';
import { DoctorType, Gender } from '../../enums/options';
import Layout from '../layout/Layout';
import styles from './DoctorFormContent.module.scss';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface Props {
  doctor?: any;
}

const DoctorFormContent = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = (values: any) => {
    isEdit ? updateNewDoctor(values) : createNewDoctor(values);
  };

  const createNewDoctor = async (request: any) => {
    try {
      setLoading(true);
      await DoctorManager.create(request);
      message.success('Doctor created successfully');
      form.resetFields();
      setLoading(false);
    } catch (error: any) {
      console.log(error.response);
      message.error(error.response.data.message);
      console.error(error);
      setLoading(false);
    }
  };

  const updateNewDoctor = async (request: any) => {
    try {
      setLoading(true);
      const response = await DoctorManager.update({
        ...request,
        id: props.doctor.id,
        specialties: request.specialtiesArr,
      });
      message.success(response.message);
      setLoading(false);
    } catch (error: any) {
      message.error(error.response.data.message);
      console.error(error);
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const isEdit = !!props.doctor;
  return (
    <>
      <div className={styles.FormWrapper + ' bg-white form__content'}>
        <h2>{isEdit ? 'Edit Doctor' : 'Create Doctor'}</h2>
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          initialValues={props.doctor}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="mobile" label="Mobile">
            <Input />
          </Form.Item>
          <Form.Item name="type" label="Doctor Type">
            <Select
              defaultValue={props.doctor?.type}
              placeholder="Select Patient Type"
            >
              <Select.Option value="1">Doctor</Select.Option>
              <Select.Option value="2">Phsychologist</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="gender" label="Gender">
            <Select
              defaultValue={props.doctor?.geder}
              placeholder="Select Gender"
              allowClear
            >
              {Object.entries(Gender).map(([gender, key]) => {
                return (
                  <Option key={key} value={key}>
                    {gender}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="bmdc_reg_no" label="bmdc_reg_no">
            <Input />
          </Form.Item>
          <Form.Item name="specialties" label="specialties">
            <Select mode="tags" placeholder="Select specialties">
              {specialties.map((specialty, key) => {
                return (
                  <Option key={key} value={specialty}>
                    {specialty}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {!props.doctor ? (
            <>
              <Form.Item name="password" label="Password">
                <Input type="password" />
              </Form.Item>
              <Form.Item name="confirm_password" label="Confimr Password">
                <Input type="password" />
              </Form.Item>
            </>
          ) : null}

          <Form.Item {...tailLayout}>
            <Button loading={loading} type="primary" htmlType="submit">
              {isEdit ? 'Update' : 'Create'}
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default DoctorFormContent;
