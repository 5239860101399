import axios from 'axios';
import { LOCAL_LOGOUT_ENDPOINT } from '../configs/endpoints';
import store from '../store';
import { getSessionToken } from '../store/ducks/session';

/** Interface to describe a flexible object */
interface FlexObj {
  [key: string]: any;
}

// Request types
/** GET REQUEST TYPE */
export const GET = 'GET';
type GET = typeof GET;

/** POST REQUEST TYPE */
export const POST = 'POST';
type POST = typeof POST;

/**
 * axioGet makes a get request using the axio libary
 * @param {string} url - the url where the get request will be made
 * @param {FlexObj | FlexObj[]} params - the parameters that will be passed
 * @param {boolean} isProtected - if true, access token will be included in header; otherwise, not.
 * @returns {any} - response returned by the server if successful
 */
export const axioGet = async (
  url: string,
  params: FlexObj | FlexObj[] = {},
  isProtected = false
) => {
  /** retrieve the access token from redux store */
  const token = getSessionToken((store as any).getState());

  const config: any = isProtected
    ? {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    : { headers: { Accept: `application/json` } };
  const response: any = await axios.get(url, { ...config, params });
  return await response;
};

/**
 * axioPost makes a post request using the axio libary
 * @param {string} url - the url where the post request will be made
 * @param {FlexObj | FlexObj[]} params - the parameters that will be passed
 * @param {boolean} isProtected - if true, access token will be included in header; otherwise, not.
 * @returns {any} - response returned by the server if successful
 */
export const axioPost = async (
  url: string,
  params: FlexObj | FlexObj[] = {},
  isProtected = false
) => {
  /** retrieve the access token from redux store */
  const token = getSessionToken((store as any).getState());

  const config: any = isProtected
    ? {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    : { headers: { Accept: `application/json` } };
  const response = await axios.post(url, params, config);
  return await response;
};

/**
 * axioService makes a server request using the axio libary
 * @param {GET|POST} type - the type of request
 * @param {string} url - the url where the request will be made
 * @param {FlexObj | FlexObj[]} params - the parameters that will be passed
 * @param {boolean} isProtected - if true, access token will be included in header; otherwise, not.
 * @returns {any} - response returned by the server if successful; otherwise, error object
 */
export const axioService = async (
  type: GET | POST,
  url: string,
  params: FlexObj | FlexObj[] = {},
  isProtected = false
) => {
  let response;
  try {
    if (type == POST) {
      response = await axioPost(url, params, isProtected);
    } else {
      response = await axioGet(url, params, isProtected);
    }
    return await response;
  } catch (e: any) {
    if (e.response.status === 401) {
      /** resets the session if there is a token */
      const token = getSessionToken((store as any).getState());
      if (token !== '') {
        window.location.href = LOCAL_LOGOUT_ENDPOINT;
        throw e;
      } else {
        throw e;
      }
    } else {
      throw e;
    }
  }
};
