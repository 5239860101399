import {
  ExclamationCircleOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import {
  Avatar,
  Button,
  Col,
  message,
  Modal,
  Pagination,
  Row,
  Space,
  Tooltip,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DoctorManager from '../../api/doctors/request';
import { deactivatingStatus } from '../../enums/status';
import { Meta } from '../../interfaces/api';
import { DoctorInterface } from '../../interfaces/doctor';
import Delete from '../delete/Delete';
import Toggle from '../toggle/Toggle';

const DoctorsList = () => {
  const actionRef = useRef<ActionType>();
  const [doctors, setDoctors] = useState<Array<DoctorInterface>>([]);
  const [meta, setMeta] = useState<Meta | null>(null);
  const [doctor, setDoctor] = useState<any>(null);
  const [detailsModal, setDetailsModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    fetchDoctors(meta?.currentPage || 1);
  }, []);

  const columns: ProColumns<DoctorInterface>[] = [
    {
      title: 'bmdc reg no',
      dataIndex: 'bmdcRegNo',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record, _, action) => {
        return (
          <div>
            <Row wrap={false} gutter={10} align="middle">
              <Col>
                <Avatar size={50} src={record.photo} />
              </Col>
              <Col>
                <h4 className="fs-16 m-0">{text}</h4>
                <span className="fs-13 clr-gray">{record.email}</span>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      render(text, record) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Specialties',
      dataIndex: 'specialties',
      render(text, render) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Area',
      dataIndex: 'area',
    },
    {
      title: 'Status',
      render(text, record: any) {
        return (
          <div style={{ color: record.status.color }}>
            {record.status.label}
          </div>
        );
      },
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (text, record, index) => {
        return (
          <div>
            <Space>
              <>
                <Toggle
                  method={
                    record.status.value === 0
                      ? DoctorManager.activate
                      : DoctorManager.deactivate
                  }
                  id={record.id}
                  icon={
                    record.status.value === 0 ? (
                      <UserAddOutlined
                        style={{ color: '#000', fontSize: '24px' }}
                      />
                    ) : (
                      <UserDeleteOutlined
                        style={{ color: '#000', fontSize: '24px' }}
                      />
                    )
                  }
                  confirmLabel={
                    record.status.value === 0
                      ? 'Are you sure to activate?'
                      : 'Are you sure to deactivate?'
                  }
                  onDelete={(payload: any) => {
                    if (payload.success) {
                      const prevDoctors: any = [...doctors];
                      prevDoctors[index].status = {
                        value: 0,
                        label:
                          payload.success === 'doctor_deactivated'
                            ? 'Inactive'
                            : 'Active',
                        color:
                          payload.success === 'doctor_deactivated'
                            ? 'red'
                            : 'green',
                      };
                      setDoctors(prevDoctors);
                      message.success('Doctor deactivated successfully!');
                    }
                  }}
                />
                <Tooltip title="Edit">
                  <Button
                    onClick={() => navigate(record.editLink)}
                    style={{
                      background: '#fff',
                      border: 'none',
                      boxShadow: 'none',
                    }}
                    type="primary"
                    shape="circle"
                    icon={
                      <span className="anticon anticon-dollar-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M421.7 220.3 188.5 453.4l-33.9-33.9 3.5-3.5H112c-8.8 0-16-7.2-16-16v-46.1l-3.49 3.5c-4.73 4.8-8.2 10.6-10.09 17l-22.98 78.2 78.16-23c5.5-1.9 12.2-5.4 17-10.1l33.9 33.9c-10.4 10.4-23.3 18.1-37.4 22.2L30.77 511c-8.42 2.5-17.53.2-23.74-6.9-6.21-5.3-8.532-14.4-6.054-22.9L36.37 360.9c4.16-14.1 11.79-27 22.2-37.4L291.7 90.34l130 129.96zm71-161.55c25 24.99 25 65.55 0 90.55l-48.4 48.4-130-129.98 48.4-48.4c25-24.998 65.6-24.998 90.6 0l39.4 39.43z" />
                        </svg>
                      </span>
                    }
                  />
                </Tooltip>
              </>
              <Tooltip title="View doctor">
                <Button
                  onClick={() => {
                    setDoctor({ ...record, doctorIndex: index });
                    setDetailsModal(true);
                  }}
                  style={{
                    background: '#fff',
                    border: 'none',
                    boxShadow: 'none',
                  }}
                  type="primary"
                  shape="circle"
                  icon={
                    <span className="anticon anticon-dollar-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path d="M279.6 160.4c2.8-.3 5.6-.4 8.4-.4 53 0 96 42.1 96 96 0 53-43 96-96 96-53.9 0-96-43-96-96 0-2.8.1-5.6.4-8.4 9.3 4.5 20.1 8.4 31.6 8.4 35.3 0 64-28.7 64-64 0-11.5-3.9-22.3-8.4-31.6zm201-47.8c46.8 43.4 78.1 94.5 92.9 131.1 3.3 7.9 3.3 16.7 0 24.6-14.8 35.7-46.1 86.8-92.9 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.58-80.6C48.62 355.1 17.34 304 2.461 268.3a31.967 31.967 0 0 1 0-24.6C17.34 207.1 48.62 156 95.42 112.6 142.5 68.84 207.2 32 288 32c80.8 0 145.5 36.84 192.6 80.6zM288 112c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144-64.5-144-144-144z" />
                      </svg>
                    </span>
                  }
                />
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
  ];

  const fetchDoctors = async (page: number) => {
    try {
      setLoading(true);
      const response = await DoctorManager.all({ page });
      setDoctors(response.data);
      setMeta(response.meta || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const onPaginateChange = (page: number) => {
    const params: any = { page: page };
    fetchDoctors(params);
  };

  return (
    <>
      <div className="page__contents">
        <div></div>
        <Button type="primary" className="btn-create">
          <Link to="/doctors/create">Create Doctors</Link>
        </Button>
      </div>
      <ProTable<any>
        dataSource={doctors}
        columns={columns}
        loading={loading}
        actionRef={actionRef}
        search={false}
        rowKey="id"
        cardBordered
        pagination={false}
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
          onChange(value) {
            console.error('value: ', value);
          },
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.perPage || 15}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title={`Doctor: ${doctor?.bmdcRegNo}`}
        visible={detailsModal}
        onCancel={() => setDetailsModal(false)}
        footer={false}
      >
        {doctor ? (
          <div>
            <Row gutter={10} align="middle">
              <Col>
                <Avatar size={50} src={doctor.photo} />
              </Col>
              <Col>
                <h4 className="fs-16 m-0">{doctor.name}</h4>
                <span className="fs-13">{doctor.email}</span>
              </Col>
            </Row>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default DoctorsList;
