import { Button, Col, Divider, InputNumber, message, Row, Space } from 'antd';
import React, { useState } from 'react';
import { AppointmentWithIndex } from '../../../interfaces/appointment';
import AppointmentManager from '../request';

interface Props {
  appointment: AppointmentWithIndex;
  onPaymentSuccess: (appointment: AppointmentWithIndex) => void;
}
export default function PaymentDetails({
  appointment,
  onPaymentSuccess,
}: Props) {
  const [total, setTotal] = useState(appointment.totalAmount);
  const [loading, setLoading] = useState(false);
  const [paid, setPaid] = useState(false);
  const payAppointment = async () => {
    try {
      setLoading(true);
      const response: any = await AppointmentManager.pay({
        appointment_id: appointment.id,
        amount: total,
      });
      if (response.data.data.success === 'payment_confirmed') {
        message.success('Payment successfull!');
        onPaymentSuccess({
          ...appointment,
          paymentStatus: 'success',
        });
        setPaid(true);
        setLoading(false);
      }
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };
  return (
    <div>
      <h3>PAYMENT</h3>
      <Row justify="space-between">
        <Col>
          <span style={{ color: appointment.paymentStatusColor }}>
            {appointment.paymentStatus}
          </span>
        </Col>
      </Row>
      <Divider />
      <Row className="brdr-btm-1 py-10" justify="space-between">
        <Col>Visiting Fee</Col>
        <Col>{appointment.visitingFee} BDT</Col>
      </Row>
      <Row className="brdr-btm-1 py-10" justify="space-between">
        <Col>Confirmation amount</Col>
        <Col>{appointment.confirmationAmount} BDT</Col>
      </Row>
      <Row className="brdr-btm-1 py-10" justify="space-between">
        <Col>Service Charge</Col>
        <Col>{appointment.serviceCharge} BDT</Col>
      </Row>
      <Row className="brdr-btm-1 py-10" justify="space-between">
        <Col>Net Payable</Col>
        <Col>{appointment.netPayable} BDT</Col>
      </Row>
      <Row className="brdr-btm-1 py-10" justify="space-between">
        <Col>Due amount</Col>
        <Col>{appointment.dueAmount} BDT</Col>
      </Row>
      <Row className="brdr-btm-1 py-10" justify="space-between">
        <Col>Paid amount</Col>
        <Col>{appointment.paidAmount} BDT</Col>
      </Row>
      {appointment.dueAmount ? (
        <>
          {' '}
          <Row className="brdr-btm-1 py-10" justify="space-between">
            <Col>Total</Col>
            <Col>
              <Space>
                <InputNumber
                  onChange={(number: number) => setTotal(number)}
                  defaultValue={appointment.totalAmount}
                  max={appointment.dueAmount}
                />
                BDT
              </Space>
            </Col>
          </Row>
          <Row className="py-10" justify="space-between">
            <Col></Col>
            <Col>
              <Button
                disabled={paid}
                loading={loading}
                onClick={payAppointment}
                type="primary"
              >
                Pay now
              </Button>
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
}
