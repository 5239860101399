/* eslint-disable no-console */
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
  Switch,
  TimePicker,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ChamberManager from '../../api/chambers/request';
import DoctorManager from '../../api/doctors/request';
import ScheduleManager from '../../api/schedules/request';
import { DoctorInterface } from '../../interfaces/doctor';
import styles from './ScheduleFormContent.module.scss';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface ScheduleFormContentProps {
  schedule?: any;
  pageLabel?: string;
}

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const ScheduleFormContent: React.FC<ScheduleFormContentProps> = (
  props: ScheduleFormContentProps
) => {
  const [form] = Form.useForm();
  const [doctors, setDoctors] = useState<Array<DoctorInterface>>([]);
  const [chambers, setChambers] = useState<any>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<DoctorInterface | null>(
    null
  );
  const [initialValues, setInitialValues] = useState<any>(null);
  const [sessions, setSessions] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const isEdit = props.schedule || false;
  const timeFormat = 'HH:mm';
  let timeout: any = null;

  useEffect(() => {
    if (props.schedule) {
      form.setFieldsValue({
        doctor_id: props.schedule.doctorName,
        chamber_id: props.schedule.chamberName,
        working_days: props.schedule.workingDays,
        start_time: moment(props.schedule.startTime, 'hh:mm a'),
        end_time: moment(props.schedule.endTime, 'hh:mm a'),
        working_date: props.schedule.workingDate
          ? moment(props.schedule.workingDate)
          : null,
        max_patient: props.schedule.maxPatient,
        physical_consultation: props.schedule.physicalConsultation,
        video_consultation: props.schedule.videoConsultation,
      });
      setSessions(props.schedule.sessions || []);
    }
  }, [props.schedule]);

  useEffect(() => {
    if (!props.schedule) {
      fetchDoctors();
    }
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);
  const navigate = useNavigate();

  const fetchDoctors = async () => {
    try {
      const response = await DoctorManager.all();
      setDoctors(response.data);
    } catch (error: any) {
      message.error(error.response.data.message);
      console.error(error);
    }
  };

  const fetchChambers = async (params: any) => {
    try {
      const response = await ChamberManager.all(params);
      setChambers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeDoctor = (value: any) => {
    const doctor: any = doctors.find(
      (doctor: DoctorInterface) => doctor.id === value
    );
    if (doctor) {
      fetchChambers({ doctor_id: doctor.id });
      setSelectedDoctor(doctor);
    }
  };

  const formatRequest = (request: any) => ({
    ...request,
    start_time: request.start_time
      ? moment(request.start_time).format(timeFormat)
      : '',
    end_time: request.end_time
      ? moment(request.end_time).format(timeFormat)
      : '',
    sessions: sessions,
    physical_consultation: request.physical_consultation ? true : false,
    video_consultation: request.video_consultation ? true : false,
  });

  const onFinish = (request: any) => {
    props.schedule
      ? editSchedule(formatRequest(request))
      : createNewSchedule(formatRequest(request));
  };

  const createNewSchedule = async (request: any) => {
    try {
      await ScheduleManager.create({
        ...request,
      });
      message.success('Schedule created successfully');
      navigate('/schedules');
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data);
    }
  };

  const editSchedule = async (request: any) => {
    try {
      const requestObj = {
        ...request,
        id: props.schedule.id,
        doctor_id: props.schedule.doctorId,
        chamber_id: props.schedule.chamberId,
      };
      const response = await ScheduleManager.update({
        ...requestObj,
      });
      message.success('Schedule updated successfully');
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const onDoctorSearch = async (value: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(async () => {
      const response = await DoctorManager.all({
        q: value,
      });
      if (response.data && response.data.length > 0) {
        setDoctors(response.data);
      }
    }, 500);
  };
  const addSession = () => {
    setSessions([
      ...sessions,
      {
        id: uuidv4(),
        duration: 0,
        quota: 0,
        fee: 0,
      },
    ]);
  };
  const onSessionValueChange = (ev: any) => {
    setSessions((prevSessions: any) => {
      return prevSessions.map((prevSession: any, index: number) => {
        if (prevSession.id === ev.id) {
          const item: any = { ...prevSession };
          item[ev.itemKey] = ev.value;
          return item;
        }
        return prevSession;
      });
    });
  };

  const reemoveSession = (ev: any) => {
    const updatedSessions = sessions.filter(
      (sessionArrItr: any, index: number) => {
        if (sessionArrItr.id !== ev.id) {
          return sessionArrItr;
        }
      }
    );
    setSessions(updatedSessions);
  };

  return loading ? (
    <Spin />
  ) : (
    <div className={styles.FormWrapper + ' bg-white form__content'}>
      <h2>{props.pageLabel || 'Create Schedule'}</h2>
      <Row>
        <Col span={18}>
          <Form
            initialValues={initialValues || null}
            {...layout}
            form={form}
            name="schedule-form"
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Row gutter={20}>
              <Col lg={12} md={8} sm={12} xs={24}>
                <Form.Item
                  name="doctor_id"
                  label="Doctor"
                  rules={[{ required: true }]}
                >
                  {props.schedule ? (
                    <Input disabled={true} />
                  ) : (
                    <Select
                      showSearch
                      onChange={(value) => onChangeDoctor(value)}
                      placeholder="Select a option and change input text above"
                      allowClear
                      onSearch={onDoctorSearch}
                      showArrow={false}
                      filterOption={false}
                      notFoundContent={null}
                    >
                      {doctors.map((doctor: any, index: number) => (
                        <Option key={index} value={doctor.id}>
                          {doctor.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} md={8} sm={12} xs={24}>
                <Form.Item
                  name="chamber_id"
                  label="Chamber"
                  rules={[{ required: true }]}
                >
                  {props.schedule ? (
                    <Input disabled={true} />
                  ) : (
                    <Select placeholder="Select chamber">
                      {chambers.map((chamber: any, index: number) => {
                        return (
                          <Select.Option key={index} value={chamber.id}>
                            {chamber.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="working_days"
                  label="Working Days"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select"
                    style={{ width: '100%' }}
                  >
                    {weekDays.map((day: string, index: number) => {
                      return (
                        <Select.Option value={day} key={index}>
                          {day}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="working_date" label="Working date">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="start_time" label="Start time">
                  <TimePicker format={timeFormat} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="end_time" label="End time">
                  <TimePicker format={timeFormat} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="physical_consultation"
                  label="Physical Consultation"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="video_consultation"
                  label="Video Consultation"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="max_patient" label="Max Patient">
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
            <div>
              {sessions?.length ? (
                <div className="session__list">
                  {sessions.map((session: any, index: number) => {
                    return (
                      <div className="session__list__item" key={session.id}>
                        <h5 className="session__list__item__title">
                          session #{++index}
                        </h5>
                        <Button
                          onClick={(ev: any) =>
                            reemoveSession({ ...ev, id: session.id })
                          }
                          danger
                          type="primary"
                          className="session__list__item__remover"
                          icon={<CloseCircleOutlined />}
                        ></Button>
                        <Row gutter={20}>
                          <Col span={8}>
                            <span className="session__list__item__label">
                              Duration:
                            </span>
                            <InputNumber
                              value={session.duration}
                              min={0}
                              onChange={(ev: number) =>
                                onSessionValueChange({
                                  value: ev,
                                  id: session.id,
                                  itemKey: 'duration',
                                })
                              }
                            />
                          </Col>
                          <Col span={8}>
                            <span className="session__list__item__label">
                              quota:
                            </span>
                            <InputNumber
                              min={0}
                              value={session.quota}
                              onChange={(value: number) =>
                                onSessionValueChange({
                                  value,
                                  id: session.id,
                                  itemKey: 'quota',
                                })
                              }
                            />
                          </Col>
                          <Col span={8}>
                            <span className="session__list__item__label">
                              Fee:
                            </span>
                            <InputNumber
                              min={0}
                              value={session.fee}
                              onChange={(value: number) =>
                                onSessionValueChange({
                                  value,
                                  id: session.id,
                                  itemKey: 'fee',
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              <Button
                className="session__list__add"
                onClick={() => addSession()}
                danger
              >
                Add Session
              </Button>
            </div>
            <Form.Item>
              <Button
                style={{ marginRight: '10px' }}
                type="primary"
                htmlType="submit"
              >
                {isEdit ? 'Update' : 'Add new schedule'}
              </Button>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ScheduleFormContent;
