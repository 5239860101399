import endpoints from '../../configs/endpoints';
import { Meta } from '../../interfaces/api';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import { mapMeta } from '../util';
import Mapper from './mapper';

interface ResponseInterface {
  data: Array<any> | any | null | any;
  meta?: Meta | null;
}

/**
 * Fetch all users for the current provider with pagination
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const all = async (request?: any): Promise<ResponseInterface> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_USERS_ENDPOINT.replace(':providerId', getProviderId()),
    request,
    true
  );
  return {
    meta: mapMeta(response.data.meta),
    data: Mapper.dataFromSTC(response.data.data),
  };
};

/**
 * Fetch a user by id
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const get = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_USER_SHOW_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':userId', request.id),
    request,
    true
  );
  return {
    data: Mapper.dataDetailsItemFromSTC(response.data.data),
  };
};

/**
 * create a new user
 * @param request the request body
 * @returns {Promise<UserDetailsInterface>}
 */
export const create = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_USERS_STORE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return {
    data: response.data.data,
  };
};

/**
 * create a new doctor
 * @param request the request body
 * @returns {Promise<UserDetailsInterface>} the updated doctor
 */
export const update = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_USER_UPDATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':userId', request.id),
    request,
    true
  );
  return {
    data: response.data,
  };
};

const getRoles = async (request?: any): Promise<ResponseInterface> => {
  // const response = await axioService(
  //   GET,
  //   endpoints.SERVER_USERS_ENDPOINT.replace(':providerId', getProviderId()),
  //   request,
  //   true
  // );
  return {
    meta: null,
    data: [
      {
        label: 'Admin',
        value: 1,
      },
      {
        label: 'Doctor',
        value: 2,
      },
      {
        label: 'Patient',
        value: 3,
      },
      {
        label: 'Operator',
        value: 4,
      },
    ],
  };
};

export const deactivate = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_USER_DEATIVATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':userId', request.id),
    request,
    true
  );
  return response;
};

export const activate = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_USER_ATIVATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':userId', request.id),
    request,
    true
  );
  return response;
};

const UserManager = {
  all,
  create,
  get,
  update,
  getRoles,
  deactivate,
  activate,
};

export default UserManager;
