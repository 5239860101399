import { ExclamationCircleOutlined } from '@ant-design/icons';
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Dropdown,
  Menu,
  message,
  Modal,
  Pagination,
  Row,
  Space,
  Tooltip,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import PaymentDetails from '../../api/appointments/paymentDetails/PaymentDetails';
import AppointmentManager from '../../api/appointments/request';
import AppointmentFilter from '../../components/appointmentFilter/AppointmentFilter';
import ShortCard from '../../components/shortCard/ShortCard';
import ShortPatientDetails from '../../components/shortPatientDetails/ShortPatientDetails';
import { DATE_FORMAT } from '../../configs/constants';
import { AppointmentStatus, AppointmentStatusStr } from '../../enums/status';
import { Meta } from '../../interfaces/api';
import {
  Appointment,
  AppointmentWithIndex,
} from '../../interfaces/appointment';
import AppointmentDetails from '../appointmentDetails/AppointmentDetails';
import {
  FetchAppointmentsRequest,
  RequestStatusChange,
} from './AppointmentList.d';

const AppointmentList = () => {
  const actionRef = useRef<ActionType>();
  const [appointments, setAppointments] = useState<Array<Appointment>>([]);
  const [meta, setMeta] = useState<Meta | null>(null);
  const [loading, setLoading] = useState(true);
  const [detailsDrawer, setDetailsDrawer] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const location = useLocation();
  const params = useParams();
  const [payableAppointment, setPayableAppointment] =
    useState<AppointmentWithIndex | null>(null);
  const [appointmentDetails, setAppointmentDetails] =
    useState<AppointmentWithIndex | null>(null);

  const [filterParams, setFilterParams] = useState({
    start_date: moment().format(DATE_FORMAT),
    page: 1,
    per_page: 30,
  });

  useEffect(() => {
    fetchAppointments({ ...filterParams, ...params });
  }, []);

  useEffect(() => {
    const paramsObj = Object.fromEntries(new URLSearchParams(location.search));
    fetchAppointments(paramsObj);
  }, [location]);

  const fetchAppointments = async (requestParam: FetchAppointmentsRequest) => {
    setLoading(true);
    try {
      const response = await AppointmentManager.all(requestParam);
      setAppointments(response.data);
      setMeta(response.meta);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onCloseDetailsDrawer = () => {
    setDetailsDrawer(false);
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const openPaymentModal = (payload: Appointment, index: number) => {
    if (payableAppointment) {
      setPayableAppointment(null);
    }
    setPayableAppointment({ ...payload, appointmentIndex: index });
    setPaymentModal(true);
  };

  const appointmentStatusMenus = (record: Appointment, index: number) => {
    return (
      Object.keys(AppointmentStatus) as (keyof typeof AppointmentStatus)[]
    )
      .filter((itr) => {
        if (
          (record.appointmentStatus === AppointmentStatusStr.Confirmed &&
            itr === AppointmentStatusStr.Confirmed) ||
          itr === AppointmentStatusStr.Canceled
        ) {
          return false;
        }
        return itr;
      })
      .map((status, index: number) => ({
        label: (
          <Button
            onClick={() =>
              onStatusChange({
                appointmentId: record.id,
                status: AppointmentStatus[status],
                index: index,
              })
            }
            type="link"
          >
            {status}
          </Button>
        ),
        key: index,
      }));
  };

  const onStatusChange = async (requestParam: RequestStatusChange) => {
    setLoading(true);
    try {
      const response = await AppointmentManager.update({
        id: requestParam.appointmentId,
        status: requestParam.status,
      });
      if (response && response.data) {
        message.success(requestParam.message || 'Status updated successfully');
        setAppointments((prevAppointments) => {
          const appointments = prevAppointments.map((item: any): any => {
            if (item.id == requestParam.appointmentId) {
              return {
                ...item,
                appointmentStatus: response.data.data.data.appointment_status,
              };
            }
            return item;
          });
          return appointments;
        });
      }
      setLoading(false);
    } catch (error: any) {
      console.error(error.response.data);
      message.error(error.response?.data?.message);
      setLoading(false);
    }
  };
  const columns: ProColumns<any>[] = [
    {
      title: 'Serial',
      render: (text, record, index) => `#${index + 1}`,
    },
    {
      title: 'Patient',
      dataIndex: 'patient_name',
      sorter: (a, b) => `${a.patient_name}`.localeCompare(b.patient_name),
      render: (text, record, index) => (
        <ShortPatientDetails
          person={{
            name: record.patientName,
            photo: record.patientPhoto,
            age: record.patientAge,
            contactNo: record.patientContactNo,
          }}
        />
      ),
    },
    {
      title: 'Consultation',
      dataIndex: 'doctor_name',
      render: (text, record, index) => (
        <ShortCard
          photo={<Avatar src={record.doctorPhoto} size={50} />}
          title={<span>{record.doctorName} </span>}
          description={
            <span>
              <span
                style={{
                  color: '#959595',
                  fontWeight: '500',
                  fontSize: '11px',
                }}
              >
                {record.chamberName}
              </span>
              <br />
              <span style={{ color: '#000', fontSize: '11px' }}>
                <span style={{ color: '#000', fontWeight: '300' }}>
                  type :{' '}
                </span>{' '}
                <span style={{ fontWeight: '500' }}>
                  {' '}
                  {record.consultationType}
                </span>
              </span>
            </span>
          }
        />
      ),
    },
    {
      title: 'Appointment',
      dataIndex: 'appointment_at',
      sorter: (a, b) => `${a.appointment_at}`.localeCompare(b.appointment_at),
      render: (text, record, index) => (
        <div>
          <span style={{ color: '#000', fontWeight: '600', fontSize: '13px' }}>
            {record.appointmentTime}-{record.appointmentEndTime}
          </span>
          <br />
          <span style={{ color: '#000', fontWeight: '400', fontSize: '13px' }}>
            {record.appointmentDate}
          </span>
          <br />
          <span
            style={{ color: '#959595', fontWeight: '400', fontSize: '13px' }}
          >
            Payment :
            <span
              style={{
                color: record.paymentStatusColor,
                fontWeight: '400',
                fontSize: '13px',
              }}
            >
              {' '}
              {record.paymentStatus}
            </span>
          </span>
        </div>
      ),
    },
    {
      title: 'Status',
      hideInSetting: true,
      dataIndex: 'appointment_status',
      render: (text, record, index) => (
        <span
          className="appointment-status-toggler"
          style={{ color: record.appointmentStatusColor }}
        >
          <Row align="middle">
            <Col span={20}>
              <span style={{ marginRight: '20px' }}>
                {record.appointmentStatus}
              </span>
            </Col>
            <Col span={4}>
              {record.appointmentStatus !== 'Canceled' ? (
                <Dropdown
                  overlay={
                    <Menu items={appointmentStatusMenus(record, index)} />
                  }
                  trigger={['click']}
                >
                  <Button
                    size="small"
                    style={{
                      background: '#fff',
                      boxShadow: '0px 0px 10px 10px rgba(0,0,0,.03)',
                      fontSize: '12px',
                      color: '#959595',
                      border: 'none',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    type="primary"
                    shape="circle"
                  >
                    <Space
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <svg
                        style={{ width: '10px', marginTop: '7px' }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                      </svg>
                    </Space>
                  </Button>
                </Dropdown>
              ) : null}
            </Col>
          </Row>
        </span>
      ),
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (text, record, index, action) => {
        return (
          <Space>
            {record.appointmentStatus !== 'Canceled' ? (
              <>
                <Tooltip title="Cancel appointment">
                  <Button
                    style={{
                      background: '#fff',
                      border: 'none',
                      boxShadow: 'none',
                    }}
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      Modal.confirm({
                        icon: <ExclamationCircleOutlined />,
                        content: (
                          <span>Are you sure to cancel this appointment?</span>
                        ),
                        onOk() {
                          onStatusChange({
                            appointmentId: record.id,
                            status: AppointmentStatus.Canceled,
                            message: 'Appointment successfully canceled',
                            index: index,
                          });
                        },
                      });
                    }}
                    icon={
                      <span className="anticon anticon-dollar-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M0 256C0 114.6 114.6 0 256 0s256 114.6 256 256-114.6 256-256 256S0 397.4 0 256zm175-47.9 47.1 47L175 303c-9.3 9.4-9.3 24.6 0 33.1 9.4 10.2 24.6 10.2 33.1 0l47-46.2 47.9 46.2c9.4 10.2 24.6 10.2 33.1 0 10.2-8.5 10.2-23.7 0-33.1l-46.2-47.9 46.2-47c10.2-8.5 10.2-23.7 0-33.1-8.5-9.3-23.7-9.3-33.1 0l-47.9 47.1-47-47.1c-8.5-9.3-23.7-9.3-33.1 0-9.3 9.4-9.3 24.6 0 33.1z" />
                        </svg>
                      </span>
                    }
                  />
                </Tooltip>
                <Tooltip title="Make payment">
                  <Button
                    onClick={() => openPaymentModal(record, index)}
                    style={{
                      background: '#fff',
                      border: 'none',
                      boxShadow: 'none',
                    }}
                    type="primary"
                    shape="circle"
                    icon={
                      <span className="anticon anticon-dollar-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="m511.6 36.86-64 415.1a32.008 32.008 0 0 1-31.65 27.147c-4.188 0-8.319-.815-12.29-2.472l-122.6-51.1-50.86 76.29C226.3 508.5 219.8 512 212.8 512c-11.5 0-20.8-9.3-20.8-20.8v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96 122.3 360.3 19.69 317.5C8.438 312.8.812 302.2.062 289.1s5.47-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z" />
                        </svg>
                      </span>
                    }
                  />
                </Tooltip>
              </>
            ) : null}

            <Tooltip title="View appointment">
              <Button
                onClick={() => {
                  setAppointmentDetails({ ...record, appointmentIndex: index });
                  setDetailsDrawer(true);
                }}
                style={{
                  background: '#fff',
                  border: 'none',
                  boxShadow: 'none',
                }}
                type="primary"
                shape="circle"
                icon={
                  <span className="anticon anticon-dollar-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path d="M279.6 160.4c2.8-.3 5.6-.4 8.4-.4 53 0 96 42.1 96 96 0 53-43 96-96 96-53.9 0-96-43-96-96 0-2.8.1-5.6.4-8.4 9.3 4.5 20.1 8.4 31.6 8.4 35.3 0 64-28.7 64-64 0-11.5-3.9-22.3-8.4-31.6zm201-47.8c46.8 43.4 78.1 94.5 92.9 131.1 3.3 7.9 3.3 16.7 0 24.6-14.8 35.7-46.1 86.8-92.9 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.58-80.6C48.62 355.1 17.34 304 2.461 268.3a31.967 31.967 0 0 1 0-24.6C17.34 207.1 48.62 156 95.42 112.6 142.5 68.84 207.2 32 288 32c80.8 0 145.5 36.84 192.6 80.6zM288 112c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144-64.5-144-144-144z" />
                    </svg>
                  </span>
                }
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const onPaginateChange = (value: number) => {
    const params = { ...filterParams, page: value };
    setFilterParams(params);
    fetchAppointments(params);
  };

  const onPaymentSuccess = (payload: AppointmentWithIndex) => {
    const prevAppointments = [...appointments];
    const appointment: Appointment = prevAppointments[payload.appointmentIndex];
    appointment['paymentStatus'] = 'success';
    setAppointments(prevAppointments);
    setPayableAppointment(null);
    setPaymentModal(false);
  };
  return (
    <>
      <div className="page__contents">
        <div></div>
        <Button type="primary" className="btn-create">
          <Link to="/appointments/create">Create Appointment</Link>
        </Button>
      </div>
      <AppointmentFilter />
      <ProTable<any>
        dataSource={appointments}
        columns={columns}
        actionRef={actionRef}
        search={false}
        pagination={false}
        rowKey="id"
        cardBordered
        loading={loading}
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.perPage || 15}
          showSizeChanger={false}
        />
      </div>
      <Drawer
        title={appointmentDetails?.serialNo}
        placement="right"
        size="large"
        onClose={onCloseDetailsDrawer}
        visible={detailsDrawer}
      >
        {appointmentDetails ? (
          <AppointmentDetails
            appointment={appointmentDetails}
            onPaymentSuccess={onPaymentSuccess}
          />
        ) : null}
      </Drawer>

      <Modal
        title={`Pay for: ${payableAppointment?.patientName}`}
        visible={paymentModal}
        onCancel={closePaymentModal}
        footer={false}
      >
        {payableAppointment ? (
          <PaymentDetails
            onPaymentSuccess={onPaymentSuccess}
            appointment={payableAppointment}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default AppointmentList;
