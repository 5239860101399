import React from 'react';
import Layout from '../components/layout/Layout';
import ScheduleFormContent from '../components/scheduleFormContent/ScheduleFormContent';

const ScheduleCreate = () => {
  return (
    <Layout>
      <ScheduleFormContent />
    </Layout>
  );
};

export default ScheduleCreate;
