/* eslint-disable no-console */
import { ProDescriptions } from '@ant-design/pro-components';
import { Avatar, Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ScheduleManager from '../api/schedules/request';
import Layout from '../components/layout/Layout';

const SchedduleDetails = () => {
  const { id }: any = useParams();
  const [schedule, setSchedule] = useState<any>(null);

  useEffect(() => {
    fetchSchedule();
  }, []);

  const fetchSchedule = async () => {
    try {
      if (id) {
        const response = await ScheduleManager.get({ id });
        console.log('schecudel', response);
        setSchedule(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <Row>
        <Col span={16}>
          <div className="details__content bg-white">
            <div className="details__content__item">
              <h4>#{schedule?.id}</h4>
            </div>
            <div className="details__content__item">
              <div
                style={{ display: 'flex', gap: '20px', alignItems: 'center' }}
              >
                <Avatar src={schedule?.photo} size={64} />
                <div>
                  <h3 style={{ margin: '0' }}>{schedule?.full_name}</h3>
                  <p>{schedule?.specialties}</p>
                </div>
              </div>
            </div>
            <div className="details__content__item">
              <p>{schedule?.educations}</p>
              <p>{schedule?.works}</p>
              <p>{schedule?.area}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default SchedduleDetails;
