import React from 'react';
import Layout from '../components/layout/Layout';
import ScheduleList from '../components/scheduleList/ScheduleList';

const Schedules = () => (
  <Layout>
    <ScheduleList />
  </Layout>
);
export default Schedules;
