import endpoints from '../../configs/endpoints';
import { Meta } from '../../interfaces/api';
import {
  DoctorDetailsInterface,
  DoctorInterface,
} from '../../interfaces/doctor';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import { mapMeta } from '../util';
import Mapper from './mapper';
interface ResponseInterface {
  data: Array<DoctorInterface> | DoctorInterface | null | any;
  meta?: Meta | null;
}

interface DoctorsRequestInterface {
  page?: number;
  perPage?: number;
}

interface DoctorRequestInterface {
  id: string;
}

/**
 * Fetch all doctors for the current provider with pagination
 * @param request - the request object
 * @returns {Promise<ResponseInterface>}
 */

export const all = async (request?: any): Promise<ResponseInterface> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_DOCTORS_ENDPOINT.replace(':providerId', getProviderId()),
    request,
    true
  );
  return {
    data: Mapper.dataFromSTC(response.data.data),
    meta: Mapper.meta(response.data.meta),
  };
};

/**
 * Fetch a doctor by id
 * @param request - doctor id
 * @returns {Promise<ResponseInterface>}
 */
export const get = async (
  request: DoctorRequestInterface
): Promise<{
  data: DoctorDetailsInterface;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_DOCTOR_SHOW_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':doctorId', request.id),
    request,
    true
  );
  return {
    data: Mapper.dataDetailsItemFromSTC(response.data.data),
  };
};

/**
 * create a new doctor
 * @param request the request body
 * @returns {Promise<DoctorDetailsInterface>}
 */
export const create = async (
  request: any
): Promise<{
  data: DoctorDetailsInterface;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_DOCTORS_STORE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return {
    data: response.data,
  };
};

/**
 * create a new doctor
 * @param request the request body
 * @returns {Promise<DoctorDetailsInterface>} the updated doctor
 */
export const update = async (request: any): Promise<any> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_DOCTORS_UPDATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':doctorId', request.id),
    request,
    true
  );
  return {
    message: response.data.message,
    status: response.data.success,
  };
};

export const deactivate = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_DOCTOR_DEACTIVATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':doctorId', request.id),
    request,
    true
  );
  return {
    data: response.data,
  };
};

export const activate = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_DOCTOR_ACTIVATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':doctorId', request.id),
    request,
    true
  );
  return {
    data: response.data,
  };
};

const DoctorManager = {
  all,
  get,
  update,
  create,
  deactivate,
  activate,
};

export default DoctorManager;
