import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import TemplateManager from '../../api/templates/request';
import { DATE_TIME_FORMAT } from '../../configs/constants';
interface Props {
  templateId: number;
  onTested: (payload: any) => void;
}

export default function TemplateTest(props: Props) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async (requestParam: any) => {
    setLoading(true);
    try {
      const response = await TemplateManager.test({
        ...requestParam,
        appointment_at: requestParam.appointment_at.format(DATE_TIME_FORMAT),
        id: props.templateId,
      });
      props.onTested(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  return (
    <div>
      <Form
        form={form}
        name="template-test-form"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              name="doctor_name"
              label="Doctor Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter doctor name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="patient_name"
              label="Patient Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter patient name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="appointment_at"
              label="Appointment at"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Button loading={loading} type="primary" htmlType="submit">
          Test
        </Button>
      </Form>
    </div>
  );
}
