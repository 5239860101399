import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChamberManager from '../api/chambers/request';
import ChamberFormContent from '../components/chamberFormContent/ChamberFormContent';
import Layout from '../components/layout/Layout';

const ChamberUpdate = () => {
  const [chamber, setChamber] = useState<any>(null);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getDoctorById(id);
    }
  }, [id]);
  const getDoctorById = async (id: any) => {
    try {
      const response = await ChamberManager.get({ id });
      setChamber(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    chamber && (
      <Layout>
        <ChamberFormContent label="Update Chamber" chamber={chamber} />
      </Layout>
    )
  );
};

export default ChamberUpdate;
