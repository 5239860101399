import React from 'react';
import DashboardContent from '../components/dashboardContent/DashboardContent';
import Layout from '../components/layout/Layout';

export default function Dashboard() {
  return (
    <Layout>
      <DashboardContent />
    </Layout>
  );
}
