import React from 'react';
import Layout from '../components/layout/Layout';
import UserForm from '../components/userForm/UserForm';

const UserCreate = () => {
  return (
    <Layout>
      <UserForm />
    </Layout>
  );
};

export default UserCreate;
