import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import lodash from 'lodash';
import { getMaxHeight } from '../../services/utility';

/** interface for LabelObj object */
export interface LabelObj {
  id: string;
  title: string;
  color: string;
  order: number;
  isFavourite: boolean;
  isHidden: boolean;
  parent: string;
  expanded: boolean;
  synced: boolean;
}

/** The reducer name */
export const reducerName = 'labels';

// actions
/** action types */
export const SET_LABELS = 'virtunus/reducer/labels/SET_LABELS';
export const UPDATE_LABELS = 'virtunus/reducer/labels/UPDATE_LABELS';
export const SET_LABEL = 'virtunus/reducer/labels/SET_LABEL';
export const FIX_LABEL_ORDERS = 'virtunus/reducer/labels/FIX_LABEL_ORDERS';
export const SET_LABELS_SYNCED_STATUS =
  'virtunus/reducer/labels/SET_LABELS_SYNCED_STATUS';

/** interface for SET_LABELS action */
export interface SetLabelsAction extends AnyAction {
  labels: LabelObj[];
  type: typeof SET_LABELS;
}

/** interface for UPDATE_LABELS action */
export interface UpdateLabelsAction extends AnyAction {
  labels: LabelObj[];
  type: typeof UPDATE_LABELS;
}

/** interface for SET_LABEL action */
export interface SetLabelAction extends AnyAction {
  label: LabelObj;
  type: typeof SET_LABEL;
}

/** interface for FIX_LABEL_ORDERS action */
export interface FixLabelOrdersAction extends AnyAction {
  parentId: string;
  type: typeof FIX_LABEL_ORDERS;
}

/** interface for SET_LABELS_SYNCED_STATUS action */
export interface SetLabelsSyncedStatusAction extends AnyAction {
  status: boolean;
  type: typeof SET_LABELS_SYNCED_STATUS;
  ids: string[];
}

/** Create type for label reducer actions */
export type LabelsActionTypes =
  | SetLabelsAction
  | UpdateLabelsAction
  | SetLabelAction
  | FixLabelOrdersAction
  | SetLabelsSyncedStatusAction
  | AnyAction;

// action creators

/** set labels action creator
 * @param {LabelObj} labels - an array of label items
 * @returns {SetLabelsAction} - an action to set labels in store
 */
export const setLabels = (labels: LabelObj[]): SetLabelsAction => {
  return {
    labels,
    type: SET_LABELS,
  };
};

/** update labels action creator
 * @param {labelObj} projects - an array of label items
 * @returns {UpdateLabelsAction} - an action to update labels in store
 */
export const updateLabels = (labels: LabelObj[]): UpdateLabelsAction => {
  const ids = labels.map((label: LabelObj) => label.id);
  return {
    labels,
    type: UPDATE_LABELS,
    ids,
  };
};

/** set labels action creator
 * @param {LabelObj} label - a label object
 * @returns {SetLabelAction} - an action to set label in store
 */
export const setLabel = (label: LabelObj): SetLabelAction => ({
  label,
  type: SET_LABEL,
});

/**
 * fixes the order of labels given a parent id
 * @param {string} parentId - the parent id
 * @returns {FixLabelOrdersAction} - an action to fix the order of labels respect to a parent id
 */
export const fixLabelOrders = (parentId: string): FixLabelOrdersAction => ({
  parentId,
  type: FIX_LABEL_ORDERS,
});

/** set labels synced status action creator
 * @param {string[]} ids - an array of label ids
 * @param {boolean} status - the synced status to set
 * @returns {SetLabelsSyncedStatusAction} - an action to set labels synced status in store
 */
export const setLabelsSyncedStatus = (
  ids: string[],
  status: boolean
): SetLabelsSyncedStatusAction => ({
  status,
  type: SET_LABELS_SYNCED_STATUS,
  ids,
});

// the reducer

/** interface for labels state in redux store */
type LabelsState = LabelObj[];

/** Create an immutable labels state */
export type ImmutableLabelsState =
  SeamlessImmutable.ImmutableArray<LabelsState>;

/** initial labels state */
const initialState: ImmutableLabelsState = SeamlessImmutable([]);

/** the labels reducer function */
export default function reducer(
  state: ImmutableLabelsState = initialState,
  action: LabelsActionTypes
): ImmutableLabelsState {
  switch (action.type) {
    case SET_LABELS:
      return SeamlessImmutable(action.labels);
    case SET_LABEL:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateLabel: LabelObj) => iterateLabel.id !== action.label.id
        ),
        action.label,
      ]);
    case UPDATE_LABELS:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateLabel: LabelObj) => !action.ids.includes(iterateLabel.id)
        ),
        ...action.labels,
      ]);
    case FIX_LABEL_ORDERS:
      return SeamlessImmutable([
        ...lodash.filter(
          state as any,
          (iterateLabel: LabelObj) => iterateLabel.parent !== action.parentId
        ),
        ...lodash.map(
          lodash.filter(
            state,
            (iterateLabel: LabelObj) => iterateLabel.parent === action.parentId
          ),
          (iterateLabel: LabelObj, index: number) => ({
            ...iterateLabel,
            order: index,
          })
        ),
      ]);
    case SET_LABELS_SYNCED_STATUS:
      return SeamlessImmutable(
        lodash.map(state, (iterateLabel: LabelObj) => {
          return action.ids.includes(iterateLabel.id)
            ? { ...iterateLabel, synced: action.status }
            : iterateLabel;
        }) as any
      );
    default:
      return state;
  }
}

// selectors

/** returns the labels list
 * @param {Partial<Store>} state - the redux store
 * @return { LabelObj[] } - the existing labels
 */
export function getLabels(state: Partial<Store>): LabelObj[] {
  return (state as any)[reducerName];
}

/** returns the labels list length
 * @param {Partial<Store>} state - the redux store
 * @return { number } - the existing labels length
 */
export function getLabelsLength(state: Partial<Store>): number {
  return (state as any)[reducerName].length;
}

/** returns the label obj given label id if exists; otherwise, null
 * @param {Partial<Store>} state - the redux store
 * @param {string} labelId - the label id
 * @return { LabelObj | null } - the existing label or null
 */
export function getLabelById(state: Partial<Store>, labelId: string): LabelObj {
  return lodash.find((state as any)[reducerName], { id: labelId }) || null;
}

/** returns the new available order in list given a label id as parent
 * @param {Partial<Store>} state - the redux store
 * @param {string} label - the label id
 * @return { number } - the new available order
 */
export function getNewLabelOrderbyLabelId(
  state: Partial<Store>,
  labelId: string
): number {
  return lodash.filter((state as any)[reducerName], { parent: labelId }).length;
}

/** returns the maximum height or depth given a label id
 * @param {Partial<Store>} state - the redux store
 * @param {string} label - the label id
 * @return { number } - the maximum height
 */
export function getMaxHeightOfLabel(
  state: Partial<Store>,
  labelId: string
): number {
  return getMaxHeight((state as any)[reducerName], labelId);
}

/**
 * returns the list of unsynced labels
 * @param {Partial<Store>} state - the redux store
 * @returns {LabelObj[]} - the list of unsynced labels
 */
export function getUnsyncedLabels(state: Partial<Store>): LabelObj[] {
  return lodash.filter((state as any)[reducerName], { synced: false });
}
