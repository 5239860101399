import { AppointmentStatusStr, PaymentStatus } from './../../enums/status';
import moment from 'moment';
import { DATE_VISIBLE_FORMAT, TIME_FORMAT } from '../../configs/constants';
import { Appointment } from '../../interfaces/appointment';
import { Color } from '../../enums/colors';

export const dataFromSTC = (data: any): Array<Appointment> =>
  data.map(dataItemFromSTC);

export const dataDetailsFromSTC = (data: any[]): Appointment[] =>
  data.map(dataDetailsItemFromSTC);

export const dataItemFromSTC = (dataItem: any): Appointment => ({
  id: dataItem.id,
  serialNo: dataItem.serial_no,
  doctorName: dataItem.doctor.full_name,
  doctorSpecialities: dataItem.doctor.specialties,
  patientName: dataItem.patient.name,
  patientContactNo: dataItem.patient.contact_no,
  patientAge: dataItem.patient.age,
  patientPhoto: dataItem.patient.photo,
  patientGender: dataItem.patient.gender,
  chamberName: dataItem.chamber.name,
  chamberAddress: dataItem.chamber.address,
  consultationType: dataItem.consultation_type,
  appointmentStatus: dataItem.appointment_status,
  appointmentStatusColor:
    dataItem.appointment_status === AppointmentStatusStr.Canceled
      ? Color.Error
      : Color.Success,
  appointmentType: dataItem.type,
  complaint: dataItem.complaint,
  isOld: dataItem.is_old,
  visitingFee: dataItem.visiting_fee,
  confirmationAmount: dataItem.confirmation_amount,
  serviceChargePercentage: dataItem.service_charge_percentage,
  serviceCharge: dataItem.service_charge,
  discount: dataItem.discount,
  minimum_payable: dataItem.minimum_payable,
  netPayable: dataItem.net_payable,
  paymentStatus: dataItem.due_amount || PaymentStatus.Paid,
  paymentStatusColor: dataItem.due_amount ? Color.Error : Color.Success,
  paidAmount: dataItem.paid_amount,
  dueAmount: dataItem.due_amount,
  appointmentDate: moment(dataItem.appointment_at).format(DATE_VISIBLE_FORMAT),
  appointmentTime: moment(dataItem.appointment_at).format(TIME_FORMAT),
  appointmentEndTime: moment(
    dataItem.appointment_end_at
      ? dataItem.appointment_end_at
      : dataItem.appointment_at
  ).format(TIME_FORMAT),
});

export const dataDetailsItemFromSTC = (data: any): Appointment => ({
  ...dataItemFromSTC(data),
});

const meta = (meta: any) => ({
  currentPage: meta.current_page,
  page: meta.page,
  perPage: meta.per_page,
  total: meta.total,
});

const Mapper = {
  dataFromSTC,
  dataItemFromSTC,
  dataDetailsFromSTC,
  dataDetailsItemFromSTC,
  meta,
};

export default Mapper;
