import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TemplateManager from '../api/templates/request';
import Layout from '../components/layout/Layout';
import TemplateForm from '../components/templateForm/TemplateForm';

const TemplateUpdate = () => {
  const [data, setData] = useState<any>(null);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getDataById(id);
    }
  }, [id]);
  const getDataById = async (id: any) => {
    try {
      const response = await TemplateManager.get({ id });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    data && (
      <Layout>
        <TemplateForm data={data} />
      </Layout>
    )
  );
};

export default TemplateUpdate;
