import endpoints from '../../configs/endpoints';
import { Meta } from '../../interfaces/api';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import { Appointment } from './../../interfaces/appointment.d';
import Mapper from './mapper';

/**
 * Fetch all appointments for the current provider with pagination
 * @param request
 * @returns {Promise<*>}
 */
const all = async (
  request: any
): Promise<{
  data: Array<Appointment>;
  meta: Meta;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_APPLICATIONS_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return {
    data: Mapper.dataFromSTC(response.data.data),
    meta: Mapper.meta(response.data.meta),
  };
};

/**
 * create an appointment
 * @param request
 * @returns {Promise<Appointment>}
 */
const create = async (request: any): Promise<{ data: Appointment }> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_APPLICATION_STORE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return {
    data: Mapper.dataItemFromSTC(response.data.data),
  };
};

export const get = async (request: any): Promise<any> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_APPOINTMENT_DETAILS_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':id', request.id),
    {},
    true
  );
  const output = {
    data: Mapper.dataDetailsItemFromSTC(response.data.data),
  };
  return output;
};

export const update = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_APPOINTMENT_STATUS_UPDATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':appointmentId', request.id),
    request,
    true
  );
  return {
    data: response,
  };
};

export const pay = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_APPOINTMENT_PAYMENT_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':appointmentId', request.appointment_id),
    request,
    true
  );
  return {
    data: response,
  };
};

const AppointmentManager = {
  all,
  create,
  get,
  update,
  pay,
};

export default AppointmentManager;
