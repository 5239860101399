/** global constants */

/** server login grant type */
export const SERVER_LOGIN_GRANT_TYPE =
  process.env.REACT_APP_LOGIN_GRANT_TYPE || 'password';

/** server login client id */
export const SERVER_LOGIN_CLIENT_ID =
  process.env.REACT_APP_LOGIN_CLIENT_ID || '2';

/** server login client secret */
export const SERVER_LOGIN_CLIENT_SECRET =
  process.env.REACT_APP_LOGIN_CLIENT_SECRET ||
  'BeUTDDRWLn6H4ZN2TXOULfkRxutzwFe924HSpHli';

/** server login client scope */
export const SERVER_LOGIN_CLIENT_SCOPE =
  process.env.REACT_APP_LOGIN_CLIENT_SCOPE || '*';

/** server login app name */
export const SERVER_LOGIN_APP_NAME =
  process.env.REACT_APP_LOGIN_APP_NAME || 'patient_portal';

export const AppointmentStatusColors: any = {
  0: 'gold',
  1: 'green',
  2: 'red',
  3: 'black',
  4: 'volcano',
  5: 'lime',
  6: 'blue',
};

export const TIME_FORMAT = 'hh:mm a';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_VISIBLE_FORMAT = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD, hh:mm';

const constants = {
  SERVER_LOGIN_GRANT_TYPE,
  SERVER_LOGIN_CLIENT_ID,
  SERVER_LOGIN_CLIENT_SECRET,
  SERVER_LOGIN_CLIENT_SCOPE,
  SERVER_LOGIN_APP_NAME,
  AppointmentStatusColors,
};

export default constants;
