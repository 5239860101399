import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Store } from 'redux';
import endpoints, { LOCAL_LOGOUT_ENDPOINT } from '../configs/endpoints';
import Account from '../containers/account/Account';
import ConnectedAccountRecovery from '../containers/accountRecovery/AccountRecovery';
import ConnectedGuestRoute from '../containers/guestRoute/GuestRoute';
import Login from '../containers/login/Login';
import ConnectedPrivateRoute from '../containers/privateRoute/PrivateRoute';
import Register from '../containers/register/Register';
import ConnectedResetPassword from '../containers/resetPassword/ResetPassword';
import ConnectedSessionAuthorize from '../containers/sessionAuthorize/SessionAuthorize';
import ConnectedSessionDestroy from '../containers/sessionDestroy/SessionDestroy';
import ConnectedVerifyEmail from '../containers/verifyEmail/VerifyEmail';
import AppointmentCreate from '../pages/AppointmentCreate';
import Appointments from '../pages/Appointments';
import CreateChamberForm from '../pages/ChamberCreate';
import ChamberDetails from '../pages/ChamberDetails';
import Chambers from '../pages/Chambers';
import Dashboard from '../pages/Dashboard';
import CreateDoctorForm from '../pages/DoctorCreate';
import DoctorDetails from '../pages/DoctorDetails';
import Doctors from '../pages/Doctors';
import CreatePatientForm from '../pages/PatientCreate';
import Patients from '../pages/Patients';
import Report from '../pages/Report';
import CreateScheduleForm from '../pages/ScheduleCreate';
import SchedduleDetails from '../pages/ScheduleDetails';
import Schedules from '../pages/Schedules';
import UpdateDoctorForm from '../pages/DoctorUpdate';
import UpdatePatient from '../pages/PatientUpdate';
import UpdateSchedule from '../pages/ScheduleUpdate';
import UpdateUserForm from '../pages/UserUpdate';
import CreateUserForm from '../pages/UserCreate';
import Users from '../pages/Users';
import { axioService, GET } from '../services/axioService';
import { getSessionToken, setSessionUserInfo } from '../store/ducks/session';
import './App.scss';
import NotFound from '../pages/NotFound';
import Transactions from '../pages/Transactions';
import Templates from '../pages/Templates';
import TemplateCreate from '../pages/TemplateCreate';
import TemplateUpdate from '../pages/TemplateUpdate';
import ChamberUpdate from '../pages/ChamberUpdate';

interface AppProps {
  token: string;
  setSessionUserInfoActionCreator: typeof setSessionUserInfo;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const { token, setSessionUserInfoActionCreator } = props;

  /** fetches initial data based on login */
  React.useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axioService(
          GET,
          endpoints.SERVER_USER_PROFILE_ENDPOINT,
          {},
          true
        );
        const userInfo = response.data.data;
        if (userInfo) {
          const providerResponse = await axioService(
            GET,
            endpoints.SERVER_MY_PRIVIDER_ENDPOINT,
            {},
            true
          );
          if (providerResponse.data.data) {
            const providerId = providerResponse.data.data[0].id;
            if (providerId) {
              setSessionUserInfoActionCreator({
                ...response.data.data,
                providerId: providerId,
              });
            } else {
              window.location.href = LOCAL_LOGOUT_ENDPOINT;
            }
          } else {
            window.location.href = LOCAL_LOGOUT_ENDPOINT;
          }
        }
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };
    if (token !== '') {
      fetchProfile();
    }
  }, [token]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            path={'/'}
            element={
              <ConnectedGuestRoute>
                <Login />
              </ConnectedGuestRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_LOGIN_ENDPOINT}
            element={
              <ConnectedGuestRoute>
                <Login />
              </ConnectedGuestRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_REGISTER_ENDPOINT}
            element={
              <ConnectedGuestRoute>
                <Register />
              </ConnectedGuestRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_DASHBOARD_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <Dashboard />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_APPOINTMENTS_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <Appointments />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_APPOINTMENTS_CREATE_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <AppointmentCreate />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_SCHEDULES_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <Schedules />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_SCHEDULES_EDIT_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <UpdateSchedule />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_REPORTS_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <Report />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_DOCTORS_DETAILS_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <DoctorDetails />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_DOCTORS_EDIT_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <UpdateDoctorForm />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_DOCTORS_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <Doctors />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_DOCTORS_CREATE_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <CreateDoctorForm />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_CHAMBERS_CREATE_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <CreateChamberForm />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_CHAMBERS_DETAILS_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <ChamberDetails />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_PATIENTS_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <Patients />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_PATIENTS_CREATE_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <CreatePatientForm />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_PATIENTS_EDIT_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <UpdatePatient />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_CHAMBERS_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <Chambers />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_CHAMBERS_EDIT_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <ChamberUpdate />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_SCHEDULES_CREATE_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <CreateScheduleForm />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_USERS_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <Users />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_USERS_CREATE_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <CreateUserForm />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_USERS_EDIT_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <UpdateUserForm />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_SCHEDULES_DETAILS_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <SchedduleDetails />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_LOGOUT_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <ConnectedSessionDestroy />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_AUTHORIZE_ENDPOINT}
            element={<ConnectedSessionAuthorize />}
          />
          <Route
            path={endpoints.LOCAL_FORGOT_PASSWORD_ENDPOINT}
            element={
              <ConnectedGuestRoute>
                <ConnectedAccountRecovery />
              </ConnectedGuestRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_RESET_PASSWORD_ENDPOINT}
            element={
              <ConnectedGuestRoute>
                <ConnectedResetPassword />
              </ConnectedGuestRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_PERSONAL_INFO_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <Account />
              </ConnectedPrivateRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_VERIFY_EMAIL_ENDPOINT}
            element={
              <ConnectedPrivateRoute>
                <ConnectedVerifyEmail />
              </ConnectedPrivateRoute>
            }
          />
        </Route>
        <Route
          path={endpoints.LOCAL_TEMPLATES_ENDPOINT}
          element={
            <ConnectedPrivateRoute>
              <Templates />
            </ConnectedPrivateRoute>
          }
        />
        <Route
          path={endpoints.LOCAL_TEMPLATES_CREATE_ENDPOINT}
          element={
            <ConnectedPrivateRoute>
              <TemplateCreate />
            </ConnectedPrivateRoute>
          }
        />
        <Route
          path={endpoints.LOCAL_TEMPLATES_EDIT_ENDPOINT}
          element={
            <ConnectedPrivateRoute>
              <TemplateUpdate />
            </ConnectedPrivateRoute>
          }
        />
        <Route
          path={endpoints.LOCAL_TRANSACTIONS_ENDPOINT}
          element={
            <ConnectedPrivateRoute>
              <Transactions />
            </ConnectedPrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setSessionUserInfoActionCreator: setSessionUserInfo,
};

/** connect App to the redux store */
const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

/** the default export */
export default ConnectedApp;
