import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PatientManager from '../api/patients/request';
import Layout from '../components/layout/Layout';
import PatientForm from '../components/patientForm/PatientForm';

const PatientUpdate = () => {
  const [patient, setPatient] = useState<any>(null);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getPatientById(id);
    }
  }, [id]);
  const getPatientById = async (id: any) => {
    try {
      const response = await PatientManager.get({ id });
      setPatient(response);
    } catch (error) {
      console.error(error);
    }
  };
  return patient ? (
    <Layout>
      <PatientForm submitBtnLabel="Update" patient={patient} />
    </Layout>
  ) : null;
};

export default PatientUpdate;
