import React from 'react';
import AppointmentForm from '../components/appointmentForm/AppointmentForm';
import Layout from '../components/layout/Layout';

const AppointmentCreate = () => {
  return (
    <Layout>
      <AppointmentForm />
    </Layout>
  );
};

export default AppointmentCreate;
