import React from 'react';
import ChamberList from '../components/chamberList/ChamberList';
import Layout from '../components/layout/Layout';

const Chambers = () => (
  <Layout>
    <ChamberList />
  </Layout>
);

export default Chambers;
