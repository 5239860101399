/* eslint-disable no-console */
import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserManager from '../../api/users/request';
import endpoints from '../../configs/endpoints';
import styles from './UserForm.module.scss';

const { Option } = Select;
interface Props {
  user?: any;
  UserName?: string;
  submitBtnLabel?: string;
  onUserCreate?: any;
  shortForm?: boolean;
  showTitle?: boolean;
}

const INITIAL_USERINPUT = {
  role_id: 2,
  name: '',
  mobile: '',
  email: '',
  password: '',
};
const UserForm = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isEdit = !!props.user;
  const [roles, setRoles] = useState([]);
  const [detailsModal, setDetailsModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchRoles();
  }, []);
  const onFinish = async (request: any) => {
    isEdit ? editUser(request) : createNewUser(request);
  };

  const fetchRoles = async (request?: any) => {
    try {
      const response: any = await UserManager.getRoles({
        ...request,
      });
      setRoles(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const createNewUser = async (request: any) => {
    try {
      setLoading(true);
      const response: any = await UserManager.create({
        ...request,
      });
      message.success('User created successfully');
      form.resetFields();
      setLoading(false);
      navigate(endpoints.LOCAL_USERS_ENDPOINT);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  const editUser = async (request: any) => {
    try {
      setLoading(true);
      const response = await UserManager.update({
        ...request,
        id: props.user?.id,
      });
      message.success('User updated successfully');
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  return (
    <div
      className={clsx(
        styles.FormWrapper,
        'bg-white form__content',
        props.shortForm && styles.ShortForm
      )}
    >
      <Row>
        <Col
          lg={props.shortForm ? 24 : 16}
          md={props.shortForm ? 24 : 16}
          sm={24}
          xs={24}
        >
          {!props.showTitle ? null : (
            <h2>{isEdit ? 'Edit User' : 'Create User'}</h2>
          )}
          <Form
            initialValues={props.user || INITIAL_USERINPUT}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="mobile"
              label="Mobile"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, type: 'email' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="role_id" label="Role" rules={[{ required: true }]}>
              <Select>
                {roles.map((role: any, index: number) => (
                  <Select.Option key={index} value={role.value}>
                    {role.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: props.user ? false : true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              hasFeedback
              rules={[
                {
                  required: props.user ? false : true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {isEdit ? 'Update' : 'Create'}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default UserForm;
