/* eslint-disable no-console */
import { Alert, Button, Card, Form, Input, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  LOCAL_LOGIN_ENDPOINT,
  LOCAL_PERSONAL_INFO_ENDPOINT,
  PRIVACY_POLICY_ENDPOINT,
  SERVER_APPLE_AUTH_LINK,
  SERVER_FACEBOOK_AUTH_LINK,
  SERVER_GOOGLE_AUTH_LINK,
  SERVER_REGISTER_ENDPOINT,
  TERMS_OF_SERVICE_ENDPOINT,
  VIRTUNUS_HOME_ENDPOINT,
} from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import { getSessionToken } from '../../store/ducks/session';
import './Register.scss';

/** interface to describe register props */
interface RegisterProps {
  token: string;
}

/** the Register component */
const Register: React.FC<RegisterProps> = (props: RegisterProps) => {
  const { token } = props;
  const location = useLocation();
  const history = useNavigate();
  const nameRef = React.useRef<any>(null);
  const passwordRef = React.useRef<any>(null);
  const passwordConfirmationRef = React.useRef<any>(null);

  // React states
  /** manages loading while processing register request */
  const [loading, setLoading] = React.useState<boolean>(false);
  /** error message to show if occurs */
  const [errorMessage, setErrorMessage] = React.useState<any>('');
  /** status to manage password visibility */
  const [isPasswordVisible, setIsPasswordVisible] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  /** cycles */

  React.useEffect(() => {
    nameRef.current?.focus();
  }, []);

  /** handlers */

  /** status to manage password confirmation visibility */
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] =
    React.useState<boolean>(false);

  /** toggles the password input visibility */
  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    passwordRef.current.blur();
  };

  /** toggles the password confirmation input visibility */
  const handlePasswordConfirmationVisibility = () => {
    setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);
    passwordConfirmationRef.current.blur();
  };

  /**
   * onFinish is called when form validation passes
   * @param {any} values - containing the key-value user response
   */
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const response = await axioService(
        POST,
        SERVER_REGISTER_ENDPOINT,
        values,
        false
      );
      /** redirects on successful login */
      if (response.status === 201) {
        navigate(LOCAL_LOGIN_ENDPOINT + location.search);
      }
      setLoading(false);
    } catch (exception) {
      /** console error the exception */
      console.error(exception);
      setLoading(false);
      if (
        (exception as any).response &&
        (exception as any).response.data &&
        (exception as any).response.data.message
      ) {
        /** create the error msg from server response */
        const errorMsg = (
          <div>
            {(exception as any).response.data.message} <br />
            <ul>
              {(exception as any).response.data.errors &&
                Object.keys((exception as any).response.data.errors).map(
                  (iterErrorField) => (
                    <li key="iterErrorField">
                      <span className="Register-alert-field">
                        {iterErrorField}
                      </span>{' '}
                      :{' '}
                      {(exception as any).response.data.errors[iterErrorField]}
                    </li>
                  )
                )}
            </ul>
          </div>
        );
        setErrorMessage(errorMsg);
      } else {
        setErrorMessage('There is a network error !!!');
      }
    }
  };

  /**
   * onFinishFailed is called when form validation fails
   * @param {any} errorInfo - containing the respective error information
   */
  const onFinishFailed = (errorInfo: any) => {
    // eslint-disable-next-line no-console
    console.error('Failed:', errorInfo);
  };

  /** onAlertClose is used to close alert message */
  const onAlertClose = () => setErrorMessage('');

  /** google login handler */
  const googleLoginHandler = () => {
    window.location.href = SERVER_GOOGLE_AUTH_LINK;
  };

  /** facebook login handler */
  const facebookLoginHandler = () => {
    window.location.href = SERVER_FACEBOOK_AUTH_LINK;
  };

  /** apple login handler */
  const appleLoginHandler = () => {
    window.location.href = SERVER_APPLE_AUTH_LINK;
  };

  return (
    <Spin spinning={loading}>
      {errorMessage !== '' && (
        <Alert
          closable
          onClose={onAlertClose}
          className="Register-error"
          message="Registration Failed"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
      <div className="Register-body">
        <div className="Register-content">
          {token !== '' && (
            <Navigate to={LOCAL_PERSONAL_INFO_ENDPOINT} replace />
          )}
          <div className="Register-logo-container">
            <a href={VIRTUNUS_HOME_ENDPOINT}>
              <img src="/logo.webp" width="138" alt="" />
            </a>
          </div>
          <Form
            name="register"
            validateTrigger="onSubmit"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Card className="Register-card">
              <h4 className="Register-card-title">REGISTRATION</h4>
              <Form.Item
                className="Register-form-item"
                name="name"
                rules={[{ required: true, message: 'Please input a name' }]}
              >
                <Input
                  ref={nameRef}
                  size="large"
                  placeholder="Name"
                  prefix={<i className="fas fa-user" />}
                />
              </Form.Item>
              <Form.Item
                className="Register-form-item"
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please input valid email!' },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Email"
                  prefix={<i className="fas fa-envelope" />}
                />
              </Form.Item>
              <Form.Item
                className="Register-form-item"
                name="org_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your organization name',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Organization name"
                  prefix={<i className="fas fa-building" />}
                />
              </Form.Item>
              <Form.Item
                className="Register-form-item"
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: 'Please input your mobile number',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Mobile number"
                  prefix={<i className="fas fa-phone" />}
                />
              </Form.Item>
              <Form.Item
                className="Register-form-item"
                name="iso_no"
                rules={[
                  {
                    required: true,
                    message: 'Please enter iso_no',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="ISO no"
                  prefix={<i className="fas fa-cog" />}
                />
              </Form.Item>
              <Form.Item
                className="Register-form-item"
                name="registration_no"
                rules={[
                  {
                    required: true,
                    message: 'Please enter registration no',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Registration no"
                  prefix={<i className="fas fa-cog" />}
                />
              </Form.Item>
              <Form.Item className="Register-form-item" name="address">
                <Input
                  size="large"
                  placeholder="Address"
                  prefix={<i className="fas fa-cog" />}
                />
              </Form.Item>
              <Form.Item
                className="Register-form-item"
                name="password"
                rules={[
                  { required: true, message: 'Please input a password!' },
                  {
                    min: 8,
                    message: 'Password needs to be minimum 8 characters!',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Password"
                  prefix={<i className="fas fa-lock" />}
                  type={isPasswordVisible ? 'text' : 'password'}
                  ref={passwordRef}
                  suffix={
                    isPasswordVisible ? (
                      <i
                        onClick={handlePasswordVisibility}
                        className="fas fa-eye-slash"
                      />
                    ) : (
                      <i
                        className="fas fa-eye"
                        onClick={handlePasswordVisibility}
                      />
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                className="Register-form-item"
                name="password_confirmation"
                rules={[
                  { required: true, message: 'Please confirm the password!' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      );
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  placeholder="Confirm Password"
                  prefix={<i className="fas fa-lock" />}
                  type={isPasswordConfirmationVisible ? 'text' : 'password'}
                  ref={passwordConfirmationRef}
                  suffix={
                    isPasswordConfirmationVisible ? (
                      <i
                        onClick={handlePasswordConfirmationVisibility}
                        className="fas fa-eye-slash"
                      />
                    ) : (
                      <i
                        className="fas fa-eye"
                        onClick={handlePasswordConfirmationVisibility}
                      />
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                extra={
                  <div>
                    By creating an account, you agree with our{' '}
                    <a target="blank" href={TERMS_OF_SERVICE_ENDPOINT}>
                      terms of service
                    </a>{' '}
                    and{' '}
                    <a target="blank" href={PRIVACY_POLICY_ENDPOINT}>
                      privacy policy
                    </a>
                    .
                  </div>
                }
              ></Form.Item>
              <Button
                className="Register-sign-up-btn"
                type="primary"
                htmlType="submit"
                size="large"
              >
                SIGN UP WITH EMAIL
              </Button>
              <div className="Register-signin-options-container">
                <h4>
                  <span className="Register-signin-title">
                    Already have an account?{' '}
                  </span>
                  <Link to={LOCAL_LOGIN_ENDPOINT + location.search}>
                    Sign in
                  </Link>
                </h4>
              </div>
              <div className="Register-divider-container">
                <div className="Register-divider" /> <h4> Or Register With </h4>
                <div className="Register-divider" />
              </div>
              <Button
                className="Register-google-btn"
                shape="round"
                size="large"
                onClick={googleLoginHandler}
              >
                Google
              </Button>
              <Button
                className="Register-facebook-btn"
                shape="round"
                size="large"
                onClick={facebookLoginHandler}
              >
                Facebook
              </Button>
              <Button
                className="Login-apple-btn"
                shape="round"
                size="large"
                onClick={appleLoginHandler}
              >
                Apple
              </Button>
            </Card>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect Register to the redux store */
const ConnectedRegister = connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);

export default ConnectedRegister;
