import React from 'react';
import Layout from '../components/layout/Layout';
import Form from '../components/templateForm/TemplateForm';

const TemplateCreate = () => {
  return (
    <Layout>
      <Form />
    </Layout>
  );
};

export default TemplateCreate;
