import React from 'react';
import AppointmentList from '../components/appointmentList/AppointmentList';
import Layout from '../components/layout/Layout';
import TemplateList from '../components/templateList/TemplateList';

const Templates = () => (
  <Layout>
    <TemplateList />
  </Layout>
);
export default Templates;
