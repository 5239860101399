import { ProDescriptions } from '@ant-design/pro-components';
import { Avatar, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DoctorManager from '../api/doctors/request';
import Layout from '../components/layout/Layout';

const DoctorDetails = () => {
  const { id }: any = useParams();
  const [doctor, setDoctor] = useState<any>(null);

  useEffect(() => {
    fetchDoctor();
  }, []);

  const fetchDoctor = async () => {
    try {
      if (id) {
        const response = await DoctorManager.get({ id });
        setDoctor(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <ProDescriptions column={2}>
        <ProDescriptions.Item valueType="option">
          <Button key="primary" type="primary">
            <Link to="/doctors">Back</Link>
          </Button>
        </ProDescriptions.Item>
        <ProDescriptions.Item
          span={2}
          valueType="text"
          contentStyle={{
            maxWidth: '80%',
          }}
          ellipsis
        >
          <div className="details__content bg-white">
            <div className="details__content__item">
              <h4>#{doctor?.bmdc_reg_no}</h4>
            </div>
            <div className="details__content__item">
              <div
                style={{ display: 'flex', gap: '20px', alignItems: 'center' }}
              >
                <Avatar src={doctor?.photo} size={64} />
                <div>
                  <h3 style={{ margin: '0' }}>{doctor?.full_name}</h3>
                  <p>{doctor?.specialties}</p>
                </div>
              </div>
            </div>
            <div className="details__content__item">
              <p>{doctor?.educations}</p>
              <p>{doctor?.works}</p>
              <p>{doctor?.area}</p>
            </div>
          </div>
        </ProDescriptions.Item>
      </ProDescriptions>
    </Layout>
  );
};

export default DoctorDetails;
