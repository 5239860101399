import React from 'react';
import DoctorsList from '../components/doctorList/DoctorList';
import Layout from '../components/layout/Layout';

const Doctors = () => (
  <Layout>
    <DoctorsList />
  </Layout>
);
export default Doctors;
