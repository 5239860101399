export const dataFromSTC = (data: any): Array<any> => data.map(dataItemFromSTC);

export const dataDetailsFromSTC = (data: any[]): any[] =>
  data.map(dataDetailsItemFromSTC);

export const dataItemFromSTC = (dataItem: any): any => ({
  id: dataItem.id,
  title: dataItem.title,
  subject: dataItem.subject,
  event: dataItem.event,
  channel: dataItem.channel,
  message: dataItem.message,
});

export const dataDetailsItemFromSTC = (data: any): any => ({
  ...dataItemFromSTC(data),
});

const meta = (meta: any) => ({
  currentPage: meta.current_page,
  page: meta.page,
  perPage: meta.per_page,
  total: meta.total,
});

const Mapper = {
  dataFromSTC,
  dataItemFromSTC,
  dataDetailsFromSTC,
  dataDetailsItemFromSTC,
  meta,
};

export default Mapper;
