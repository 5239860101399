import { Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Store } from 'redux';
import {
  LOCAL_LOGIN_ENDPOINT,
  SERVER_LOGOUT_ENDPOINT,
} from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import { getSessionToken, resetSession } from '../../store/ducks/session';
import queryString from 'query-string';
import './SessionDestroy.scss';

/** interface to describe SessionDestroy props*/
interface SessionDestroyProps {
  token: string;
  resetSessionActionCreator: typeof resetSession;
}

const SessionDestroy = (props: SessionDestroyProps) => {
  const { resetSessionActionCreator } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const clearSession = async () => {
    try {
      await axioService(POST, SERVER_LOGOUT_ENDPOINT, {}, true);
    } catch (exception) {
      console.error(exception);
    }
    resetSessionActionCreator();

    const app = queryString.parse(location.search)['app'] || '';
    const guest = queryString.parse(location.search)['guest'] || '';

    if (app !== '' && guest !== '') {
      window.location.href = `https://${app}.virtunus.com${decodeURIComponent(
        guest as string
      )}`;
    } else {
      navigate(LOCAL_LOGIN_ENDPOINT + location.search);
    }
  };

  /** destroys the session */
  React.useEffect(() => {
    clearSession();
  }, []);

  return (
    <Spin tip={'Signing out of accounts'}>
      <div className="SessionDestroy-container"></div>
    </Spin>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  resetSessionActionCreator: resetSession,
};

/** connect SessionDestroy to the redux store */
const ConnectedSessionDestroy = connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionDestroy);

export default ConnectedSessionDestroy;
