/* eslint-disable no-console */
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Spin,
} from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PatientManager from '../../api/patients/request';
import styles from './PatientForm.module.scss';

const { Option } = Select;
interface Props {
  patient?: any;
  patientName?: string;
  submitBtnLabel?: string;
  onPatientCreate?: any;
  shortForm?: boolean;
  showTitle?: boolean;
}

const PatientForm = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [formInitialValue, setFormInitialValue] = useState({});
  const isEdit = !!props.patient;
  useEffect(() => {
    const patientName = props.patientName?.split(';');
    if (patientName?.length) {
      form.setFieldsValue({
        name: patientName[0] || '',
        contact_no: patientName[1] ? patientName[1] : '',
      });
    }
  }, [props.patientName]);
  useEffect(() => {
    setFormInitialValue(props.patient ? { ...props.patient } : {});
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);
  const mapRequest = (request: any) => ({
    name: request.name,
    contact_no: request.contactNo,
    em_contact_no: request.em_contact_no,
    gender: request.gender,
    years: request.years,
    months: request.months,
    days: request.days,
    email: request.email,
    profession: request.profession,
    present_address: request.presentAddress,
    permanent_address: request.permanentAddress,
    area: request.area,
    photo: request.photo,
  });
  const onFinish = async (request: any) => {
    isEdit ? editPatient(request) : createNewPatient(request);
  };

  const createNewPatient = async (request: any) => {
    try {
      setLoading(true);
      const response: any = await PatientManager.create(mapRequest(request));
      message.success('Patient created successfully');
      props.onPatientCreate(response.data);

      form.resetFields();
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  const editPatient = async (request: any) => {
    try {
      setLoading(true);
      const response: any = await PatientManager.update({
        ...mapRequest(request),
        id: props.patient?.id,
      });
      if (response.data.success === 'patient_updated') {
        message.success('Patient updated successfully');
      }
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  return loading ? (
    <Spin />
  ) : (
    <div
      className={clsx(
        styles.FormWrapper,
        'bg-white form__content',
        props.shortForm && styles.ShortForm
      )}
    >
      <Row>
        <Col
          lg={props.shortForm ? 24 : 16}
          md={props.shortForm ? 24 : 16}
          sm={24}
          xs={24}
        >
          {!props.showTitle ? null : (
            <h2>{isEdit ? 'Edit Patient' : 'Create Patient'}</h2>
          )}
          <Form
            initialValues={formInitialValue}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="contactNo"
              label="Contact No"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            {!props.shortForm && (
              <Form.Item name="emContactNo" label="Emergency Contact No">
                <Input />
              </Form.Item>
            )}
            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="Male">Male</Radio>
                <Radio value="Female">Female</Radio>
                <Radio value="Others">Others</Radio>
              </Radio.Group>
            </Form.Item>
            <div className="ant-col ant-col-24 ant-form-item-label">
              <span className="ant-form-item-required">Age</span>
            </div>{' '}
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item name="years" label="">
                  <InputNumber style={{ width: '100%' }} placeholder="Years" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="months">
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Months"
                    defaultValue={0}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="days">
                  <InputNumber style={{ width: '100%' }} placeholder="Days" />
                </Form.Item>
              </Col>
            </Row>
            {!props.shortForm && (
              <>
                <Form.Item name="email" label="Email">
                  <Input />
                </Form.Item>
                <Form.Item name="profession" label="Profession">
                  <Input />
                </Form.Item>
                <Form.Item name="presentAddress" label="present address">
                  <Input />
                </Form.Item>

                <Form.Item name="permanentAddress" label="Permanent address">
                  <Input />
                </Form.Item>

                <Form.Item name="area" label="Area">
                  <Input />
                </Form.Item>

                <Form.Item name="photo" label="Photo">
                  <Input />
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {props.submitBtnLabel || 'Create'}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default PatientForm;
