import endpoints from '../../configs/endpoints';
import { Meta } from '../../interfaces/api';
import {
  ChamberDetailsInterface,
  ChamberInterface,
} from '../../interfaces/chamber';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import Mapper from './mapper';

interface ResponseInterface {
  data: Array<ChamberInterface> | ChamberInterface | null | any;
  meta?: Meta | null;
}

/**
 * Fetch all chambers for the current provider with pagination
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const all = async (request?: any): Promise<ResponseInterface> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_CHAMBERS_ENDPOINT.replace(':providerId', getProviderId()),
    request,
    true
  );
  return {
    meta: Mapper.meta(response.data.meta),
    data: Mapper.dataFromSTC(response.data.data),
  };
};

/**
 * Fetch a chamber by id
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const get = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_CHAMBER_SHOW_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':chamberId', request.id),
    request,
    true
  );
  return {
    data: Mapper.dataDetailsFromSTC(response.data.data),
  };
};

/**
 * create a new chamber
 * @param request the request body
 * @returns {Promise<ChamberDetailsInterface>}
 */
export const create = async (
  request: any
): Promise<{
  data: ChamberDetailsInterface;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_CHAMBERS_STORE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return {
    data: response.data.data,
  };
};

/**
 * create a new doctor
 * @param request the request body
 * @returns {Promise<ChamberDetailsInterface>} the updated doctor
 */
export const update = async (
  request: any
): Promise<{
  data: ChamberDetailsInterface;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_CHAMBER_UPDATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':chamberId', request.id),
    request,
    true
  );
  return {
    data: response.data,
  };
};

export const deactivate = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_CHAMBER_DEACTIVATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':chamberId', request.id),
    request,
    true
  );
  return {
    data: response.data,
  };
};

export const activate = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_CHAMBER_ACTIVATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':chamberId', request.id),
    request,
    true
  );
  return {
    data: response.data,
  };
};

const ChamberManager = {
  all,
  get,
  update,
  create,
  activate,
  deactivate,
};

export default ChamberManager;
