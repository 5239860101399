/* eslint-disable no-console */
import endpoints from '../../configs/endpoints';
import { Meta } from '../../interfaces/api';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import Mapper from './mapper';

/**
 * Fetch all templates for the current provider with pagination
 * @param request
 * @returns {Promise<*>}
 */
const all = async (
  request: any
): Promise<{
  data: Array<any>;
  meta: Meta;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_TEMPLATES_ENDPOINT.replace(':providerId', getProviderId()),
    request,
    true
  );
  return {
    data: Mapper.dataFromSTC(response.data.data),
    meta: Mapper.meta(response.data.meta),
  };
};

/**
 * create an appointment
 * @param request
 * @returns {Promise<any>}
 */
const create = async (request: any): Promise<{ data: any }> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_TEMPLATES_STORE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return {
    data: Mapper.dataItemFromSTC(response.data.data),
  };
};

export const get = async (request: any): Promise<any> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_TEMPLATE_SHOW_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':templateId', request.id),
    {},
    true
  );
  const output = {
    data: response.data.data,
  };
  return output;
};

export const update = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_TEMPLATE_UPDATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':templateId', request.id),
    request,
    true
  );
  return {
    data: response,
  };
};

const fetchEvents = async (
  request?: any
): Promise<{
  data: Array<any>;
  meta: Meta;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_TEMPLATES_ENDPOINT.replace(':providerId', getProviderId()),
    request,
    true
  );
  return response.data.meta.appointment.event;
};

export const test = async (request: any): Promise<any> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_TEMPLATE_TEST_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':templateId', request.id),
    {
      doctor_name: request.doctor_name,
      patient_name: request.patient_name,
      appointment_at: request.appointment_at,
    },
    true
  );
  const output = {
    data: response.data,
  };
  return output;
};

export const remove = async (request: any): Promise<any> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_TEMPLATE_DELETE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':templateId', request.id),
    {
      _method: 'DELETE',
    },
    true
  );
  const output = {
    data: response.data,
  };
  return output;
};

const TemplateManager = {
  all,
  create,
  get,
  update,
  fetchEvents,
  test,
  remove,
};

export default TemplateManager;
