import moment from 'moment';
import { getYMD } from '../../services/utility';

export const dataItemFromSTC = (dataItem: any): any => {
  const age = getYMD(moment(dataItem.dob), moment());
  return {
    name: dataItem.name,
    contactNo: dataItem.contact_no,
    age: dataItem.age,
    photo: dataItem.photo,
    patientGender: dataItem.gender,
    id: dataItem.id,
    bloodGroup: dataItem.blood_group,
    gender: dataItem.gender,
    profession: dataItem.profession,
    years: age.years,
    months: age.months,
    days: age.days,
    detailsLink: `/patients/${dataItem.id}`,
    editLink: `/patients/${dataItem.id}/edit`,
  };
};

export const dataFromSTC = (data: any[]): any[] => {
  return data.map(dataItemFromSTC);
};

export const dataDetailsItemFromSTC = (dataItem: any): any => {
  return {
    ...dataItemFromSTC(dataItem),
  };
};

export const dataDetailsFromSTC = (data: any[]): any[] =>
  data.map(dataDetailsItemFromSTC);

const meta = (meta: any) => ({
  currentPage: meta.current_page,
  page: meta.page,
  perPage: meta.per_page,
  total: meta.total,
});

const Mapper = {
  dataFromSTC,
  dataItemFromSTC,
  dataDetailsFromSTC,
  dataDetailsItemFromSTC,
  meta,
};

export default Mapper;
