import { PageContainer, ProLayout } from '@ant-design/pro-components';
import React from 'react';
import HeaderContent from '../headerContent/HeaderContent';
import { GithubOutlined } from '@ant-design/icons';
import Layout from '../layout/Layout';

const DashboardContent = () => (
  <div className="dashboard__contents">Dashboard</div>
);

export default DashboardContent;
