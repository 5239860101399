import { Avatar, Col, Divider, Row, Tag } from 'antd';
import React from 'react';
import PaymentDetails from '../../api/appointments/paymentDetails/PaymentDetails';

interface Props {
  appointment: any;
  onPaymentSuccess?: (payload: any) => void;
}
export default function AppointmentDetails(props: Props) {
  const { appointment } = props;
  const onPaymentSuccess = (payload: any) => {
    props.onPaymentSuccess ? props.onPaymentSuccess(payload) : '';
  };
  return (
    <div className="mx-50">
      <Row gutter={20} wrap={false}>
        <Col>
          <Avatar
            className="icon-svg"
            size={64}
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M352 128c0 70.7-57.3 128-128.9 128-69.8 0-128-57.3-128-128 0-70.69 58.2-128 128-128C294.7 0 352 57.31 352 128zm-64.9 234c-26.7 7.1-48 32.2-48 62v24c0 4.2 2.6 8.3 5.6 11.3l16 16c6.2 6.3 16.4 6.3 22.6 0 6.3-6.2 6.3-16.4 0-22.6l-12.2-11.3V424c0-17.7 15.2-32 32-32 18.6 0 32.9 14.3 32.9 32v17.4l-11.3 11.3c-6.3 6.2-6.3 16.4 0 22.6 6.2 6.3 16.4 6.3 22.6 0l16-16c3-3 4.7-7.1 4.7-11.3v-24c0-29.8-20.4-54.9-48-62v-53.2c73.5 17.9 128 83.3 128 163.2v8c0 17.7-14.3 32-32 32H32c-17.67 0-32-14.3-32-32v-8c0-79 54.53-145.3 128-163.2v61.5c-23.1 6.9-40 28.3-40 53.7 0 30.9 25.1 56 56 56s56-25.1 56-56c0-25.4-16.9-46.8-40-53.7v-66.1c2.7-.1 5.3-.2 8-.2h112c2.7 0 5.3.1 8 .2l-.9 57.8zm-120 62c0 13.3-9.8 24-24 24-12.4 0-24-10.7-24-24s11.6-24 24-24c14.2 0 24 10.7 24 24z" />
              </svg>
            }
          />
        </Col>
        <Col>
          <h3 className="m-0">{appointment.doctorName}</h3>
          <span className="clr-gray fw-400 fs-13">
            {appointment.doctorSpecialities}
          </span>
        </Col>
      </Row>
      <Row gutter={60} className="mt-20">
        <Col>
          <span className="fs-14 clr-gray">Date:</span>
          <br />
          <span className="fs-16">{appointment.appointmentDate}</span>
        </Col>
        <Col>
          <span className="fs-14 clr-gray">Time:</span>
          <br />
          <span className="fs-16">{appointment.appointmentTime}</span>
        </Col>
        <Col>
          <span className="fs-14 clr-gray">Status:</span>
          <br />
          <Tag color={appointment.appointmentStatusColor}>
            {appointment.appointmentStatus}
          </Tag>
        </Col>
      </Row>
      <Divider />
      <h3>CHAMBER</h3>
      <Row gutter={20} wrap={false}>
        <Col>
          <Avatar
            className="icon-svg"
            size={64}
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path d="M192 48c0-26.51 21.5-48 48-48h160c26.5 0 48 21.49 48 48v464h-80v-80c0-26.5-21.5-48-48-48s-48 21.5-48 48v80h-80V48zm120 16c-8.8 0-16 7.16-16 16v24h-24c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h24v24c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-24h24c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16h-24V80c0-8.84-7.2-16-16-16h-16zM160 96v416H48c-26.51 0-48-21.5-48-48V320h80c8.84 0 16-7.2 16-16s-7.16-16-16-16H0v-64h80c8.84 0 16-7.2 16-16s-7.16-16-16-16H0v-48c0-26.5 21.49-48 48-48h112zm432 0c26.5 0 48 21.5 48 48v48h-80c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v64h-80c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v144c0 26.5-21.5 48-48 48H480V96h112z" />
              </svg>
            }
          />
        </Col>
        <Col>
          <h4>{appointment.chamberName}</h4>
          <span className="fs-14 clr-gray">{appointment.chamberAddress}</span>
        </Col>
      </Row>
      <Divider />
      <h3>PATIENT</h3>
      <Row gutter={20} wrap={false}>
        <Col>
          <Avatar
            className="icon-svg"
            size={64}
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0 96 57.31 96 128s57.3 128 128 128zm50.7 48H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3c0-95.7-77.6-173.3-173.3-173.3z" />
              </svg>
            }
          />
        </Col>
        <Col>
          <h4>{appointment.patientName}</h4>
          <span className="fs-14 clr-gray">{appointment.patientContactNo}</span>
        </Col>
      </Row>
      <Row gutter={60} className="mt-20">
        <Col>
          <span className="fs-14 clr-gray">Age:</span>
          <br />
          <span className="fs-16">{appointment.patientAge}</span>
        </Col>
        <Col>
          <span className="fs-14 clr-gray">Gender:</span>
          <br />
          <span className="fs-16">{appointment.patientGender}</span>
        </Col>
        <Col>
          <span className="fs-14 clr-gray">Age:</span>
          <br />
          <span className="fs-16">{appointment.patientAge}</span>
        </Col>
      </Row>
      <Divider />
      <PaymentDetails
        onPaymentSuccess={onPaymentSuccess}
        appointment={appointment}
      />
    </div>
  );
}
