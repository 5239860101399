import { Avatar } from 'antd';
import React from 'react';
import ShortCard from '../shortCard/ShortCard';

interface Props {
  person: {
    name: string;
    photo: string;
    contactNo: string;
    age: string;
  };
}

function ShortPatientDetails({ person }: Props) {
  const { name, photo, contactNo, age } = person;
  return (
    <ShortCard
      photo={<Avatar src={photo} size={50} />}
      title={<span>{name} </span>}
      description={
        <span>
          <span style={{ color: '#959595', fontSize: '11px' }}>{age}</span>
          <br />
          <span style={{ color: '#000', fontSize: '11px' }}>{contactNo}</span>
        </span>
      }
    />
  );
}

export default ShortPatientDetails;
