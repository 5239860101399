export const getPatientBaseMapper = (patient: any) => ({
  patientName: patient.name,
  patientContactNo: patient.contact_no,
  patientAge: patient.age,
  patientPhoto: patient.photo,
  patientGender: patient.gender,
});

export const getDoctorBaseMapper = (doctor: any) => ({
  doctorName: doctor.full_name,
  doctorContactNo: doctor.contact_no,
  doctorPhoto: doctor.photo,
  doctorSpecialties: doctor.specialties,
});

export const getChamberBaseMapper = (chamber: any) => ({
  chamberName: chamber.name,
  chamberContactNo: chamber.contact_no,
  chamberAddress: chamber.address,
});
