import React from 'react';
import AppointmentList from '../components/appointmentList/AppointmentList';
import Layout from '../components/layout/Layout';

const Appointments = () => (
  <Layout>
    <AppointmentList />
  </Layout>
);
export default Appointments;
