export const specialties = [
  'Cardiology',
  'Dentistry',
  'Dermatology',
  'Endocrinology',
  'Gastroenterology',
  'General Medicine',
  'Hematology',
];

export const templateTypes = [
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'app_notification',
    label: 'App notification',
  },
  {
    id: 'sms',
    label: 'Sms',
  },
];
