import { Layout } from 'antd';
import React from 'react';

const Report = () => (
  <Layout>
    <h1>Report</h1>
  </Layout>
);

export default Report;
