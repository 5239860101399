/** local */

/** local login endpoint */
export const LOCAL_LOGIN_ENDPOINT = `/login`;

/** local register endpoint */
export const LOCAL_REGISTER_ENDPOINT = `/register`;

/** local forgot password endpoint */
export const LOCAL_FORGOT_PASSWORD_ENDPOINT = `/forgot-password`;

/** local reset password endpoint */
export const LOCAL_RESET_PASSWORD_ENDPOINT = `/reset-password`;

/** local logout endpoint */
export const LOCAL_LOGOUT_ENDPOINT = `/logout`;

/** local logout endpoint */
export const LOCAL_AUTHORIZE_ENDPOINT = `/authorize`;

/** local verify email endpoint */
export const LOCAL_VERIFY_EMAIL_ENDPOINT = `/email/verify/:id/:hash`;

/** local personal info endpoint */
export const LOCAL_PERSONAL_INFO_ENDPOINT = `/account`;

export const LOCAL_DASHBOARD_ENDPOINT = `/dashboard`;

export const LOCAL_APPOINTMENTS_ENDPOINT = `/appointments`;
export const LOCAL_APPOINTMENTS_DETAILS_ENDPOINT = `/appointments/:id`;
export const LOCAL_APPOINTMENTS_CREATE_ENDPOINT = `/appointments/create`;
export const LOCAL_APPOINTMENTS_EDIT_ENDPOINT = `/appointments/:id/edit`;

export const LOCAL_DOCTORS_ENDPOINT = `/doctors`;
export const LOCAL_DOCTORS_DETAILS_ENDPOINT = `/doctors/:id`;
export const LOCAL_DOCTORS_CREATE_ENDPOINT = `/doctors/create`;
export const LOCAL_DOCTORS_EDIT_ENDPOINT = `/doctors/:id/edit`;

export const LOCAL_PATIENTS_ENDPOINT = `/patients`;
export const LOCAL_PATIENTS_DETAILS_ENDPOINT = `/patients/:id`;
export const LOCAL_PATIENTS_CREATE_ENDPOINT = `/patients/create`;
export const LOCAL_PATIENTS_EDIT_ENDPOINT = `/patients/:id/edit`;

export const LOCAL_CHAMBERS_ENDPOINT = `/chambers`;
export const LOCAL_CHAMBERS_DETAILS_ENDPOINT = `/chambers/:id`;
export const LOCAL_CHAMBERS_CREATE_ENDPOINT = `/chambers/create`;
export const LOCAL_CHAMBERS_EDIT_ENDPOINT = `/chambers/:id/edit`;

export const LOCAL_SCHEDULES_ENDPOINT = `/schedules`;
export const LOCAL_SCHEDULES_DETAILS_ENDPOINT = `/schedules/:id`;
export const LOCAL_SCHEDULES_CREATE_ENDPOINT = `/schedules/create`;
export const LOCAL_SCHEDULES_EDIT_ENDPOINT = `/schedules/:id/edit`;

export const LOCAL_USERS_ENDPOINT = `/users`;
export const LOCAL_USERS_DETAILS_ENDPOINT = `/users/:id`;
export const LOCAL_USERS_CREATE_ENDPOINT = `/users/create`;
export const LOCAL_USERS_EDIT_ENDPOINT = `/users/:id/edit`;

export const LOCAL_TEMPLATES_ENDPOINT = `/templates`;
export const LOCAL_TEMPLATES_DETAILS_ENDPOINT = `/templates/:id`;
export const LOCAL_TEMPLATES_CREATE_ENDPOINT = `/templates/create`;
export const LOCAL_TEMPLATES_EDIT_ENDPOINT = `/templates/:id/update`;

export const LOCAL_REPORTS_ENDPOINT = `/reports`;
export const LOCAL_TRANSACTIONS_ENDPOINT = `/transactions`;

/** server */

/** server register endpoint */
export const SERVER_REGISTER_ENDPOINT =
  process.env.REACT_APP_REGISTER_USER_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/signup`;

/** server login endpoint */
export const SERVER_LOGIN_ENDPOINT =
  process.env.REACT_APP_LOGIN_USER_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/oauth/token`;

/** server login endpoint */
export const SERVER_LOGOUT_ENDPOINT =
  process.env.REACT_APP_LOGOUT_USER_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/logout`;

/** server forgot password endpoint */
export const SERVER_FORGOT_PASSWORD_ENDPOINT =
  process.env.REACT_APP_FORGOT_PASSWORD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/forgot-password`;

/** server reset password endpoint */
export const SERVER_RESET_PASSWORD_ENDPOINT =
  process.env.REACT_APP_RESET_PASSWORD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/reset-password`;

/** server change password endpoint */
export const SERVER_CHANGE_PASSWORD_ENDPOINT =
  process.env.REACT_APP_CHANGE_PASSWORD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/change-password`;

/** server profile endpoint */
export const SERVER_USER_PROFILE_ENDPOINT =
  process.env.REACT_APP_USER_PROFILE_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/profile/basic`;

/** server email verify endpoint */
export const SERVER_EMAIL_VERIFY_ENDPOINT =
  process.env.REACT_APP_EMAIL_VERIFY_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/email/verify`;

/** server send email endpoint */
export const SERVER_SEND_EMAIL_ENDPOINT =
  process.env.REACT_APP_SEND_EMAIL_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/email/verification-notification`;

/** server avatar endpoint */
export const SERVER_AVATAR_ENDPOINT =
  process.env.REACT_APP_AVATAR_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/profile/change-avatar`;

export const SERVER_MY_PRIVIDER_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/my-providers`;

/** server send email endpoint */
export const SERVER_APPLICATIONS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/appointments`;
export const SERVER_APPOINTMENT_DETAILS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/appointments/:id/details`;
export const SERVER_APPLICATION_STORE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/appointments/store`;
export const SERVER_APPOINTMENT_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/appointments/:appointmentId/update`;
export const SERVER_APPOINTMENT_STATUS_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/appointments/:appointmentId/status-change`;
export const SERVER_APPOINTMENT_PAYMENT_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/payments`;

export const SERVER_DOCTORS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/doctors`;
export const SERVER_DOCTOR_SHOW_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/doctors/:doctorId`;
export const SERVER_DOCTORS_STORE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/doctors`;
export const SERVER_DOCTORS_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/doctors/:doctorId`;
export const SERVER_DOCTOR_DEACTIVATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/doctors/:doctorId/deactivate`;
export const SERVER_DOCTOR_ACTIVATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/doctors/:doctorId/activate`;

export const SERVER_PATIENTS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/patients`;
export const SERVER_PATIENTS_STORE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/patients`;
export const SERVER_PATIENT_SHOW_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/patients/:patientId`;
export const SERVER_PATIENT_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/patients/:patientId`;
export const SERVER_PATIENT_DELETE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/patients/:patientId`;

export const SERVER_CHAMBERS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/chambers`;
export const SERVER_CHAMBERS_STORE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/chambers`;
export const SERVER_CHAMBER_SHOW_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/chambers/:chamberId`;
export const SERVER_CHAMBER_DEACTIVATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/chambers/:chamberId/deactivate`;
export const SERVER_CHAMBER_ACTIVATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/chambers/:chamberId/activate`;
export const SERVER_CHAMBER_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/chambers/:chamberId`;
export const SERVER_CONSULTANT_SCHEDULE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/doctors/:doctorId/schedules`;

export const SERVER_SCHEDULES_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/schedules`;
export const SERVER_SCHEDULES_STORE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/schedules`;
export const SERVER_SCHEDULE_SHOW_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/schedules/:scheduleId`;
export const SERVER_SCHEDULE_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/schedules/:scheduleId`;

export const SERVER_USERS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/users`;
export const SERVER_USERS_STORE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/users`;
export const SERVER_USER_SHOW_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/users/:userId`;
export const SERVER_USER_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/users/:userId`;
export const SERVER_USER_DEATIVATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/users/:userId/deactivate`;
export const SERVER_USER_ATIVATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/users/:userId/activate`;

// template
export const SERVER_TEMPLATES_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/templates`;
export const SERVER_TEMPLATES_STORE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/templates`;
export const SERVER_TEMPLATE_SHOW_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/templates/:templateId`;
export const SERVER_TEMPLATE_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/templates/:templateId`;
export const SERVER_TEMPLATE_DELETE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/templates/:templateId`;
export const SERVER_TEMPLATE_TEST_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/templates/:templateId/test`;

export const SERVER_TRANSATIONS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/:providerId/transactions`;

/** auth endpoints */

/** google auth endpoint */
export const SERVER_GOOGLE_AUTH_LINK =
  process.env.REACT_APP_GOOGLE_AUTH_LINK ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login/google`;

/** facebook auth endpoint */
export const SERVER_FACEBOOK_AUTH_LINK =
  process.env.REACT_APP_FACEBOOK_AUTH_LINK ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login/facebook`;

/** apple auth endpoint */
export const SERVER_APPLE_AUTH_LINK =
  process.env.REACT_APP_APPLE_AUTH_LINK ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login/apple`;

/** apps */

/** Todo app endpoint */
export const TODO_APP_ENDPOINT = process.env.REACT_APP_TODO_ENDPOINT || '';

/** Tips app endpoint */
export const TIPS_APP_ENDPOINT = process.env.REACT_APP_TIPS_ENDPOINT || '';

/** sample app authorize endpoint */
export const APP_AUTHORIZE_ENDPOINT =
  process.env.REACT_APP_AUTHORIZE_ENDPOINT || '';

/** external */
export const BLOG_ENDPOINT = process.env.REACT_APP_BLOG_ENDPOINT || '';
export const TERMS_OF_SERVICE_ENDPOINT =
  process.env.REACT_APP_TERMS_OF_SERVICE_ENDPOINT ||
  'https://virtunus.com/terms-of-service/';
export const PRIVACY_POLICY_ENDPOINT =
  process.env.REACT_APP_PRIVACY_POLICY_ENDPOINT ||
  'https://virtunus.com/privacy-policy/';
export const VIRTUNUS_HOME_ENDPOINT =
  process.env.REACT_APP_VIRTUNUS_HOME_ENDPOINT || 'https://tips.virtunus.com/';

const endpoints = {
  LOCAL_REGISTER_ENDPOINT,
  LOCAL_LOGIN_ENDPOINT,
  LOCAL_FORGOT_PASSWORD_ENDPOINT,
  LOCAL_RESET_PASSWORD_ENDPOINT,
  LOCAL_LOGOUT_ENDPOINT,
  LOCAL_AUTHORIZE_ENDPOINT,
  LOCAL_VERIFY_EMAIL_ENDPOINT,
  LOCAL_PERSONAL_INFO_ENDPOINT,
  LOCAL_DASHBOARD_ENDPOINT,
  LOCAL_DOCTORS_ENDPOINT,
  LOCAL_DOCTORS_DETAILS_ENDPOINT,
  LOCAL_DOCTORS_CREATE_ENDPOINT,
  LOCAL_DOCTORS_EDIT_ENDPOINT,
  LOCAL_PATIENTS_ENDPOINT,
  LOCAL_PATIENTS_DETAILS_ENDPOINT,
  LOCAL_PATIENTS_CREATE_ENDPOINT,
  LOCAL_PATIENTS_EDIT_ENDPOINT,
  LOCAL_CHAMBERS_ENDPOINT,
  LOCAL_CHAMBERS_DETAILS_ENDPOINT,
  LOCAL_CHAMBERS_CREATE_ENDPOINT,
  LOCAL_CHAMBERS_EDIT_ENDPOINT,
  LOCAL_APPOINTMENTS_ENDPOINT,
  LOCAL_APPOINTMENTS_DETAILS_ENDPOINT,
  LOCAL_APPOINTMENTS_CREATE_ENDPOINT,
  LOCAL_APPOINTMENTS_EDIT_ENDPOINT,
  SERVER_APPOINTMENT_PAYMENT_ENDPOINT,
  LOCAL_REPORTS_ENDPOINT,
  LOCAL_SCHEDULES_ENDPOINT,
  LOCAL_SCHEDULES_DETAILS_ENDPOINT,
  LOCAL_SCHEDULES_CREATE_ENDPOINT,
  LOCAL_SCHEDULES_EDIT_ENDPOINT,
  LOCAL_USERS_ENDPOINT,
  LOCAL_USERS_DETAILS_ENDPOINT,
  LOCAL_USERS_CREATE_ENDPOINT,
  LOCAL_USERS_EDIT_ENDPOINT,
  LOCAL_TEMPLATES_ENDPOINT,
  LOCAL_TEMPLATES_DETAILS_ENDPOINT,
  LOCAL_TEMPLATES_CREATE_ENDPOINT,
  LOCAL_TEMPLATES_EDIT_ENDPOINT,
  LOCAL_TRANSACTIONS_ENDPOINT,
  SERVER_REGISTER_ENDPOINT,
  SERVER_LOGIN_ENDPOINT,
  SERVER_LOGOUT_ENDPOINT,
  SERVER_FORGOT_PASSWORD_ENDPOINT,
  SERVER_RESET_PASSWORD_ENDPOINT,
  SERVER_CHANGE_PASSWORD_ENDPOINT,
  SERVER_USER_PROFILE_ENDPOINT,
  SERVER_EMAIL_VERIFY_ENDPOINT,
  SERVER_SEND_EMAIL_ENDPOINT,
  SERVER_AVATAR_ENDPOINT,
  SERVER_GOOGLE_AUTH_LINK,
  SERVER_FACEBOOK_AUTH_LINK,
  SERVER_APPLE_AUTH_LINK,
  TODO_APP_ENDPOINT,
  TIPS_APP_ENDPOINT,
  APP_AUTHORIZE_ENDPOINT,
  BLOG_ENDPOINT,
  TERMS_OF_SERVICE_ENDPOINT,
  PRIVACY_POLICY_ENDPOINT,
  VIRTUNUS_HOME_ENDPOINT,
  SERVER_APPLICATIONS_ENDPOINT,
  SERVER_APPLICATION_STORE_ENDPOINT,
  SERVER_DOCTORS_ENDPOINT,
  SERVER_MY_PRIVIDER_ENDPOINT,
  SERVER_PATIENTS_ENDPOINT,
  SERVER_PATIENTS_STORE_ENDPOINT,
  SERVER_PATIENT_SHOW_ENDPOINT,
  SERVER_PATIENT_UPDATE_ENDPOINT,
  SERVER_PATIENT_DELETE_ENDPOINT,
  SERVER_DOCTOR_SHOW_ENDPOINT,
  SERVER_DOCTORS_STORE_ENDPOINT,
  SERVER_DOCTORS_UPDATE_ENDPOINT,
  SERVER_DOCTOR_DEACTIVATE_ENDPOINT,
  SERVER_DOCTOR_ACTIVATE_ENDPOINT,
  SERVER_CHAMBERS_ENDPOINT,
  SERVER_CHAMBERS_STORE_ENDPOINT,
  SERVER_CHAMBER_SHOW_ENDPOINT,
  SERVER_CHAMBER_UPDATE_ENDPOINT,
  SERVER_CHAMBER_DEACTIVATE_ENDPOINT,
  SERVER_CHAMBER_ACTIVATE_ENDPOINT,
  SERVER_SCHEDULES_ENDPOINT,
  SERVER_SCHEDULES_STORE_ENDPOINT,
  SERVER_SCHEDULE_SHOW_ENDPOINT,
  SERVER_SCHEDULE_UPDATE_ENDPOINT,
  SERVER_CONSULTANT_SCHEDULE_ENDPOINT,
  SERVER_APPOINTMENT_DETAILS_ENDPOINT,
  SERVER_APPOINTMENT_UPDATE_ENDPOINT,
  SERVER_APPOINTMENT_STATUS_UPDATE_ENDPOINT,
  SERVER_USERS_ENDPOINT,
  SERVER_USERS_STORE_ENDPOINT,
  SERVER_USER_SHOW_ENDPOINT,
  SERVER_USER_UPDATE_ENDPOINT,
  SERVER_USER_DEATIVATE_ENDPOINT,
  SERVER_USER_ATIVATE_ENDPOINT,
  SERVER_TEMPLATES_ENDPOINT,
  SERVER_TEMPLATES_STORE_ENDPOINT,
  SERVER_TEMPLATE_SHOW_ENDPOINT,
  SERVER_TEMPLATE_UPDATE_ENDPOINT,
  SERVER_TEMPLATE_DELETE_ENDPOINT,
  SERVER_TEMPLATE_TEST_ENDPOINT,
  SERVER_TRANSATIONS_ENDPOINT,
};

export default endpoints;
