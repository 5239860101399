/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-console */
import { LayoutOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppointmentManager from '../../api/appointments/request';
import ChamberManager from '../../api/chambers/request';
import DoctorManager from '../../api/doctors/request';
import PatientManager from '../../api/patients/request';
import { getConsultantSchedule } from '../../api/schedules/request';
import { DATE_FORMAT } from '../../configs/constants';
import { ChamberInterface } from '../../interfaces/chamber';
import { DoctorInterface } from '../../interfaces/doctor';
import AppointmentDetails from '../appointmentDetails/AppointmentDetails';
import DropdownCard from '../dropdownCard/DropdownCard';
import PatientContentForm from '../patientForm/PatientForm';
import styles from './AppointmentForm.module.scss';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const weekDays = [
  'saturday',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
];

interface AppointmentFormProps {
  appointment?: any;
}

const AppointmentForm: React.FC<AppointmentFormProps> = (
  props: AppointmentFormProps
) => {
  const [form] = Form.useForm();
  const [doctors, setDoctors] = useState<Array<DoctorInterface>>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<DoctorInterface | null>(
    null
  );
  const doctorNameInput = useRef<any>(null);
  const chamberInput = useRef<any>(null);
  const [selectedChamber, setSelectedChamber] = useState<any>(null);
  const [chambers, setChambers] = useState<Array<ChamberInterface>>([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [patients, setPatients] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [patientNameOnType, setPatientNameOnType] = useState('');
  const navigate = useNavigate();
  const [schedules, setSchedules] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState<any>(null);
  const [selectedSession, setSelectedSession] = useState<any>(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [detailsDrawer, setDetailsDrawer] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<any>({
    consultation_type: '1',
    appointment_type: '1',
  });
  const appointment = props.appointment;

  useEffect(() => {
    fetchDoctors();
    doctorNameInput?.current?.focus();
  }, []);

  /**
   * Fetch Doctors
   */
  const fetchDoctors = async () => {
    try {
      const response = await DoctorManager.all();
      setDoctors(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Fetch Doctors based on search
   */

  const onChangeDoctor = async (value: any) => {
    const doctor: any = doctors.find(
      (doctor: DoctorInterface) => doctor.id === value
    );
    if (doctor) {
      const chambers: any = await fetchChambers({
        doctor_id: doctor.id,
      });
      if (chambers.length > 0) {
        const chamber = chambers[0];
        form.setFieldsValue({
          chamber_id: chamber.id,
        });
        selectChamber(chamber);
      }
      setChambers(chambers);
      setSelectedDoctor(doctor);
    }
  };

  const fetchChambers = async (request: any) => {
    try {
      const response = await ChamberManager.all(request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  /**
   * Create or update appointment
   */

  const onFinish = async (values: any) => {
    createNewAppointment(values);
  };

  /**
   * Create new appointment
   */

  const createNewAppointment = async (request: any) => {
    try {
      setLoading(true);
      const start_at = `${moment(request.appointment_date).format(
        DATE_FORMAT
      )} ${moment(request.start_time, 'hh:mm a').format('HH:mm')}`;
      const end_at = `${moment(request.appointment_date).format(
        DATE_FORMAT
      )} ${moment(request.end_time, 'hh:mm a').format('HH:mm')}`;
      const response: any = await AppointmentManager.create({
        appointment: {
          doctor_id: request.doctor_id,
          chamber_id: request.chamber_id,
          schedule_id: request.schedule_id,
          start_at: start_at,
          end_at: end_at,
          appointment_type: request.appointment_type,
          consultation_type: request.consultation_type,
          complaint: request.complaint || '',
        },
        patient_id: request.patient_id,
      });
      message.success('Appointment created successfully');
      setLoading(false);
      setAppointmentDetails(response.data);
      setDetailsDrawer(true);
      setSessions([]);
      form.resetFields();
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.message);
      setLoading(false);
    }
  };
  /**
   * Reset Form
   */

  const onReset = () => {
    setSessions([]);
    form.resetFields();
  };

  /**
   * Fetch schedules based on date
   */

  const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    fetchSchedules({});
  };

  const selectChamber = (chamber: any) => {
    setSelectedChamber(chamber);
    let sessionsArr: any = [];
    setSchedules(chamber?.schedules);
    chamber?.schedules.map((schedule: any) => {
      if (schedule.sessions.length) {
        const newSessions = schedule.sessions.map((newSession: any) => ({
          ...newSession,
          scheduleId: schedule.id,
        }));
        sessionsArr = [...sessionsArr, ...newSessions];
      }
    });
    setSessions(sessionsArr);
  };
  /**
   * Set selected chamber
   */

  const onChamberChange = (chamberId: any) => {
    const chamber = chambers.find((itr: any) => itr.id === chamberId);
    selectChamber(chamber);
  };

  /**
   * Check slot availability and fetch if exists
   */

  const fetchSchedules = async (request: any) => {
    const consultationType = form.getFieldValue('consultation_type');

    const params = {
      doctor_id: selectedDoctor?.id,
      chamber_id: form.getFieldValue('chamber_id'),
      video_consultation: consultationType == 2 ? 1 : 0,
      session_time: selectedSession?.duration || null,
      schedule_id: selectedSchedule?.id,
      physical_consultation: consultationType == 1 ? 1 : 0,
      start_date: moment(form.getFieldValue('appointment_date')).format(
        DATE_FORMAT
      ),
      end_date: moment(form.getFieldValue('appointment_date')).format(
        DATE_FORMAT
      ),
    };

    try {
      const response = await getConsultantSchedule({ ...params, ...request });
      setTimeSlots(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const isDoctorSelected = !form.getFieldValue('doctor_id');
  const isChamberSelected = !selectedChamber;

  const onTimeSlotChange = (index: number) => {
    const slot: any = timeSlots[index];
    form.setFieldsValue({
      schedule_id: slot.schedule_id,
      start_time: slot.start_time,
      end_time: slot.end_time,
    });
  };
  const scheduleId = form.getFieldValue('schedule_id');
  const startTime = form.getFieldValue('start_time');
  const endTime = form.getFieldValue('end_time');
  const patientYears = form.getFieldValue('years');
  const patientMonths = form.getFieldValue('months');
  const patientDays = form.getFieldValue('days');
  let timeout: any = null;
  const onPatientNameChange = async (value: any) => {
    if (patientNameOnType) {
      setPatientNameOnType('');
    }
    setPatientNameOnType(value);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(async () => {
      const response = await PatientManager.all({
        q: value,
      });
      if (response.data && response.data.length > 0) {
        setPatients(response.data);
      }
    }, 500);
  };

  const onDoctorSearch = async (value: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(async () => {
      const response = await DoctorManager.all({
        q: value,
      });
      if (response.data && response.data.length > 0) {
        setDoctors(response.data);
      }
    }, 500);
  };

  const disabledDate = (current: moment.Moment) => {
    if (current.isBefore(moment(), 'day')) {
      return true;
    }
    const days = selectedChamber.schedules
      .reduce(
        (acc: any, schedule: any) => [...acc, ...schedule.working_days],
        []
      )
      .map((day: string) => day.toLocaleLowerCase());
    const selectedDay = weekDays[current.day()];

    const checkDayExists = days.indexOf(selectedDay);
    return checkDayExists > -1 || checkDayExists === 0 ? false : true;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const preventDefaultOnPatientName = (ev: any) => {
    ev.preventDefault();
    setPatientNameOnType(ev.target.value);
  };

  const onPatientCreate = (patient: any) => {
    if (patient) {
      setPatients([...patients, patient]);
      form.setFieldsValue({
        patient_id: patient.id,
      });
    }
    handleCancel();
  };

  const onSessionChange = (index: any) => {
    const selectedSessionObj: any = sessions[index];
    setSelectedSession(selectedSessionObj);
    const selectedScheduleObj: any = schedules.find(
      (schedule: any) => schedule.id === selectedSessionObj.scheduleId
    );
    setSelectedSchedule(selectedScheduleObj);
    fetchSchedules({ session_time: selectedSessionObj.duration });
  };
  return (
    <>
      <div className={styles.FormWrapper + ' bg-white form__content'}>
        <h2>Create Appointment</h2>
        <Row gutter={60}>
          <Col xxl={16} lg={24} md={24} sm={24} xs={24}>
            <Form
              {...layout}
              initialValues={initialFormValues}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Row gutter={20}>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="doctor_id"
                    label="Doctor Name"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      onChange={(value) => onChangeDoctor(value)}
                      placeholder="Select a option and change input text above"
                      allowClear
                      defaultValue={appointment ? appointment.doctor_id : ''}
                      onSearch={onDoctorSearch}
                      showArrow={false}
                      filterOption={false}
                      notFoundContent={null}
                    >
                      {doctors.map((doctor: any, index: number) => (
                        <Option key={index} value={doctor.id}>
                          <DropdownCard
                            item={{
                              photo: (
                                <Avatar
                                  shape="square"
                                  size={67}
                                  src={doctor.photo}
                                  icon={<UserOutlined />}
                                />
                              ),
                              title: doctor.name,
                              description: doctor.specialties,
                            }}
                          />
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="chamber_id"
                    label="Chamber"
                    rules={[{ required: true }]}
                  >
                    <Select
                      dropdownClassName="dorpdown-has-card"
                      ref={chamberInput}
                      disabled={isDoctorSelected}
                      placeholder="Select a option and change input text above"
                      allowClear
                      defaultValue={appointment ? appointment.chamber_id : ''}
                      onChange={onChamberChange}
                    >
                      {chambers.map((chamber: any, index: number) => (
                        <Option key={index} value={chamber.id}>
                          <DropdownCard
                            item={{
                              photo: (
                                <Avatar
                                  size={67}
                                  shape="square"
                                  icon={<LayoutOutlined />}
                                />
                              ),
                              title: chamber.name,
                              description: chamber.allFees,
                            }}
                          />
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="appointment_date"
                    label="Appointment Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      picker={'date'}
                      placeholder={'Select Date'}
                      onChange={onDateChange}
                      disabled={isChamberSelected}
                      disabledDate={disabledDate}
                      dropdownClassName={[
                        'disable-arrow1',
                        'disable-arrow3',
                        'disable-arrow4',
                      ].join(' ')}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="session"
                    label="Session"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={onSessionChange}
                      disabled={sessions.length < 1}
                    >
                      {sessions.map((session: any, index: number) => (
                        <Select.Option value={index} key={index}>
                          Duration: {session.duration} - Fee: {session.fee}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="consultation_type"
                    label="Consultation Type"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select a option and change input text above"
                      onChange={() => fetchSchedules({})}
                    >
                      <Option value="1">Physical Consultation</Option>
                      <Option value="2">Video Consultation</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="time_slot "
                    label="Time Slot"
                    rules={[{ required: true }]}
                  >
                    <Select
                      // disabled={isTimeSlotExists}
                      placeholder="Select a option and change input text above"
                      onChange={onTimeSlotChange}
                      allowClear
                      defaultValue={appointment ? appointment.chamber_id : ''}
                    >
                      {timeSlots.map((slot: any, index: number) => (
                        <Option key={index} value={index}>
                          {slot.start_time}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>{' '}
              </Row>
              <Row gutter={20}>
                <Col xxl={12} md={14} sm={24} xs={24}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <Form.Item
                      name="patient_id"
                      label="Patient Name"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        onBlur={preventDefaultOnPatientName}
                        showSearch
                        onSearch={onPatientNameChange}
                        placeholder="Select a option and change input text above"
                        showArrow={false}
                        filterOption={false}
                        notFoundContent={null}
                      >
                        {patients.map((patient: any, index: number) => {
                          console.log(patient);
                          return (
                            <Option key={index} value={patient.id}>
                              <DropdownCard
                                item={{
                                  photo: (
                                    <Avatar
                                      size={67}
                                      shape="square"
                                      src={patient.photo}
                                      icon={<UserOutlined />}
                                    />
                                  ),
                                  title: patient.name,
                                  description: `${patient.contactNo} / ${
                                    patient.age || '-'
                                  }`,
                                }}
                              />
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Button
                      type="primary"
                      onClick={showModal}
                      style={{
                        transform: 'translateY(40px)',
                      }}
                    >
                      Create patient
                    </Button>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="appointment_type"
                    label="Appointment Type"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select a option and change input text above"
                      allowClear
                      defaultValue={
                        appointment ? appointment.appointment_type : ''
                      }
                    >
                      <Option value="1">New Consultation</Option>
                      <Option value="2">Showing Report</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col lg={24} md={24} sm={12} xs={24}>
                  <Form.Item name="complaint" label="Patient Complaint">
                    <Input.TextArea
                      defaultValue={appointment ? appointment.complaint : ''}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ display: 'none' }}>
                <Form.Item name="schedule_id">
                  <Input hidden value={scheduleId} />
                </Form.Item>
                <Form.Item name="start_time">
                  <Input hidden value={startTime} />
                </Form.Item>
                <Form.Item name="end_time">
                  <Input value={endTime} hidden />
                </Form.Item>
                <Form.Item name="years">
                  <Input value={patientYears} hidden />
                </Form.Item>
                <Form.Item name="months">
                  <Input value={patientMonths} hidden />
                </Form.Item>
                <Form.Item name="days">
                  <Input value={patientDays} hidden />
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  loading={loading}
                  style={{ marginRight: '10px' }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
      <Modal
        title="Create Patient"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <PatientContentForm
          onPatientCreate={onPatientCreate}
          patientName={patientNameOnType || ''}
          shortForm={true}
          showTitle={false}
        />
      </Modal>
      <Drawer
        placement="right"
        size="large"
        onClose={() => setDetailsDrawer(false)}
        visible={detailsDrawer}
      >
        {appointmentDetails ? (
          <AppointmentDetails appointment={appointmentDetails} />
        ) : null}
      </Drawer>
    </>
  );
};

export default AppointmentForm;
